export const translation = {
  ru: {
    menu_item_1: 'Ru placeholder',
    menu_item_2: 'Ru placeholder',
    menu_item_3: 'Ru placeholder',
    menu_item_4: 'Ru placeholder',
    request_demo: 'Ru placeholder',
    promo_title: 'Ru placeholder',
    promo_subtitle: 'Ru placeholder',

    trustedBy_title: 'Ru placeholder',
    products_title: 'Ru placeholder',

    product_1_title: 'Ru placeholder',
    product_1_description: 'Ru placeholder',

    product_1_tag_1: 'Ru placeholder',
    product_1_tag_2: 'Ru placeholder',
    product_1_tag_3: 'Ru placeholder',

    product_2_title: 'Ru placeholder',
    product_2_description: 'Ru placeholder',

    product_2_tag_1: 'Ru placeholder',
    product_2_tag_2: 'Ru placeholder',

    product_3_title: 'Ru placeholder',
    product_3_description: 'Ru placeholder',

    product_3_tag_1: 'Ru placeholder',
    product_3_tag_2: 'Ru placeholder',
    product_3_tag_3: 'Ru placeholder',
    product_3_tag_4: 'Ru placeholder',
    product_3_tag_5: 'Ru placeholder',

    features_title: 'Ru placeholder',

    features_1_tag: 'Ru placeholder',
    features_1_text: 'Ru placeholder',

    features_2_tag: 'Ru placeholder',
    features_2_text: 'Ru placeholder',

    features_3_tag: 'Ru placeholder',
    features_3_text: 'Ru placeholder',

    features_4_tag: 'Ru placeholder',
    features_4_text: 'Ru placeholder',

    casesb2g_title: 'Ru placeholder',

    casesb2g_subtitle_1: 'Ru placeholder',
    casesb2g_subtitle_2: 'Ru placeholder',

    casesb2g_1_title: 'Ru placeholder',
    casesb2g_1_description_1: 'Ru placeholder',

    casesb2g_1_description_2: 'Ru placeholder',
    casesb2g_1_description_3: 'Ru placeholder',

    casesb2g_2_title: 'Ru placeholder',
    casesb2g_2_description_1: 'Ru placeholder',

    casesb2g_2_description_2: 'Ru placeholder',
    casesb2g_2_description_3: 'Ru placeholder',

    casesb2g_3_title: 'Ru placeholder',
    casesb2g_3_description_1: 'Ru placeholder',

    casesb2g_3_description_2: 'Ru placeholder',

    casesb2g_3_description_3: 'Ru placeholder',

    casesb2b_title: 'Ru placeholder',

    casesb2b_subtitle_1: 'Ru placeholder',
    casesb2b_subtitle_2: 'Ru placeholder',

    casesb2b_1_title: 'Ru placeholder',
    casesb2b_1_description_1: 'Ru placeholder',

    casesb2b_1_description_2: 'Ru placeholder',
    casesb2b_1_description_3: 'Ru placeholder',

    casesb2b_2_title: 'Ru placeholder',
    casesb2b_2_description_1: 'Ru placeholder',

    casesb2b_2_description_2: 'Ru placeholder',
    casesb2b_2_description_3: 'Ru placeholder',

    casesb2b_3_title: 'Ru placeholder',
    casesb2b_3_description_1: 'Ru placeholder',

    casesb2b_3_description_2: 'Ru placeholder',

    casesb2b_3_description_3: 'Ru placeholder',

    getfullversion_title: 'Ru placeholder',
    getfullversion_description: 'Ru placeholder',

    getfullversion: 'Ru placeholder',

    request_title: 'Ru placeholder',

    request_1_title: 'Ru placeholder',
    request_1_items: 'Ru placeholder',

    request_2_title: 'Ru placeholder',

    request_2_items: 'Ru placeholder',

    request_3_title: 'Ru placeholder',
    request_3_items: 'Ru placeholder',

    request_4_title: 'Ru placeholder',
    request_4_items: 'Ru placeholder',

    request_5_title: 'Ru placeholder',
    request_5_items: 'Ru placeholder',

    leave_a_request: 'Ru placeholder',

    footer_title_1: 'Ru placeholder',
    footer_description_1: 'Ru placeholder',
    footer_description_2: 'Ru placeholder',

    footer_title_2: 'Ru placeholder',
    footer_description_3: 'Ru placeholder',
    footer_description_4: 'Ru placeholder',

    footer_title_3: 'Ru placeholder',
    footer_subtitle: 'Ru placeholder',

    illustration_title_1: 'Ru placeholder',
    illustration_description_1: 'Ru placeholder',

    illustration_title_2: 'Ru placeholder',
    illustration_description_2: 'Ru placeholder',

    illustration_title_3: 'Ru placeholder',
    illustration_description_3: 'Ru placeholder',

    illustration_title_4: 'Ru placeholder',
    illustration_description_4: 'Ru placeholder',

    illustration_title_5: 'Ru placeholder',
    illustration_description_5: 'Ru placeholder',

    illustration_title_6: 'Ru placeholder',
    illustration_description_6: 'Ru placeholder',
  },
  en: {
    menu_item_1: 'Products',
    menu_item_2: 'Features',
    menu_item_3: 'Cases',
    menu_item_4: 'News',
    request_demo: 'Request Demo',
    promo_title: 'City talks, we translate',
    promo_subtitle:
      'RCP (Resilient City Platform) – geo-analytical platform for data-driven urban planning and city governance',
    trustedBy_title: 'trusted by experts',
    products_title: 'Products',

    product_1_title: 'RCP (Resilient City Platform)',
    product_1_description:
      'geo-analytical platform for data-driven urban planning and city governance',
    product_1_tag_1: 'City Administrations',
    product_1_tag_2: 'Urban Planning',
    product_1_tag_3: 'Financial Bodies',

    product_2_title: 'RBP (Resilient Business Platform)',
    product_2_description:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',
    product_2_tag_1: 'Real Estate',
    product_2_tag_2: 'Retail & Transport',

    product_3_title: 'RCI (Resilient City Index)',
    product_3_description:
      'global index (500+ cities) for urban risk-resilience assessment, global city data intelligence tool',
    product_3_tag_1: 'City Administrations',
    product_3_tag_2: 'Urban Planning',
    product_3_tag_3: 'Financial Bodies',
    product_3_tag_4: 'Real Estate',
    product_3_tag_5: 'Retail & Transport',

    features_title: 'Features',

    features_1_tag: 'Accessibility',
    features_1_text: 'Identify the accessibility zone of a particular area',

    features_2_tag: '3D visualization ',
    features_2_text:
      'Use 3d visualization to look for anomalies and correlations in metrics',

    features_3_tag: 'Areas',
    features_3_text:
      'Сompare the chosen area across the five components of resilience',

    features_4_tag: 'Filters',
    features_4_text: 'Filter the metrics to find what you need',

    casesb2g_title: 'B2G Cases',

    casesb2g_subtitle_1: 'Why it is important',
    casesb2g_subtitle_2: 'What the city can do',

    casesb2g_1_title: 'Mixed land use',
    casesb2g_1_description_1:
      'correlation in the area of 3 functions - residential, business and social-leisure',
    casesb2g_1_description_2:
      "strain on the city's transportation infrastructure, reduced mobility",
    casesb2g_1_description_3:
      'Increase % of mixed use, e.g.  by implementing the OECD  framework for compact cities',

    casesb2g_2_title: 'Dependency ratio',
    casesb2g_2_description_1:
      'the ratio of the non-working-age population (under 16 and 60+) to the working-age group',
    casesb2g_2_description_2:
      "strain on the city's transportation infrastructure, reduced mobility",
    casesb2g_2_description_3:
      'Increase % of mixed use, e.g.  by implementing the OECD  framework for compact cities',

    casesb2g_3_title: 'NDVI - vegetation index ',
    casesb2g_3_description_1:
      'a numerical indicator of the quality and quantity of vegetation in a certain area',
    casesb2g_3_description_2:
      'Quality of city environment. air pollution and the carbon footprint problems',
    casesb2g_3_description_3:
      'Spot tree planting shortage areas, monitor growth progress and health of the green spaces and trees',

    casesb2b_title: 'B2B Cases',

    casesb2b_subtitle_1: 'Why it is important',
    casesb2b_subtitle_2: 'What the city can do',

    casesb2b_1_title: 'Mixed land use',
    casesb2b_1_description_1:
      'correlation in the area of 3 functions - residential, business and social-leisure',
    casesb2b_1_description_2:
      "strain on the city's transportation infrastructure, reduced mobility",
    casesb2b_1_description_3:
      'Increase % of mixed use, e.g.  by implementing the OECD  framework for compact cities',

    casesb2b_2_title: 'Dependency ratio',
    casesb2b_2_description_1:
      'the ratio of the non-working-age population (under 16 and 60+) to the working-age group',
    casesb2b_2_description_2:
      "strain on the city's transportation infrastructure, reduced mobility",
    casesb2b_2_description_3:
      'Increase % of mixed use, e.g.  by implementing the OECD  framework for compact cities',

    casesb2b_3_title: 'NDVI - vegetation index ',
    casesb2b_3_description_1:
      'a numerical indicator of the quality and quantity of vegetation in a certain area',
    casesb2b_3_description_2:
      'Quality of city environment. air pollution and the carbon footprint problems',
    casesb2b_3_description_3:
      'Spot tree planting shortage areas, monitor growth progress and health of the green spaces and trees',

    getfullversion_title: 'Get the full version of the report',
    getfullversion_description:
      'The 68-page report is based on the example of the city of Almaty.',
    getfullversion: 'Get full version',

    request_title: 'Customized B2B analytics on your request',

    request_1_title: 'Analysis of optimal location',
    request_1_items:
      'Socio-demographic profile|Commercial property analysis|Analysis of urban environment|Competitive analysis',

    request_2_title:
      'Opening new outlets, taking into account the risks of cannibalization of the existing network',
    request_2_items:
      'Socio-demographic profile|Commercial property analysis|Analysis of urban environment|Competitive analysis',

    request_3_title: 'Reputational management',
    request_3_items:
      'Socio-demographic profile|Commercial property analysis|Analysis of urban environment|Competitive analysis',

    request_4_title: 'Marketing campaigns',
    request_4_items:
      'Socio-demographic profile|Commercial property analysis|Analysis of urban environment|Competitive analysis',

    request_5_title: 'Forecasting revenues',
    request_5_items:
      'Socio-demographic profile|Commercial property analysis|Analysis of urban environment|Competitive analysis',

    leave_a_request: 'Leave a request',

    footer_title_1: 'Headquarters',
    footer_description_1: '123 Anywhere St. Any City, ST 12345',
    footer_description_2:
      'Tel. (123) 456-7890</br>Email: hello@reallygreatsite.com',

    footer_title_2: 'Office Hours',
    footer_description_3: 'Monday to Friday</br>9:00 am to 5:00 pm',
    footer_description_4: 'Weekends by appointment',

    footer_title_3: 'Social Media',
    footer_subtitle: '2024 Qala.ai. All right reserved',

    illustration_title_1: 'GIS-Maps Data',
    illustration_description_1:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',

    illustration_title_2: 'Satellite Data',
    illustration_description_2:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',

    illustration_title_3: 'City Infrastructure Data',
    illustration_description_3:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',

    illustration_title_4: 'Telecom & GPS',
    illustration_description_4:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',

    illustration_title_5: 'IoT-sensors data',
    illustration_description_5:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',

    illustration_title_6: 'Economic Data',
    illustration_description_6:
      'urban intelligence tool based on RCP for location-related business decisions tailored to the type of business',
  },
};
