import { Select } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import style from './SelectPreset.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
  dbChangeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';

function SelectPreset(props) {
  const {
    options,
    className,
    field,
    menuItemSelectedIcon,
    mode,
    placeholder,
    debounce = false,
  } = props;
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const dbChangeActiveFilter = useUnit(dbChangeActiveFilterEv);
  const activeFiltersValue = useUnit($activeFilters)[field];

  const [selected, setSelected] = useState(activeFiltersValue);

  const classes = className
    ? `${style.select_preset} ${className}`
    : style.select_preset;

  useEffect(() => {
    if (
      Array.isArray(activeFiltersValue) &&
      !activeFiltersValue.every((item) => selected.includes(item))
    ) {
      setSelected(activeFiltersValue);
    }
  }, [activeFiltersValue]);

  const onChange = (value) => {
    setSelected(value);
    if (debounce) {
      dbChangeActiveFilter({
        field,
        value,
      });
    } else {
      changeActiveFilter({
        field,
        value,
      });
    }
  };

  return (
    <Select
      placeholder={placeholder}
      allowClear
      options={options}
      className={classes}
      showSearch
      // onChange={debounceTime > 0 ? debounce(onChange, debounceTime) : onChange}
      onChange={onChange}
      menuItemSelectedIcon={menuItemSelectedIcon}
      mode={mode}
      value={selected}
      maxTagCount="responsive"
      maxTagTextLength={16}
    />
  );
}

export default SelectPreset;
