import { useRef } from 'react';
import { useUnit } from 'effector-react';
import Chart from 'react-apexcharts';
import {
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../../models/authModel/index.js';
import style from '../InfographicsTabs.module.css';
import index_names_dynamics from '../../../dictionaries/index_names_dymanics.json';
import {
  wsSendRBPAddFavoritesChart,
  wsSendRBPRemoveFavoritesChart,
} from '../../../utils/webSocketConfig.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { changeModalOpenEv } from '../../../models/indexCardsModel/index.js';

export default function PieChart(props) {
  const { expanded, data, title } = props;
  const chartRef = useRef();
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const userFavs = useUnit($userInfo).rbp_chart_favorites;
  const changeModalOpen = useUnit(changeModalOpenEv);

  const addFavorite = () => {
    wsSendRBPAddFavoritesChart([...userFavs, +title]);
  };

  const removeFavorite = () => {
    wsSendRBPRemoveFavoritesChart([+title]);
  };

  const onQuestionClick = () => {
    console.log('card_index: -', title);
    changeModalOpen(`d${title}`);
  };

  const options = {
    chart: {
      type: 'pie',
    },
    labels: Object.keys(data),
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      formatter: (value) => `${ru_en_page_dictionary[value][language]}`,
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: (value) => `${ru_en_page_dictionary[value][language]}`,
        },
      },
    },
  };

  const series = Object.values(data);

  return (
    <div
      className={[
        style.chart_wrapper,
        expanded ? style.chart_expanded : '',
      ].join(' ')}
      style={{
        background: darkMode ? '#181A1F' : '#F8F8F8',
      }}
    >
      <div className={style.chart_header_wrapper}>
        <div className={style.chart_title}>
          {index_names_dynamics[`d${title}`][`name_${language}`]}
        </div>
        {userFavs.includes(+title) ? (
          <StarFilled onClick={removeFavorite} />
        ) : (
          <StarOutlined onClick={addFavorite} />
        )}
        <div
          className={style.modal_button}
          onClick={onQuestionClick}
          style={{
            color: darkMode ? 'white' : '#717171',
          }}
        >
          <InfoCircleOutlined />
        </div>
      </div>
      <Chart
        ref={chartRef}
        type="pie"
        options={options}
        series={series}
        width={500}
        height={190}
        padding="15"
      />
    </div>
  );
}
