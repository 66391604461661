import {
  Button,
  Checkbox,
  Collapse,
  ColorPicker,
  Input,
  Popconfirm,
  Tooltip,
} from 'antd';
import { useUnit } from 'effector-react';
import {
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  UndoOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import style from './PresetsSideModal.module.css';
import { DeleteIcon, ExportIcon, PenIcon, UpdateIcon } from './ActionIcons.jsx';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $presetAreaToEdit,
  changeAreaNameEv,
  changeEditColorEv,
  changeEditNameEv,
  changePresetAreaToEditEv,
  deleteAreaEv,
  deletePresetEv,
  resetEditColorEv,
  resetEditNameEv,
  updateDataPresetEv,
} from '../../models/dataPresetsModel/index.js';
import { $isDarkTheme } from '../../models/authModel/index.js';

export default function ModeContentManager(props) {
  const {
    currentPage,
    allPresets,
    language,
    resetPreset,
    darkMode,
    chosenPresets,
    setChosenPresets,
    userPresets,
    presetType,
  } = props;

  const dataPresets = presetType === 'my' ? userPresets : allPresets;

  const presetAreaToEdit = useUnit($presetAreaToEdit);

  switch (currentPage) {
    // case 1:
    //   return (
    //     <>
    //       <div className={style.content_title}>
    //         {ru_en_page_dictionary.presets_zones[language]}
    //       </div>
    //       {
    //         // 	FIXME When zones will be added connect real data
    //         chosenPreset &&
    //           Object.keys(chosenPreset.areas).map((area) => {
    //             return (
    //               <PresetZone
    //                 key={`preset-input-${area}`}
    //                 onInputClick={onInputClick}
    //                 polygon={chosenPreset.activeFilters.draw_polygon.find(
    //                   (item) => item.id === area
    //                 )}
    //                 area={area}
    //                 onSaveArea={onSaveArea}
    //                 onDeleteArea={onDeleteArea}
    //                 preset={chosenPreset}
    //                 language={language}
    //                 darkMode={darkMode}
    //               />
    //             );
    //           })
    //       }
    //     </>
    //   );
    //   break;
    default:
      return (
        <>
          {dataPresets.map((preset) => {
            return (
              <PresetItem
                darkMode={darkMode}
                key={`preset-${preset.name}-${preset.id}`}
                preset={preset}
                language={language}
                presetAreaToEdit={presetAreaToEdit}
                resetPreset={resetPreset}
                chosenPresets={chosenPresets}
                setChosenPresets={setChosenPresets}
              />
            );
          })}
        </>
      );
      break;
  }
}

function PresetZone(props) {
  const {
    onInputClick,
    polygon,
    onSaveArea,
    onDeleteArea,
    preset,
    area,
    language,
    darkMode,
    areaName,
    setAreaNames,
  } = props;

  const onChangeAreaName = (e) => {
    setAreaNames((prev) => ({ ...prev, [area]: e.target.value }));
  };

  return (
    <div
      className={style.edit_input_wrapper}
      style={{ color: darkMode ? 'white' : '#A5A5A5' }}
    >
      <Input
        value={areaName}
        className={style.edit_input}
        onChange={onChangeAreaName}
        onClick={() => onInputClick(polygon?.id || area)}
        style={{ color: darkMode ? '#D3D3D3' : 'black' }}
      />
      {/* <Button */}
      {/*  icon={<SaveOutlined />} */}
      {/*  className={style.action_button} */}
      {/*  onClick={() => onSaveArea(inputValue, polygon?.id || area, preset)} */}
      {/* /> */}
      <Popconfirm
        onConfirm={() => onDeleteArea(polygon?.id || area, preset)}
        title={ru_en_page_dictionary.area_will_be_deleted[language]}
        okText={ru_en_page_dictionary.delete[language]}
        cancelText={ru_en_page_dictionary.cancel[language]}
        icon={
          <QuestionCircleOutlined
            style={{
              color: 'red',
            }}
          />
        }
      >
        <Button icon={<DeleteIcon />} className={style.action_button} />
      </Popconfirm>
    </div>
  );
}

function PresetItem(props) {
  const { preset, language, darkMode, chosenPresets, setChosenPresets } = props;
  const deletePreset = useUnit(deletePresetEv);
  const changePresetAreaToEdit = useUnit(changePresetAreaToEditEv);
  const resetEditName = useUnit(resetEditNameEv);
  const resetEditColor = useUnit(resetEditColorEv);

  const setEdit = () => {
    changePresetAreaToEdit({ preset: preset.name });
    resetEditColor();
    resetEditName();
  };

  const onCheck = () => {
    let temp = [...chosenPresets];
    if (temp.includes(preset.id)) {
      temp = temp.filter((item) => item !== preset.id);
    } else {
      temp.push(preset.id);
    }
    setChosenPresets(temp);
  };
  return (
    <div
      className={style.preset_wrapper}
      style={{
        borderBottom: darkMode ? '1px solid #181A1F' : '1px solid #E3F5FF',
      }}
    >
      <div className={style.preset_title_wrapper}>
        <div style={{ display: 'flex', width: '70%' }}>
          <Checkbox
            checked={chosenPresets.includes(preset.id)}
            onChange={onCheck}
          />
          <div className={style.preset_title}>{preset.name}</div>
        </div>
        <div>
          <Button
            icon={<EyeOutlined />}
            className={style.preset_delete_button}
            onClick={setEdit}
          />
          <Popconfirm
            onConfirm={() => deletePreset(preset.id)}
            title={ru_en_page_dictionary.preset_will_be_deleted[language]}
            okText={ru_en_page_dictionary.delete[language]}
            cancelText={ru_en_page_dictionary.cancel[language]}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: 'red',
                }}
              />
            }
          >
            <Button
              icon={<DeleteOutlined />}
              className={style.preset_delete_button}
            />
          </Popconfirm>
        </div>
      </div>
      <div className={style.preset_info_wrapper}>
        <div>
          <div className={style.preset_info_title}>
            {ru_en_page_dictionary.preset_created[language]}:
          </div>
          <div className={style.preset_info_data}>
            {preset.created_at ? preset.created_at.slice(0, 10) : ''}
          </div>
        </div>
        <div>
          <div className={style.preset_info_title}>
            {ru_en_page_dictionary.preset_updated[language]}:
          </div>
          <div className={style.preset_info_data}>
            {preset.updated_at ? preset.updated_at.slice(0, 10) : ''}
          </div>
        </div>
        <div>
          <div className={style.preset_info_title}>
            {ru_en_page_dictionary.preset_author[language]}:
          </div>
          <div className={style.preset_info_data}>
            {preset.created_by || ''}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PresetItemOld(props) {
  const { preset, language, presetAreaToEdit, resetPreset } = props;

  const [expanded, setExpanded] = useState(false);

  const changeEditName = useUnit(changeEditNameEv);
  const changeEditColor = useUnit(changeEditColorEv);
  const deletePreset = useUnit(deletePresetEv);
  const changePresetAreaToEdit = useUnit(changePresetAreaToEditEv);
  const darkMode = useUnit($isDarkTheme);
  const deleteArea = useUnit(deleteAreaEv);
  const updateDataPreset = useUnit(updateDataPresetEv);

  const [areaNames, setAreaNames] = useState(
    Object.fromEntries(
      Object.keys(preset.areas).map((area) => {
        let name;
        if (
          preset.activeFilters.draw_polygon
            .map((item) => item.id)
            .includes(area)
        ) {
          name = preset.activeFilters.draw_polygon.find(
            (item) => item.id === area
          ).properties.name;
          return [area, name];
        }
        name = area;

        return [name, name];
      })
    )
  );

  const onInputClick = (area) => {
    if (presetAreaToEdit.area !== area) {
      changePresetAreaToEdit({
        preset: preset.name,
        area,
      });
    }
  };
  const onDeleteArea = (areaId) => {
    deleteArea({
      areaId,
      preset,
    });
  };

  const onUpdatePreset = () => {
    updateDataPreset({ preset, areaNames });
  };

  const onResetPreset = () => {
    resetPreset();
  };

  const onResetPresetWithSave = () => {
    updateDataPreset(preset);
    resetPreset();
  };

  const collapseItems = [
    {
      key: 1,
      label: (
        <div className={style.preset_item}>
          <div className={style.preset_name_wrapper}>
            <ColorPicker
              defaultValue={preset.color}
              onChange={changeEditColor}
              size="large"
              disabled={presetAreaToEdit.preset !== preset.name}
            />
            <Input
              defaultValue={preset.name}
              onChange={(e) => changeEditName(e.target.value)}
              className={style.preset_name}
              style={{
                color: darkMode ? '#a5a5a5' : 'black',
              }}
              disabled={presetAreaToEdit.preset !== preset.name}
              onClick={() => onInputClick('')}
            />

            {/* <div className={style.preset_name}>{preset.name}</div> */}
          </div>
          {expanded ? (
            <UpOutlined style={{ marginRight: '102px' }} />
          ) : (
            <DownOutlined style={{ marginRight: '102px' }} />
          )}
        </div>
      ),
      children: (
        <div style={{ marginTop: '12px' }}>
          {Object.keys(preset.areas).map((area) => {
            return (
              <PresetZone
                key={`preset-input-${area}`}
                onInputClick={onInputClick}
                polygon={preset.activeFilters.draw_polygon.find(
                  (item) => item.id === area
                )}
                area={area}
                onDeleteArea={onDeleteArea}
                preset={preset}
                language={language}
                darkMode={darkMode}
                areaName={areaNames[area]}
                setAreaNames={setAreaNames}
              />
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Popconfirm
        onConfirm={onUpdatePreset}
        title={ru_en_page_dictionary.update_preset[language]}
        okText={ru_en_page_dictionary.yes[language]}
        cancelText={ru_en_page_dictionary.no[language]}
        icon={
          <QuestionCircleOutlined
            style={{
              color: 'red',
            }}
          />
        }
      >
        <Button
          icon={<SaveOutlined />}
          className={[style.action_button, 'no_bg', style.save_button].join(
            ' '
          )}
        />
      </Popconfirm>
      <Popconfirm
        onConfirm={() => deletePreset(preset.id)}
        title={ru_en_page_dictionary.preset_will_be_deleted[language]}
        okText={ru_en_page_dictionary.delete[language]}
        cancelText={ru_en_page_dictionary.cancel[language]}
        icon={
          <QuestionCircleOutlined
            style={{
              color: 'red',
            }}
          />
        }
      >
        <Button
          icon={<DeleteIcon />}
          className={[style.action_button, 'no_bg', style.delete_button].join(
            ' '
          )}
        />
      </Popconfirm>
      <Popconfirm
        onConfirm={onResetPresetWithSave}
        onCancel={onResetPreset}
        title={ru_en_page_dictionary.reset_preset[language]}
        okText={ru_en_page_dictionary.yes[language]}
        cancelText={ru_en_page_dictionary.no[language]}
        icon={
          <QuestionCircleOutlined
            style={{
              color: 'red',
            }}
          />
        }
      >
        <Button
          className={[style.action_button, 'no_bg', style.reset_button].join(
            ' '
          )}
          icon={<RollbackOutlined />}
          disabled={presetAreaToEdit.preset !== preset.name}
        />
      </Popconfirm>

      <Collapse
        items={collapseItems}
        ghost
        expandIcon={() => {}}
        style={{ background: darkMode ? '#1E2328' : 'white' }}
        className={style.preset_collapse}
        onChange={() => setExpanded(!expanded)}
      />
    </div>
  );
}
