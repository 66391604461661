import { createStore } from 'effector';

export const $mapLoaded = createStore(false);
$mapLoaded.watch((state) => console.log('$mapLoaded', state));

export const $interactivity = createStore(true);
$interactivity.watch((state) => console.log('$interactivity', state));

export const $searchOptions = createStore({});
$searchOptions.watch((state) => console.log('$searchOptions', state));

export const $drawMode = createStore('simple_select');

export const $layersOpacity = createStore(0.5);
$layersOpacity.watch((state) => console.log('$layersOpacity', state));

export const $hexagonsHeight = createStore(300);
$hexagonsHeight.watch((state) => console.log('$hexagonsHeight', state));

export const $invertHeight = createStore(false);
$invertHeight.watch((state) => console.log('$invertHeight', state));
