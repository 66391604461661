import { Button, Tooltip } from 'antd';
import {
  HomeOutlined,
  MinusOutlined,
  PlusOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './MapNavControls.module.css';
import { $isTablet, $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { mapCenterPC, mapCenterTab } from '../../utils/mapbox-utils.js';
import { toggleExtrusionEv } from '../../models/zoom10Model/index.js';

function MapNavControls() {
  const language = useUnit($language);
  const isTablet = useUnit($isTablet);

  const zoomHandle = (isIn) => {
    if (isIn) {
      window.map.flyTo({
        zoom: window.map.getZoom() + 1,
      });
    } else {
      window.map.flyTo({
        zoom: window.map.getZoom() - 1,
      });
    }
  };

  const zoomHome = () => {
    window.map.flyTo({
      center: isTablet ? mapCenterTab : mapCenterPC,
      zoom: 10,
    });
  };

  return (
    <div className={style.controls_wrapper}>
      <Tooltip
        trigger={isTablet ? null : 'hover'}
        title={ru_en_page_dictionary.zoom_home[language]}
        placement="right"
      >
        <Button
          onClick={zoomHome}
          icon={<HomeOutlined className={style.button_icon} />}
          className={style.button}
        />
      </Tooltip>
      <Tooltip
        trigger={isTablet ? null : 'hover'}
        title={ru_en_page_dictionary.zoom_in[language]}
        placement="right"
      >
        <Button
          onClick={() => zoomHandle(true)}
          icon={<PlusOutlined className={style.button_icon} />}
          className={style.button}
        />
      </Tooltip>
      <Tooltip
        trigger={isTablet ? null : 'hover'}
        title={ru_en_page_dictionary.zoom_out[language]}
        placement="right"
      >
        <Button
          onClick={() => zoomHandle(false)}
          icon={<MinusOutlined className={style.button_icon} />}
          className={style.button}
        />
      </Tooltip>
    </div>
  );
}

export default MapNavControls;
