export const ru_en_page_dictionary = {
  resilience_index: {
    ru: 'Индекс жизнестойкости',
    en: 'Resilience index',
  },
  filters: {
    ru: 'Фильтры',
    en: 'Filters',
  },
  params: {
    ru: 'Параметры',
    en: 'Parameters',
  },
  submit_button: {
    ru: 'Применить',
    en: 'Apply',
  },
  reset_button: {
    ru: 'Сбросить все',
    en: 'Reset all',
  },
  quarter_placeholder: {
    ru: 'Квартал',
    en: 'Quarter',
  },
  districts_placeholder: {
    ru: 'Выберите район',
    en: 'Choose district',
  },
  geocoder_placeholder: {
    ru: 'Введите адрес',
    en: 'Enter address',
  },
  select_all: {
    ru: 'Выбрать все',
    en: 'Select all',
  },
  isochrone_title: {
    ru: 'Зона доступности',
    en: 'Availability zone',
  },
  isochrone_segmented_1: {
    ru: 'Пешеход',
    en: 'Pedestrian',
  },
  isochrone_segmented_2: {
    ru: 'Авто',
    en: 'Auto',
  },
  isochrone_time_measure: {
    ru: 'мин',
    en: 'min',
  },
  fav_metrics: {
    ru: 'Избранные',
    en: 'Favorites',
  },
  no_fav_metrics: {
    ru: 'Нет избранных',
    en: 'No Favorites',
  },
  radar_tooltip: {
    ru: 'Индекс',
    en: 'Index',
  },
  polygon_button: {
    ru: 'Выделить область',
    en: 'Draw region',
  },
  delete_button: {
    ru: 'Удалить область',
    en: 'Delete region',
  },
  isochrone_button: {
    ru: 'Зона доступности',
    en: 'Availability zone',
  },
  zoom_in: {
    ru: 'Приблизить',
    en: 'Zoom in',
  },
  zoom_out: {
    ru: 'Отдалить',
    en: 'Zoom out',
  },
  zoom_home: {
    ru: 'Вернуться',
    en: 'Reset',
  },
  table_name: {
    ru: 'Имя',
    en: 'Name',
  },
  table_email: {
    ru: 'E-mail',
    en: 'E-mail',
  },
  table_organization: {
    ru: 'Организация',
    en: 'Organization',
  },
  table_status: {
    ru: 'Статус',
    en: 'Status',
  },
  table_action: {
    ru: 'Дайствия',
    en: 'Actions',
  },
  table_action_unblock: {
    ru: 'Разблокировать пользователя',
    en: 'Unblock user',
  },
  table_action_block: {
    ru: 'Заблокировать пользователя',
    en: 'Block user',
  },
  table_action_confirm: {
    ru: 'Подтвердить пользователя',
    en: 'Confirm user',
  },
  table_performance: {
    ru: 'Производительность',
    en: 'Performance',
  },
  table_download: {
    ru: 'Загрузка',
    en: 'Download',
  },
  table_mutate: {
    ru: 'Мутирование',
    en: 'Mutate',
  },
  table_to_front: {
    ru: 'фронт',
    en: 'front',
  },
  table_to_back: {
    ru: 'бэк',
    en: 'back',
  },
  table_switch: {
    ru: 'Переключить просчет на',
    en: 'Switch calculation to',
  },
  table_action_view: {
    ru: 'Просмотреть пользователя',
    en: 'View user',
  },
  status_blocked: {
    ru: 'Заблокирован',
    en: 'Blocked',
  },
  status_confirmed: {
    ru: 'Подтвержден',
    en: 'Confirmed',
  },
  status_undefined: {
    ru: 'Неопределен',
    en: 'Undefined',
  },
  user: {
    ru: 'Пользователь',
    en: 'User',
  },
  chart_download: {
    ru: 'Статистика скорости загрузки',
    en: 'Download speed stats',
  },
  chart_mutation: {
    ru: 'Статистика скорости мутации',
    en: 'Mutation speed stats',
  },
  user_table_date: {
    ru: 'Дата',
    en: 'Date',
  },
  user_table_download: {
    ru: 'Скорость загрузки',
    en: 'Download speed',
  },
  user_table_mutation: {
    ru: 'Скорость мутации',
    en: 'Mutation speed',
  },
  table_admin: {
    ru: 'Админ',
    en: 'Admin',
  },
  make_admin: {
    ru: 'Назначить админом',
    en: 'Make admin',
  },
  table_timers: {
    ru: 'Таймеры',
    en: 'Timers',
  },
  side_description: {
    ru: 'Описание',
    en: 'Description',
  },
  side_calculation: {
    ru: 'Метод расчета',
    en: 'Calculation method',
  },
  side_hist_data: {
    ru: 'Исторические данные',
    en: 'Historical data',
  },
  isochrone_traffic: {
    ru: 'Трафик',
    en: 'Traffic',
  },
  map_style_streets: {
    ru: 'Рельеф',
    en: 'Streets',
  },
  map_style_voyager: {
    ru: 'Улицы',
    en: 'Voyager',
  },
  map_style_dark: {
    ru: 'Темная',
    en: 'Dark',
  },
  map_style_light: {
    ru: 'Светлая',
    en: 'Light',
  },
  map_style_satellite: {
    ru: 'Снимок',
    en: 'Satellite',
  },
  report_button: {
    ru: 'Сформировать отчет',
    en: 'Generate report',
  },
  report_title: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  report_new: {
    ru: 'Формирование нового отчета',
    en: 'Generate new report',
  },
  report_new_tablet: {
    ru: 'Новый отчет',
    en: 'New report',
  },
  report_archive: {
    ru: 'Архив отчетов',
    en: 'Reports archive',
  },
  report_name: {
    ru: 'Название файла',
    en: 'File name',
  },
  report_checkbox_1: {
    ru: 'Использовать примененные к показателям RCP фильтры',
    en: 'Use filters applied to RCP metrics',
  },
  report_checkbox_1_rbp: {
    ru: 'Использовать примененные к показателям RBP фильтры',
    en: 'Use filters applied to RBP metrics',
  },
  report_checkbox_2: {
    ru: 'Использовать примененные пространственные фильтры (выделенная территория, зона доступности)',
    en: 'Use applied spatial filters (selected area, accessibility zone)',
  },
  report_checkbox_3: {
    ru: 'Учитывать разделение по районам',
    en: 'Take into account the division by region',
  },
  report_checkbox_4: {
    ru: 'Использовать абсолютные значения',
    en: 'Use absolute values',
  },
  report_name_error: {
    ru: 'Неверное имя файла. Имя файла не может быть пустым и не может содержать / , : ; * ? " < > | ~ \\ | ;',
    en: 'Invalid file name. File name cannot  be empty and cannot contain / , : ; * ? " < > | ~ \\ | ;',
  },
  user_list_title: {
    ru: 'Список пользователей',
    en: 'User list',
  },
  profile_user_list: {
    ru: 'Список пользователей',
    en: 'User list',
  },
  profile_logout: {
    ru: 'Выход',
    en: 'Logout',
  },
  algorithm: {
    ru: 'Алгоритм',
    en: 'Algorithm',
  },
  gradient_by: {
    ru: 'Градиент по',
    en: 'Gradient by',
  },
  isochrone_custom_time: {
    ru: 'Введите время (мин)',
    en: 'Enter time (min)',
  },
  metrics: {
    ru: 'Показатели',
    en: 'Metrics',
  },
  index: {
    ru: 'Индекс',
    en: 'Index',
  },
  reports: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  theme: {
    ru: 'Режим',
    en: 'Theme',
  },
  language: {
    ru: 'Язык',
    en: 'Language',
  },
  opacity: {
    ru: 'Прозрачность',
    en: 'Opacity',
  },
  hex_height: {
    ru: 'Высота гексагонов в 3D-режиме',
    en: 'Hexagons height in 3D mode',
  },
  save_preset: {
    ru: 'Сохранить новый пресет',
    en: 'Save new preset',
  },
  hide_presets: {
    ru: 'Обычный режим',
    en: 'Regular mode',
  },
  show_presets: {
    ru: 'Режим сравнения',
    en: 'Comparison mode',
  },
  presets: {
    ru: 'Пресеты',
    en: 'Presets',
  },
  sort_by: {
    ru: 'Сортировать по',
    en: 'Sort by',
  },
  sort_ascend: {
    ru: 'По возрастанию',
    en: 'Ascend',
  },
  sort_descend: {
    ru: 'По убыванию',
    en: 'Descend',
  },
  order: {
    ru: 'Порядок',
    en: 'Order',
  },
  sort_by_name: {
    ru: 'Названию',
    en: 'Name',
  },
  sort_by_index: {
    ru: 'Индексу',
    en: 'Index',
  },
  sort_by_create: {
    ru: 'Дата создания',
    en: 'Create date',
  },
  sort_by_update: {
    ru: 'Дата изменения',
    en: 'Update date',
  },
  sort_by_author: {
    ru: 'Автор',
    en: 'Author',
  },
  presets_1: {
    ru: 'Редактирование пресета',
    en: 'Edit preset',
  },
  presets_zones: {
    ru: 'Зоны',
    en: 'Zones',
  },
  reset_preset: {
    ru: 'Выйти из пресета. Сохранить изменения?',
    en: 'Reset preset. Save changes?',
  },
  preset_action_1: {
    ru: 'Откат',
    en: 'Reset',
  },
  preset_action_2: {
    ru: 'Редактировать',
    en: 'Edit',
  },
  preset_action_3: {
    ru: 'Обновить',
    en: 'Update',
  },
  preset_action_4: {
    ru: '',
    en: '',
  },
  preset_action_5: {
    ru: 'Создать дубликат',
    en: 'Create duplicate',
  },
  preset_action_6: {
    ru: 'Удалить',
    en: 'Delete',
  },
  r_u_sure: {
    ru: 'Вы уверены?',
    en: 'Are you sure?',
  },
  update_preset: {
    ru: 'Обновть пресет',
    en: 'Update preset',
  },
  preset_will_be_deleted: {
    ru: 'Пресет будет полностью удален.',
    en: 'Preset will be deleted.',
  },
  area_will_be_deleted: {
    ru: 'Область будет удалена из пресета',
    en: 'Area will be deleted from the preset',
  },
  yes: {
    ru: 'Да',
    en: 'Yes',
  },
  no: {
    ru: 'Нет',
    en: 'No',
  },
  delete: {
    ru: 'Удалить',
    en: 'Delete',
  },
  cancel: {
    ru: 'Отменить',
    en: 'Cancel',
  },
  enter_preset_name: {
    ru: 'Введите название нового пресета',
    en: 'Enter new preset name',
  },
  choose_preset: {
    ru: 'Выберите пресет из списка ниже',
    en: 'Choose preset from the list below',
  },
  preset_error_name: {
    ru: 'Введите название пресета',
    en: 'Enter preset name',
  },
  preset_error_areas: {
    ru: 'Выделите зоны',
    en: 'Select zones',
  },
  error: {
    ru: 'Ошибка',
    en: 'Error',
  },
  preset_created: {
    ru: 'Создано',
    en: 'Created',
  },
  preset_updated: {
    ru: 'Изменено',
    en: 'Updated',
  },
  preset_author: {
    ru: 'Автор',
    en: 'Author',
  },
  delete_chosen: {
    ru: 'Удалить выбранные',
    en: 'Delete chosen',
  },
  my_presets: {
    ru: 'Мои пресеты',
    en: 'My presets',
  },
  all_presets: {
    ru: 'Все персеты',
    en: 'All presets',
  },
  compare: {
    ru: 'Сравнение',
    en: 'Compare',
  },
  hide: {
    ru: 'Свернуть',
    en: 'Hide',
  },
  show: {
    ru: 'Развернуть',
    en: 'Show',
  },
  delete_chosen_presets: {
    ru: 'Выбранные пресеты будут удалены.',
    em: 'Chosen presets will be deleted.',
  },
  pin_tooltip: {
    ru: 'Укажите точку на карте',
    en: 'Specify a point on the map',
  },
  rbp_widgets: {
    ru: 'Параметры индекса',
    en: 'Index parameters',
  },
  rbp_infographics: {
    ru: 'Характеристики территории',
    en: 'Territory characteristics',
  },
  invert_height: {
    ru: 'Инвертировать высоту',
    en: 'Invert height',
  },
  business_type: {
    ru: 'Типы бизнеса',
    en: 'Business types',
  },
  male: {
    ru: 'Мужчины',
    en: 'Male',
  },
  female: {
    ru: 'Женщины',
    en: 'Female',
  },
  weekdays_day: {
    ru: 'Будни день',
    en: 'Weekdays day',
  },
  weekdays_evening: {
    ru: 'Будни вечер',
    en: 'Weekdays evening',
  },
  weekend_day: {
    ru: 'Выходные день',
    en: 'Weekend day',
  },
  weekend_evening: {
    ru: 'Выходные вечер',
    en: 'Weekend evening',
  },
  chosen: {
    ru: 'Выбранные',
    en: 'Chosen',
  },
  mt: {
    ru: 'Современная торговля',
    en: 'Modern trade',
  },
  tt: {
    ru: 'Традиционная торговля',
    en: 'Traditional trade',
  },
  commerce_rent: {
    ru: 'Коммерческая аренда',
    en: 'Commerce rent',
  },
  rent: {
    ru: 'Аренда',
    en: 'Rent',
  },
  sell: {
    ru: 'Продажа',
    en: 'Sell',
  },
  'live/work': {
    ru: 'Проживающее и работающее',
    en: 'Living and working',
  },
  'live/notwork': {
    ru: 'Проживающее',
    en: 'Living',
  },
  'notlive/work': {
    ru: 'Работающее',
    en: 'Working',
  },
  'notlive/notwork': {
    ru: 'Транзитное',
    en: 'Transit',
  },
  realestate: {
    ru: 'Стоимость недвижимости',
    en: 'Real estate price',
  },
  tenge: {
    ru: 'тенге',
    en: 'tenge',
  },
  toggle_rcp: {
    ru: 'Переключить доступ к RCP',
    en: 'Toggle access to RCP',
  },
  toggle_rbp: {
    ru: 'Переключить доступ к RBP',
    en: 'Toggle access to RBP',
  },
  rbp_filters_index: {
    ru: 'Фильтры индекса',
    en: 'Index filters',
  },
  rbp_filters_scodem: {
    ru: 'Фильтры соцдем',
    en: 'Socdem filters',
  },
  age: {
    ru: 'Возраст',
    en: 'Age',
  },
  income: {
    ru: 'Доход',
    en: 'Income',
  },
  gender: {
    ru: 'Пол',
    en: 'Gender',
  },
  rbp_socdem_group: {
    ru: 'Группа',
    en: 'Group',
  },
  rbp_socdem_add_group: {
    ru: 'Добавить группу',
    en: 'Add group',
  },
  rbp_layer_control_1: {
    ru: 'Индекс',
    en: 'Index',
  },
  rbp_layer_control_2: {
    ru: 'Соцдем',
    en: 'Socdem',
  },
};
