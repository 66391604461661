import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { $isochroneStore } from '../../models/isochroneModel/index.js';
import { $mapLoaded } from '../../models/mapModel/index.js';

export default function IsochroneLayer() {
  const isochroneFeatures = useUnit($isochroneStore);
  const mapLoaded = useUnit($mapLoaded);
  const createLayer = (update) => {
    const isochroneData = {
      type: 'FeatureCollection',
      features: isochroneFeatures,
    };
    if (update) {
      window.map.getSource('isochrone_source').setData(isochroneData);
    } else {
      if (!window.map.getSource('isochrone_source')) {
        window.map.addSource('isochrone_source', {
          type: 'geojson',
          data: isochroneData,
        });
      }

      if (!window.map.getLayer('isochrone_layer')) {
        window.map.addLayer({
          id: 'isochrone_layer',
          type: 'line',
          source: 'isochrone_source',
          paint: {
            'line-color': 'black',
            'line-width': 1,
            // 'fill-color': '#bf4040',
            // 'fill-opacity': 0.3,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (mapLoaded) {
      if (window.map.getLayer('isochrone_layer')) {
        createLayer(true);
      } else if (isochroneFeatures.length) {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [isochroneFeatures, mapLoaded]);

  return null;
}
