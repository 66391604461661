import React from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import getFullVersion from '../../../assets/images/sections/getFullVersion/getFullVersion.png';
import ellipse_big from '../../../assets/images/sections/getFullVersion/ellipse_big.png';
import ellipse_small from '../../../assets/images/sections/getFullVersion/ellipse_small.png';

function GetFullVersion(props) {
  const language = useUnit($language);

  return (
    <div className="landing_container">
      <div className={style.getfullversion_wrapper}>
        <img src={ellipse_big} className={style.getfullversion_ellipses} />
        <img src={ellipse_small} className={style.getfullversion_ellipses} />
        <div className={style.getfullversion_title}>
          {translation[language].getfullversion_title}
        </div>
        <div className={style.getfullversion_description}>
          {translation[language].getfullversion_description}
        </div>
        <Button
          className={style.getfullversion_button}
          iconPosition="end"
          icon={<RightOutlined />}
        >
          {translation[language].getfullversion}
        </Button>
        <img src={getFullVersion} className={style.getfullversion_img} />
      </div>
    </div>
  );
}

export default GetFullVersion;
