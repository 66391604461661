import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useUnit } from 'effector-react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  $mapLoaded,
  resetMapLoadedEv,
  setMapLoadedEv,
} from '../../models/mapModel/index.js';
import {
  createObject,
  customStylesLight,
  customStylesDark,
  deleteAll,
  ExtendDrawBar,
  modeChangeHandler,
  changeDrawStyles,
  updatePolygon,
} from '../../utils/draw_polygon_utils.js';
import {
  $isDarkTheme,
  $isTablet,
  $userInfo,
  delayedShowTabletBarEv,
} from '../../models/authModel/index.js';
import { mapCenterPC, mapCenterTab } from '../../utils/mapbox-utils.js';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZnJvbnRpZXJkIiwiYSI6ImNrYTNuMjBvNjAxNnAzbW85N3ZsbmRvZmYifQ.9OWZ7YWGJ4DDIV4bCTwsaA';

function Map() {
  const mapContainer = useRef(null);

  const setMapLoaded = useUnit(setMapLoadedEv);
  const resetMapLoaded = useUnit(resetMapLoadedEv);
  const mapLoaded = useUnit($mapLoaded);
  const darkMode = useUnit($isDarkTheme);
  const userInfo = useUnit($userInfo);
  const isTablet = useUnit($isTablet);
  const delayedShowTabletBar = useUnit(delayedShowTabletBarEv);

  useEffect(() => {
    if (window.map) return;
    window.map = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json',
      style: darkMode
        ? 'mapbox://styles/mapbox/dark-v11'
        : 'mapbox://styles/mapbox/streets-v12',
      // center: [68.71584352632227, 48.58775813682156],
      // center: [76.92861, 43.25667],
      center: isTablet ? mapCenterTab : mapCenterPC,
      zoom: 10,
    });
    window.map.dragRotate.disable();

    // const geocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl,
    // });
    //
    // const scale = new mapboxgl.NavigationControl({
    //   showCompass: false,
    // });

    // window.map.addControl(scale, 'bottom-right');
    // window.map.addControl(geocoder, 'top-left');

    window.map.on('style.load', () => {
      setMapLoaded(true);
      delayedShowTabletBar();
    });

    // window.map.on('draw.render', () => {
    //   const { layers } = window.map.getStyle();
    //   if (layers.some((item) => item.id.includes('gl-draw'))) {
    //     console.log('TEST:: I am triggered');
    //   }
    // });

    return () => {
      resetMapLoaded();
      delete window.map;
    };
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0 && !window.draw) {
      const Draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          point: true,
          trash: true,
        },
        // FIXME Custom styles of points and polygons MADE BY controls
        // for ref look in geo
        styles: userInfo.dark_theme ? customStylesDark : customStylesLight,
      });

      window.draw = Draw;

      const drawControls = new ExtendDrawBar({
        draw: Draw,
        buttons: [
          {
            on: 'click',
            action: deleteAll,
            classes: ['mapbox-gl-draw_ctrl-draw-btn', 'mapbox-gl-draw_trash'],
          },
        ],
      });

      const { modes } = MapboxDraw;
      // modes.simple_select.onDrag = function (state, e) {};

      if (window.map._controls.length != 3) {
        window.map.addControl(drawControls, 'bottom-left');
      }

      window.addEventListener('keyup', (e) => {
        if (window.draw.getMode() !== 'simple_select' && e.key === 'Escape') {
          window.draw.changeMode('simple_select');
        }
      });
      window.map.on('draw.modechange', modeChangeHandler);
      window.map.on('draw.create', createObject);
      window.map.on('draw.delete', deleteAll);
      window.map.on('draw.update', updatePolygon);
      // if (userInfo.perms.includes('admin')) {
      //   window.map.on('zoomstart', () => {
      //     window.zoom_start = window.map.getZoom();
      //   });
      //
      //   window.map.on('zoomend', () => {
      //     window.zoom_start = null;
      //   });
      //
      //   window.map.on('zoom', () => {
      //     const zoom = window.map.getZoom();
      //     if (window.zoom_start) {
      //       if (window.zoom_start > zoom) {
      //         if (zoom < 13 && zoom > 11) {
      //           window.map.flyTo({
      //             zoom: 11,
      //             speed: 5,
      //           });
      //         } else if (zoom < 11 && zoom > 10) {
      //           window.map.flyTo({
      //             zoom: 10,
      //             speed: 5,
      //           });
      //         }
      //       } else if (window.zoom_start < zoom) {
      //         if (zoom > 10 && zoom < 11) {
      //           window.map.flyTo({
      //             zoom: 11.5,
      //             speed: 5,
      //           });
      //         } else if (zoom > 12 && zoom < 13) {
      //           window.map.flyTo({
      //             zoom: 13.5,
      //             speed: 5,
      //           });
      //         }
      //       }
      //     }
      //   });
      // }
    }
  }, [userInfo]);

  return <div ref={mapContainer} className="map-container" />;
}

export default Map;
