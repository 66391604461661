import React from 'react';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import adc from '../../../assets/images/sections/trustedBy/adc.png';
import colliers from '../../../assets/images/sections/trustedBy/colliers.png';
import digitalization from '../../../assets/images/sections/trustedBy/digitalization.png';
import esg from '../../../assets/images/sections/trustedBy/esg.png';
import frontier from '../../../assets/images/sections/trustedBy/frontier.png';

function TrustedBy(props) {
  const langauge = useUnit($language);

  return (
    <div className="landing_container">
      <div className={style.trustedBy_wrapper}>
        <div className={style.trustedBy_title}>
          {translation[langauge].trustedBy_title}
        </div>
        <div className={style.trustedBy_items_wrapper}>
          <img src={colliers} />
          <img src={adc} />
          <img src={digitalization} />
          <img src={esg} />
          <img src={frontier} />
        </div>
      </div>
    </div>
  );
}

export default TrustedBy;
