import DataPresets from './DataPresets.jsx';
import TabletChartsManager from '../TabletChartsManager/TabletChartsManager.jsx';

export default function TabletDataPresets() {
  return (
    <>
      <TabletChartsManager />
      <DataPresets />
    </>
  );
}
