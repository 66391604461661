import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { center } from '@turf/turf';
import {
  $hexagonsHeight,
  $interactivity,
  $layersOpacity,
  $mapLoaded,
  $rbpLayer,
} from '../../models/mapModel/index.js';
import { $zoom9Hexagons } from '../../models/zoom9Model/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import {
  changeLayerVisibility,
  changePointerOnLayer,
  flyTo,
} from '../../utils/mapbox-utils.js';
import { saveHexDataEv } from '../../models/indexCardsModel/index.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import {
  formDynamicBuckets,
  generateExtrusionHeight,
} from '../../utils/hexagon-utils.js';
import {
  $activeExtrusionProp,
  $isExtrusion,
} from '../../models/zoom10Model/index.js';
import { $showPresets } from '../../models/dataPresetsModel/index.js';
import { $invertHeight } from '../../../models/mapModel/index.js';
import { delayedHideLoaderEv } from '../../models/webSocketModel/index.js';

function handleClick(e) {
  // if (window.map.getZoom() >= 11 && window.map.getZoom() < 13) {
  const feature = e.features[0];
  console.log('Feature click:: zoom9 hexagon', feature);
  changeActiveFilterEv({
    field: 'zoom9_hex',
    value: {
      id: feature.properties.hex_id,
      centerCoords: center(feature).geometry.coordinates,
    },
  });
  saveHexDataEv({
    level: 'zoom_9',
    data: {
      id: feature.properties.hex_id,
      indexes: feature.properties,
    },
  });
  // flyTo(center(feature).geometry.coordinates, 13);
  // }
}

function handleTouch(e) {
  if (e.originalEvent.touches && e.originalEvent.touches.length === 1) {
    handleClick(e);
  }
}

function Zoom9Layer() {
  const zoom9Hexagons = useUnit($zoom9Hexagons);
  const mapLoaded = useUnit($mapLoaded);
  const selectedZoom8Hexagon = useUnit($activeFilters).zoom8_hex;
  const selectedZoom9Hexagon = useUnit($activeFilters).zoom9_hex;
  const selectedZoom10Hexagon = useUnit($activeFilters).zoom10_hex;
  const { buckets, colors } = useUnit($gradientBuckets);
  const interactivity = useUnit($interactivity);
  const layersOpacity = useUnit($layersOpacity);
  const isExtrusion = useUnit($isExtrusion);
  const activeExtrusionProp = useUnit($activeExtrusionProp);
  const hexagonsHeight = useUnit($hexagonsHeight);
  const showPresets = useUnit($showPresets);
  const invertHeight = useUnit($invertHeight);
  const rbpLayer = useUnit($rbpLayer);

  const createLayer = (update) => {
    const zoom9HexagonData = {
      type: 'FeatureCollection',
      features: zoom9Hexagons,
    };
    const gradientProp = formDynamicBuckets(buckets, colors, rbpLayer);

    if (update) {
      window.map.getSource('zoom9_hexagon_source').setData(zoom9HexagonData);
      window.map.setPaintProperty(
        'zoom9_hexagon_layer',
        'fill-extrusion-color',
        gradientProp
      );
      window.map.setPaintProperty(
        'zoom9_hexagon_outline',
        'line-color',
        gradientProp
      );
    } else {
      if (!window.map.getSource('zoom9_hexagon_source')) {
        window.map.addSource('zoom9_hexagon_source', {
          type: 'geojson',
          data: zoom9HexagonData,
        });
      }

      if (!window.map.getLayer('zoom9_hexagon_layer')) {
        window.map.addLayer({
          id: 'zoom9_hexagon_layer',
          type: 'fill-extrusion',
          source: 'zoom9_hexagon_source',
          paint: {
            'fill-extrusion-color': gradientProp,
            'fill-extrusion-height': generateExtrusionHeight(
              isExtrusion,
              activeExtrusionProp,
              hexagonsHeight,
              invertHeight
            ),
            'fill-extrusion-opacity': layersOpacity,
          },
        });
      }

      window.map.on('render', () => {
        delayedHideLoaderEv();
      });

      changePointerOnLayer('zoom9_hexagon_layer', true);

      if (!window.map.getLayer('zoom9_hexagon_outline')) {
        // FIXME Border layer
        window.map.addLayer({
          id: 'zoom9_hexagon_outline',
          type: 'line',
          source: 'zoom9_hexagon_source',
          paint: {
            'line-color': gradientProp,
            'line-width': 1,
            'line-opacity': layersOpacity,
            // 'line-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0, 10, 0.5],
          },
        });
      }

      if (isExtrusion) {
        changeLayerVisibility('zoom9_hexagon_outline', 'none');
      } else {
        changeLayerVisibility('zoom9_hexagon_outline', 'visible');
      }
      if (showPresets) {
        changeLayerVisibility('zoom9_hexagon_layer', 'none');
        changeLayerVisibility('zoom9_hexagon_outline', 'none');
      } else {
        changeLayerVisibility('zoom9_hexagon_layer', 'visible');
        changeLayerVisibility(
          'zoom9_hexagon_outline',
          isExtrusion ? 'none' : 'visible'
        );
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && zoom9Hexagons.length && buckets.length > 0) {
      if (window.map.getLayer('zoom9_hexagon_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [zoom9Hexagons, mapLoaded, buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom9_hexagon_layer')) {
      if (selectedZoom9Hexagon.id !== '') {
        flyTo(selectedZoom9Hexagon.centerCoords, 13);
      } else if (selectedZoom8Hexagon.centerCoords.length > 0) {
        flyTo(selectedZoom8Hexagon.centerCoords, 11);
      }
    }
  }, [selectedZoom9Hexagon.id]);

  useEffect(() => {
    if (interactivity) {
      window.map.on('click', 'zoom9_hexagon_layer', handleClick);
      window.map.on('touchstart', 'zoom9_hexagon_layer', handleTouch);
      changePointerOnLayer('zoom9_hexagon_layer', interactivity);
    } else {
      window.map.off('click', 'zoom9_hexagon_layer', handleClick);
      window.map.off('touchstart', 'zoom9_hexagon_layer', handleTouch);
      changePointerOnLayer('zoom9_hexagon_layer', interactivity);
    }
  }, [interactivity]);

  useEffect(() => {
    if (
      mapLoaded &&
      window.map.getLayer('zoom9_hexagon_layer') &&
      buckets.length > 0
    ) {
      createLayer(true);
    }
  }, [buckets, rbpLayer]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom9_hexagon_layer')) {
      setTimeout(() => {
        window.map.setPaintProperty(
          'zoom9_hexagon_layer',
          'fill-extrusion-opacity',
          layersOpacity
        );
        window.map.setPaintProperty(
          'zoom9_hexagon_outline',
          'line-opacity',
          layersOpacity
        );
      }, 100);
    }
  }, [mapLoaded, layersOpacity]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom9_hexagon_layer')) {
      if (!isExtrusion) {
        window.map.setPaintProperty(
          'zoom9_hexagon_layer',
          'fill-extrusion-height',
          generateExtrusionHeight(isExtrusion, '', 0, invertHeight)
        );
      } else {
        window.map.setPaintProperty(
          'zoom9_hexagon_layer',
          'fill-extrusion-height',
          generateExtrusionHeight(
            isExtrusion,
            activeExtrusionProp,
            hexagonsHeight,
            invertHeight
          )
        );
      }
    }
  }, [
    isExtrusion,
    activeExtrusionProp,
    hexagonsHeight,
    showPresets,
    invertHeight,
  ]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom9_hexagon_layer')) {
      if (showPresets) {
        changeLayerVisibility('zoom9_hexagon_layer', 'none');
        changeLayerVisibility('zoom9_hexagon_outline', 'none');
      } else {
        changeLayerVisibility('zoom9_hexagon_layer', 'visible');
        changeLayerVisibility(
          'zoom9_hexagon_outline',
          isExtrusion ? 'none' : 'visible'
        );
      }
    }
  }, [showPresets, isExtrusion]);

  // useEffect(() => {
  //   if (window.map.getLayer('zoom9_hexagon_layer')) {
  //     if (selectedZoom9Hexagon.id === '' && selectedZoom10Hexagon.id !== '') {
  //       changeLayerVisibility('zoom9_hexagon_layer', 'none');
  //     } else {
  //       changeLayerVisibility('zoom9_hexagon_layer', 'visible');
  //     }
  //   }
  // }, [selectedZoom9Hexagon, selectedZoom10Hexagon]);
  return null;
}

export default Zoom9Layer;
