export const districts_dictionary = {
  1: {
    ru: 'Бостандыкский район',
    en: 'Bostandyk district',
  },
  2: {
    ru: 'Ауэзовский район',
    en: 'Auezov district',
  },
  3: {
    ru: 'Алатауский район',
    en: 'Alatau district',
  },
  4: {
    ru: 'Медеуский район',
    en: 'Medeu district',
  },
  5: {
    ru: 'Наурызбайский район',
    en: 'Nauryzbay district',
  },
  6: {
    ru: 'Жетысуский район',
    en: 'Jetysu district',
  },
  7: {
    ru: 'Алмалинский район',
    en: 'Almaly district',
  },
  8: {
    ru: 'Турксибский район',
    en: 'Turksib district',
  },
};
