import { useUnit } from 'effector-react';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Popover,
  Radio,
  Select,
  Space,
} from 'antd';
import Icon, {
  DoubleRightOutlined,
  DownOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import style from './DataPresets.module.css';
import {
  $activeAreas,
  $dataPresets,
  $showPresets,
  $sortBy,
  $sortOrder,
  changeActiveAreasEv,
  recolorPresetEv,
} from '../../models/dataPresetsModel/index.js';
import { small_icons_dict } from '../../dictionaries/icons_dict.jsx';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $isDarkTheme,
  $isTablet,
  $language,
} from '../../models/authModel/index.js';
import PresetsSorter from './PresetsSorter.jsx';
import { sortDataPresets } from '../../utils/data-presets-utils.js';

export default function DataPresets() {
  const showPresets = useUnit($showPresets);
  const dataPresets = useUnit($dataPresets);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const activeAreas = useUnit($activeAreas);
  const changeActiveAreas = useUnit(changeActiveAreasEv);
  const recolorPreset = useUnit(recolorPresetEv);
  const isTablet = useUnit($isTablet);

  const [sortedDataPresets, setSortedDataPresets] = useState(dataPresets);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');

  useEffect(() => {
    setSortedDataPresets(sortDataPresets([dataPresets, sortBy, sortOrder]));
  }, [sortBy, sortOrder, dataPresets]);

  return (
    <div
      className={[
        style.dataPresets_wrapper,
        showPresets ? style.show : style.hide,
      ].join(' ')}
      style={{
        height: isTablet
          ? activeAreas.length === 0
            ? 'calc(100% - 20px)'
            : 'calc(100% - 304px)'
          : '100%',
      }}
    >
      <div className={style.presets_title_wrapper}>
        <div
          className={style.presets_title}
          style={{ color: isTablet ? '#717171' : darkMode ? 'white' : 'black' }}
        >
          {ru_en_page_dictionary.presets[language]} ({dataPresets.length})
        </div>
        <PresetsSorter
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </div>
      <div className={style.presets_wrapper}>
        {sortedDataPresets.map((item) => {
          return (
            <PresetItem
              key={item.name}
              activeAreas={activeAreas}
              changeActiveAreas={changeActiveAreas}
              recolorPreset={recolorPreset}
              isTablet={isTablet}
              {...item}
            />
          );
        })}
      </div>
    </div>
  );
}

function PresetItem(props) {
  const {
    name,
    chartData,
    areasChartData,
    activeAreas,
    changeActiveAreas,
    activeFilters,
    color,
    recolorPreset,
    id,
    isTablet,
  } = props;

  const { draw_polygon } = activeFilters;

  const areas = Object.keys(areasChartData);

  const [expanded, setExpanded] = useState(false);

  const indeterminate =
    areas.some((item) => activeAreas.includes(item)) &&
    !areas.every((item) => activeAreas.includes(item));

  const onCheckAll = () => {
    changeActiveAreas(areas);
  };

  const collapseItems = [
    {
      key: 1,
      label: (
        <div>
          <div className={style.preset_top}>
            <div className={style.preset_name}>{name}</div>
            <DownOutlined
              style={{ color: '#2D9CDB' }}
              rotate={expanded ? 180 : 0}
            />
            {/* <MarkerIcon /> */}
          </div>
          <div className={style.preset_bottom}>
            <div style={{ color: '#2D9CDB' }} className={style.preset_subindex}>
              <InfoCircleFilled />
              {(
                chartData.reduce((a, b) => a + parseInt(b.value), 0) / 5
              ).toFixed(2)}
            </div>
            {chartData.map((item, index) => {
              return (
                <div
                  key={`preset-${name}-subindex-${index}`}
                  className={style.preset_subindex}
                >
                  <Icon component={small_icons_dict[index + 1]} />
                  {item.value}
                </div>
              );
            })}
          </div>
        </div>
      ),
      children: (
        <div className={style.collapse_content_wrapper}>
          {areas.map((area) => {
            const polygon = draw_polygon.find((item) => item.id === area);
            return (
              <div key={`preset-${name}-area-${area}`}>
                <Divider className={style.divider} />
                <div className={style.preset_top}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Checkbox
                      onChange={() => changeActiveAreas(area)}
                      checked={activeAreas.includes(area)}
                    />
                    <div
                      className={style.preset_name}
                      style={{ marginLeft: '5px' }}
                    >
                      {polygon?.properties?.name || area}
                    </div>
                  </div>
                  {/* <MarkerIcon /> */}
                </div>
                <div
                  className={style.preset_bottom}
                  style={{ marginLeft: 'unset' }}
                >
                  <div
                    style={{ color: '#2D9CDB' }}
                    className={style.preset_subindex}
                  >
                    <InfoCircleFilled />
                    {(
                      areasChartData[area].reduce(
                        (a, b) => a + parseInt(b.value),
                        0
                      ) / 5
                    ).toFixed(2)}
                  </div>
                  {areasChartData[area].map((item, index) => {
                    return (
                      <div
                        key={`preset-${name}-subindex-${index}-zone`}
                        className={style.preset_subindex}
                      >
                        <Icon component={small_icons_dict[index + 1]} />
                        {item.value}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <Card
      className={style.preset_wrapper}
      bodyStyle={{
        display: 'flex',
        columnGap: '5px',
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      <Checkbox
        className={style.preset_top_checkbox}
        onChange={onCheckAll}
        indeterminate={indeterminate}
        checked={areas.every((item) => activeAreas.includes(item))}
      />
      <div className={style.preset_color} onClick={() => recolorPreset(id)}>
        <div
          style={{ background: color }}
          className={style.preset_color_half}
        />
      </div>
      <Collapse
        ghost
        items={collapseItems}
        className={style.preset_collapse}
        onChange={() => setExpanded(!expanded)}
      />
    </Card>
  );
}
