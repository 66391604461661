import { createEvent } from 'effector';

export const putBlockedBucketsEv = createEvent();

export const resetBlockedBucketsEv = createEvent();

export const putThresholdsEv = createEvent();

export const calculateThresholdsEv = createEvent();

export const changeBucketAlgorithmEv = createEvent();
