import { useUnit } from 'effector-react';
import { Button, Segmented } from 'antd';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import style from './ReportModal.module.css';
import ReportForm from './ReportForm.jsx';
import ReportArchive from './ReportArchive.jsx';

export default function TabletReport() {
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const [activeTab, setActiveTab] = useState(0);
  const isDarkMode = useUnit($isDarkTheme);

  const segmentedOptions = [
    {
      label: ru_en_page_dictionary.report_new_tablet[language],
      value: 0,
    },
    {
      label: ru_en_page_dictionary.report_archive[language],
      value: 1,
    },
  ];

  const content = {
    0: <ReportForm />,
    1: <ReportArchive />,
  };
  return (
    <div className={style.tablet_report_wrapper}>
      {/* <div */}
      {/*  className={style.report_title} */}
      {/*  style={{ */}
      {/*    color: darkMode ? 'white' : 'black', */}
      {/*  }} */}
      {/* > */}
      {/*  {ru_en_page_dictionary.report_title[language]} */}
      {/* </div> */}
      {/* <Segmented */}
      {/*  options={segmentedOptions} */}
      {/*  onChange={setActiveTab} */}
      {/*  value={activeTab} */}
      {/*  block */}
      {/*  style={{ background: 'transparent', marginTop: '10px' }} */}
      {/* /> */}
      {/* {content[activeTab]} */}
      <div className={style.side_content}>
        <div style={{ color: isDarkMode ? 'white' : 'black' }}>
          {ru_en_page_dictionary.report_new[language]}
        </div>
        <ReportForm />
        <div style={{ color: isDarkMode ? 'white' : 'black' }}>
          {ru_en_page_dictionary.report_archive[language]}
        </div>
        <div className={style.archive_scroll_wrapper}>
          <ReportArchive />
        </div>
      </div>
    </div>
  );
}
