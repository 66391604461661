import { sample } from 'effector';
import {
  $zoom8Hexagons,
  $zoom8MutatedByMetric,
  $zoom8MutatedHexagons,
  $zoom8StartHexagons,
} from './stores.js';
import {
  $activeFilters,
  calculateByMetricEv,
  changeActiveFilterEv,
  submitFiltersEv,
} from '../activeFiltersModel/index.js';
import {
  filterZoom8GradientEv,
  putFilteredZoom8HexagonsEv,
  putMutated8HexagonsEv,
  putZoom8HexagonsEv,
  resetZoom8ByMetricsEv,
  updateZoom8HexagonsBy10Ev,
} from './events.js';
import {
  calculateByMedian,
  calculateHexagonsByMetrics,
  filterGradient,
  mutateHexagons,
} from '../../utils/hexagon-utils.js';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { $isochroneStore } from '../isochroneModel/index.js';
import { putBlockedBucketsEv } from '../gradientModel/index.js';
import { $userInfo } from '../authModel/index.js';

$zoom8MutatedByMetric.reset(resetZoom8ByMetricsEv);

// sample({
//   clock: getZoom8HexagonsFx.doneData,
//   fn: (clock) =>
//     clock.features.map((item) => {
//       return {
//         ...item,
//         properties: {
//           ...item.properties,
//           hex_id: item.id,
//         },
//       };
//     }),
//   target: [$zoom8Hexagons, $zoom8StartHexagons],
// });

sample({
  clock: putZoom8HexagonsEv,
  fn: (clock) => {
    const data = mutateHexagons(clock, 'zoom_8');
    // console.timeEnd('TIME:: zoom 8 polygons');
    return data;
  },
  target: [$zoom8Hexagons, $zoom8StartHexagons],
});

sample({
  clock: putFilteredZoom8HexagonsEv,
  fn: (clock) => mutateHexagons(clock, 'zoom_8'),
  target: $zoom8Hexagons,
});

// sample({
//   source: [
//     $zoom8StartHexagons,
//     $zoom8MutatedHexagons,
//     $activeFilters,
//     $isochroneStore,
//     $zoom8MutatedByMetric,
//   ],
//   clock: [
//     $activeFilters.updates,
//     $zoom8MutatedHexagons.updates,
//     $isochroneStore.updates,
//     $zoom8MutatedByMetric.updates,
//   ],
//   fn: (
//     [startData, mutatedData, activeFilters, isochroneStore, mutatedByMetric],
//     clock
//   ) => {
//     let data = startData;
//
//     if (activeFilters.chosen_metrics.length > 0 && mutatedByMetric.length > 0) {
//       data = mutatedByMetric;
//     } else if (
//       // activeFilters.excludedIndexes.length > 0 &&
//       mutatedData.length > 0
//     ) {
//       data = mutatedData;
//     }
//
//     if (activeFilters.zoom8_hex.id !== '') {
//       return data.filter(
//         (item) => item.properties.hex_id === activeFilters.zoom8_hex.id
//       );
//     }
//
//     if (hasActiveFilters(activeFilters) || isochroneStore.length > 0) {
//       return filterHexagons(data, activeFilters, isochroneStore);
//     }
//     return data;
//   },
//   target: $zoom8Hexagons,
// });

sample({
  source: [$activeFilters, $userInfo],
  clock: filterZoom8GradientEv,
  filter: ([activeFilters, userInfo], clock) =>
    activeFilters.gradient.length === 0 && !userInfo.useBackend,
  fn: (source, clock) => filterGradient(clock, 'zoom_8'),
  target: putBlockedBucketsEv,
});

sample({
  source: [$zoom8StartHexagons, $activeFilters],
  clock: updateZoom8HexagonsBy10Ev,
  fn: ([startData, activeFilters], clock) =>
    window.indexWorker.postMessage({
      worker: 'index',
      action: 'calculateByMedian',
      payload: {
        zoom: 'zoom_8',
        startData,
        hexagons10: clock,
        excludedIndexes: activeFilters.excludedIndexes,
        activeFilters,
      },
    }),
  // target: $zoom8MutatedHexagons,
});

sample({
  clock: putMutated8HexagonsEv,
  fn: (clock) => clock,
  target: $zoom8MutatedHexagons,
});

sample({
  // clock: $activeFilters.updates,
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (clock) => clock.excludedIndexes.length === 0,
  fn: () => [],
  target: $zoom8MutatedHexagons,
});

sample({
  source: [$zoom8StartHexagons, $activeFilters],
  clock: calculateByMetricEv,
  filter: ([source, activeFilters], clock) =>
    activeFilters.chosen_metrics.length > 0,
  fn: ([startData, activeFilters], clock) =>
    calculateHexagonsByMetrics(startData, activeFilters),
  target: $zoom8MutatedByMetric,
});

sample({
  clock: $activeFilters.updates,
  filter: (clock) => clock.chosen_metrics.length === 0,
  fn: () => [],
  target: $zoom8MutatedByMetric,
});
