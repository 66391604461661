import React from 'react';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import illustration from '../../../assets/images/sections/illustration/illustration.png';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import rcp_illustration from '../../../assets/images/sections/illustration/rcp_illustration.svg';

function Illustration(props) {
  const language = useUnit($language);

  return (
    <div className="landing_container">
      <div className={style.illustration_wrapper}>
        {/* <img src={rcp_illustration} className={style.illustration_img} /> */}
        <object
          type="image/svg+xml"
          data={rcp_illustration}
          className={style.illustration_animated}
        />
        {/* <div className={style.illustration_test} /> */}
        <div className={style.illustration_cards_wrapper} style={{ top: 60 }}>
          <div className={style.illustration_card} style={{ marginTop: 20 }}>
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_1}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_1}
            </div>
          </div>
          <div className={style.illustration_card}>
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_2}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_2}
            </div>
          </div>
        </div>
        <div className={style.illustration_cards_wrapper} style={{ top: 565 }}>
          <div
            className={style.illustration_card}
            style={{ marginTop: 50, marginLeft: 60 }}
          >
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_3}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_3}
            </div>
          </div>
          <div className={style.illustration_card} style={{ marginRight: 63 }}>
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_4}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_4}
            </div>
          </div>
        </div>
        <div className={style.illustration_cards_wrapper} style={{ top: 803 }}>
          <div className={style.illustration_card} style={{ marginTop: 58 }}>
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_5}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_5}
            </div>
          </div>
          <div className={style.illustration_card}>
            <div className={style.illustration_card_title}>
              {translation[language].illustration_title_6}
            </div>
            <div className={style.illustration_card_description}>
              {translation[language].illustration_description_6}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Illustration;
