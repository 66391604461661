import { createStore } from 'effector';

export const $dataPresets = createStore([]);
$dataPresets.watch((state) => console.log('$dataPresets', state));

export const $showPresets = createStore(false);
$showPresets.watch((state) => console.log('$showPresets', state));

export const $layerToUpdate = createStore('');
$layerToUpdate.watch((state) => console.log('$layerToUpdate', state));

export const $showModal = createStore(false);
$showModal.watch((state) => console.log('$showModal', state));

export const $sortBy = createStore('name');
$sortBy.watch((state) => console.log('$sortBy', state));

export const $sortOrder = createStore('asc');
$sortOrder.watch((state) => console.log('$sortOrder', state));

export const $activeAreas = createStore([]);
$activeAreas.watch((state) => console.log('$activeAreas', state));

export const $presetAreaToEdit = createStore({
  preset: '',
  area: '',
});

$presetAreaToEdit.watch((state) => console.log('$presetAreaToEdit', state));

export const $presetsToColor = createStore([]);
$presetsToColor.watch((state) => console.log('$presetsToColor', state));

export const $nameEdit = createStore('');
export const $colorEdit = createStore('');

export const $userPresets = createStore([]);
$userPresets.watch((state) => console.log('$userPresets', state));
