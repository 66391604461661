import Icon from '@ant-design/icons';

function PenSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.646 3.365 13.912 2.1A1.406 1.406 0 1 1 15.9 4.09L5.124 14.864a3.375 3.375 0 0 1-1.423.848l-2.014.6.6-2.014c.16-.537.452-1.026.848-1.423l9.512-9.51h0Zm0 0 1.979 1.979"
      />
    </svg>
  );
}

export function PenIcon() {
  return <Icon component={PenSvg} />;
}

function UpdateSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.017 5.011h3.744l-2.386-2.387A6.188 6.188 0 0 0 2.023 5.399m-.784 7.334V8.99m0 0h3.744m-3.744 0 2.385 2.387a6.188 6.188 0 0 0 10.352-2.775m.785-7.334V5.01"
      />
    </svg>
  );
}

export function UpdateIcon() {
  return <Icon component={UpdateSvg} />;
}

function ExportSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={16} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.188 5.75V2.937A1.687 1.687 0 0 1 7.875 1.25h4.5a1.687 1.687 0 0 1 1.688 1.688v10.124a1.687 1.687 0 0 1-1.688 1.688h-4.5a1.687 1.687 0 0 1-1.688-1.688V10.25M9 5.75 11.25 8m0 0L9 10.25M11.25 8H1.687"
      />
    </svg>
  );
}

export function ExportIcon() {
  return <Icon component={ExportSvg} />;
}

function DeleteSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={18} fill="none">
      <path
        fill="currentColor"
        d="M4.946 2.41h-.16c.088 0 .16-.072.16-.16v.16h6.107v-.16c0 .088.073.16.161.16h-.16v1.447H12.5V2.25c0-.71-.577-1.286-1.286-1.286H4.786C4.076.964 3.5 1.54 3.5 2.25v1.607h1.446V2.41Zm10.125 1.447H.93a.642.642 0 0 0-.643.643v.642c0 .089.072.161.16.161H1.66l.496 10.507a1.287 1.287 0 0 0 1.284 1.225h9.12c.687 0 1.252-.538 1.284-1.225l.496-10.507h1.213c.089 0 .161-.072.161-.16V4.5a.642.642 0 0 0-.643-.643Zm-2.665 11.732H3.594L3.108 5.303h9.784l-.486 10.286Z"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return <Icon component={DeleteSvg} />;
}
