import { Card, Space } from 'antd';
import { useState } from 'react';
import { useUnit } from 'effector-react';
import style from './LeftBar.module.css';
import MetricTree from './MetricTree.jsx';
import ResRadar from './ResRadar.jsx';
import { $showPresets } from '../../models/dataPresetsModel/index.js';
import DataPresets from '../DataPresets/DataPresets.jsx';

export default function LeftBar() {
  const [expanded, setExpanded] = useState(['1']);

  const onExpand = (value) => {
    setExpanded(value);
  };

  return (
    <Space direction="vertical" className={style.left_bar} id="left_bar">
      <ResRadar onExpand={onExpand} expanded={expanded} />
      <div style={{ position: 'relative', height: '100%' }}>
        <DataPresets />
        <MetricTree expanded={expanded} />
      </div>
    </Space>
  );
}
