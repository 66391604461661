import { createStore } from 'effector';

const initialState = [];

export const $zoom8Hexagons = createStore(initialState);
$zoom8Hexagons.watch((state) => console.log('$zoom8Hexagons', state));

export const $zoom8StartHexagons = createStore(initialState);

export const $zoom8MutatedHexagons = createStore([]);

export const $zoom8MutatedByMetric = createStore([]);
