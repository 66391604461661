import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react/compat';
import { useEffect, useState } from 'react';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { showLoaderEv } from '../../models/webSocketModel/index.js';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import { $indexTree } from '../../models/indexCardsModel/index.js';
import { $isDarkTheme } from '../../models/authModel/index.js';

function IndexTree(props) {
  const { expanded } = props;
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const showLoader = useUnit(showLoaderEv);
  const { excludedIndexes } = useUnit($activeFilters);
  const treeData = useUnit($indexTree);
  const darkMode = useUnit($isDarkTheme);

  const [checkedKeys, setCheckedKeys] = useState([
    'index_1',
    'index_2',
    'index_3',
    'index_4',
    'index_5',
  ]);

  const onCheck = (nodes, e) => {
    let value;
    if (/index_\d{3}/g.test(e.node.key)) {
      value = [e.node.key];
      // } else if (e.node.checked && !e.node.halfChecked) {
    } else if (/index_\d{1}/g.test(e.node.key)) {
      if (!e.node.checked && e.node.halfChecked) {
        value = excludedIndexes.filter((item) => item.includes(e.node.key));
      } else {
        value = e.node.children.map((item) => item.key);
      }
    }
    // if (
    //   excludedIndexes.length === 0 ||
    //   !excludedIndexes.every((item) => value.includes(item))
    // ) {
    //   showLoader();
    // }
    changeActiveFilter({
      field: 'excludedIndexes',
      value,
    });
  };

  useEffect(() => {
    const metrics = [
      ...treeData[0].children,
      ...treeData[1].children,
      ...treeData[2].children,
      ...treeData[3].children,
      ...treeData[4].children,
    ];
    setCheckedKeys(
      metrics
        .filter((item) => !excludedIndexes.includes(item.key))
        .map((item) => item.key)
    );
  }, [excludedIndexes]);

  const formatTreeData = () => {
    return treeData.map((item) => {
      const excludedMetrics = excludedIndexes.filter((metric) =>
        metric.includes(item.key)
      ).length;
      return {
        ...item,
        title: `${item.title} (${item.children.length - excludedMetrics})`,
      };
    });
  };
  return (
    <Tree
      style={{ color: darkMode ? 'white' : '#717171' }}
      showLine
      showIcon
      switcherIcon={<DownOutlined />}
      treeData={formatTreeData()}
      checkedKeys={checkedKeys}
      defaultExpandedKeys={['index_1']}
      onCheck={onCheck}
      multiple
      checkable
      // height={expanded ? 237 : 360}
      // height={237}
      selectable={false}
    />
  );
}

export default IndexTree;
