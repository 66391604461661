import { createEvent } from 'effector';

export const putZoom9HexagonsEv = createEvent();

export const updateZoom9HexagonsBy10Ev = createEvent();

export const resetZoom9ByMetricsEv = createEvent();

export const putMutated9HexagonsEv = createEvent();

export const putFilteredZoom9HexagonsEv = createEvent();

export const filterZoom9GradientEv = createEvent();
