import { Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './MapControls.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { saveHexDataEv } from '../../models/indexCardsModel/index.js';

function HexagonSvg() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8001 21.5382C12.5087 21.7065 12.3629 21.7906 12.208 21.8235C12.0709 21.8527 11.9293 21.8527 11.7922 21.8235C11.6373 21.7906 11.4915 21.7065 11.2001 21.5382L4.13984 17.462C3.8484 17.2937 3.70268 17.2096 3.5967 17.0919C3.50293 16.9877 3.43209 16.865 3.38879 16.7318C3.33984 16.5811 3.33984 16.4129 3.33984 16.0763V7.92385C3.33984 7.58732 3.33984 7.41905 3.38879 7.26842C3.43209 7.13514 3.50293 7.01245 3.5967 6.9083C3.70268 6.7906 3.8484 6.70647 4.13984 6.5382L11.2001 2.46196C11.4915 2.2937 11.6373 2.20957 11.7922 2.17664C11.9293 2.1475 12.0709 2.1475 12.208 2.17664C12.3629 2.20957 12.5087 2.2937 12.8001 2.46196L19.8604 6.5382C20.1518 6.70647 20.2975 6.7906 20.4035 6.9083C20.4973 7.01245 20.5681 7.13514 20.6114 7.26842C20.6604 7.41905 20.6604 7.58732 20.6604 7.92384V16.0763C20.6604 16.4129 20.6604 16.5811 20.6114 16.7318C20.5681 16.865 20.4973 16.9877 20.4035 17.0919C20.2975 17.2096 20.1518 17.2937 19.8604 17.462L12.8001 21.5382Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function HexagonIcon() {
  return <Icon component={HexagonSvg} />;
}

export default function MapControls() {
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const saveHexData = useUnit(saveHexDataEv);
  const activeFilters = useUnit($activeFilters);
  const removeHex = (level) => {
    const clear_order = [10, 9, 8];
    clear_order.forEach((item) => {
      if (+level <= item && activeFilters[`zoom${item}_hex`].id !== '') {
        changeActiveFilter({
          field: `zoom${item}_hex`,
          value: {
            id: '',
            centerCoords: [],
          },
        });
        saveHexData({
          level: `zoom_${item}`,
          data: {
            id: '',
            indexes: [],
          },
        });
      }
    });
  };

  return (
    <div className={style.buttons_wrapper}>
      {activeFilters.zoom8_hex.id !== '' ? (
        <Tooltip title="Remove 8 hexagon" placement="left">
          <Button
            icon={<HexagonIcon />}
            className={style.button}
            onClick={() => removeHex('8')}
          />
        </Tooltip>
      ) : (
        ''
      )}
      {activeFilters.zoom9_hex.id !== '' ? (
        <Tooltip title="Remove 9 hexagon" placement="left">
          <Button
            icon={<HexagonIcon />}
            className={style.button}
            onClick={() => removeHex('9')}
          />
        </Tooltip>
      ) : (
        ''
      )}
      {activeFilters.zoom10_hex.id !== '' ? (
        <Tooltip title="Remove 10 hexagon" placement="left">
          <Button
            icon={<HexagonIcon />}
            className={style.button}
            onClick={() => removeHex('10')}
          />
        </Tooltip>
      ) : (
        ''
      )}
    </div>
  );
}
