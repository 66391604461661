import Header from './sections/Header.jsx';
import Promo from './sections/Promo.jsx';
import TrustedBy from './sections/TrustedBy.jsx';
import Illustration from './sections/Illustration.jsx';
import Products from './sections/Products.jsx';
import Features from './sections/Features.jsx';
import CasesB2B from './sections/CasesB2B.jsx';
import CasesB2G from './sections/CasesB2G.jsx';
import GetFullVersion from './sections/GetFullVersion.jsx';
import Request from './sections/Request.jsx';
import Footer from './sections/Footer.jsx';
import BgLines from './sections/BGLines.jsx';

export default function Landing() {
  return (
    <>
      <Header />
      <Promo />
      <TrustedBy />
      <Illustration />
      <Products />
      <Features />
      <CasesB2G />
      <CasesB2B />
      <GetFullVersion />
      <Request />
      <Footer />
      <BgLines />
    </>
  );
}
