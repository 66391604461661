import React from 'react';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import products_1 from '../../../assets/images/sections/products/products_1.png';
import products_2 from '../../../assets/images/sections/products/products_2.png';
import products_3 from '../../../assets/images/sections/products/products_3.png';

function Products(props) {
  const language = useUnit($language);

  const products = [
    {
      imgSrc: products_1,
      title: translation[language].product_1_title,
      description: translation[language].product_1_description,
      tags: [
        translation[language].product_1_tag_1,
        translation[language].product_1_tag_2,
        translation[language].product_1_tag_3,
      ],
    },
    {
      imgSrc: products_2,
      title: translation[language].product_2_title,
      description: translation[language].product_2_description,
      tags: [
        translation[language].product_2_tag_1,
        translation[language].product_2_tag_2,
      ],
    },
    {
      imgSrc: products_3,
      title: translation[language].product_3_title,
      description: translation[language].product_3_description,
      tags: [
        translation[language].product_3_tag_1,
        translation[language].product_3_tag_2,
        translation[language].product_3_tag_3,
        translation[language].product_3_tag_4,
        translation[language].product_3_tag_5,
      ],
    },
  ];

  return (
    <div className="landing_container">
      <div className={style.products_wrapper} id="products">
        <h1 className={style.products_title}>
          {translation[language].products_title}
        </h1>
        <div className={style.products_cards_wrapper}>
          {products.map((product, index) => {
            return (
              <ProductsCard
                key={`product-${index}`}
                cardIndex={index}
                {...product}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ProductsCard(props) {
  const { imgSrc, title, description, tags, cardIndex } = props;
  return (
    <div className={style.products_card}>
      <img src={imgSrc} />
      <div className={style.products_card_text_wrapper}>
        <h1 className={style.products_card_title}>{title}</h1>
        <div className={style.products_card_description}>{description}</div>
      </div>
      <div className={style.products_card_tags_wrapper}>
        {tags.map((item, index) => {
          return (
            <ProductsTag key={`card-${cardIndex}-tag-${index}`} text={item} />
          );
        })}
      </div>
    </div>
  );
}

function ProductsTag({ text }) {
  return <div className={style.products_card_tag}>{text}</div>;
}

export default Products;
