import { createEvent } from 'effector';

export const setMapLoadedEv = createEvent();

export const resetMapLoadedEv = createEvent();

export const changeInteractivityEv = createEvent();

export const putSearchOptionsEv = createEvent();

export const changeDrawModeEv = createEvent();

export const changeLayersOpacityEv = createEvent();

export const changeHexagonsHeightEv = createEvent();

export const changeInvertHeightEv = createEvent();

export const changeRbpLayerEv = createEvent();
