import booleanIntersects from '@turf/boolean-intersects';
import {
  calculate10Hexagons,
  calculateHexagonsByMetrics,
  calculateIndexesByMedian,
  formDynamicBuckets,
} from './hexagon-utils.js';
import { formChartFromFeature } from './index-widgets-utils.js';

export const separateHexByPolygon = (hexagons, polygons) => {
  return hexagons.map((item) => {
    let hex_polygon = null;
    polygons.forEach((polygon) => {
      if (booleanIntersects(polygon, item)) {
        hex_polygon = polygon.id;
      }
    });

    return {
      ...item,
      properties: {
        ...item.properties,
        hex_polygon,
      },
    };
  });
};

export function formatPreset(preset, startHexagons, language) {
  let hexagons = [];
  startHexagons.forEach((hexagon) => {
    Object.keys(preset.areas).forEach((area) => {
      if (preset.areas[area].includes(hexagon.properties.hex_id)) {
        hexagons.push({
          ...hexagon,
          properties: {
            ...hexagon.properties,
            area,
          },
        });
      }
    });
  });
  if (preset.activeFilters.excludedIndexes.length > 0) {
    hexagons = calculate10Hexagons(
      hexagons,
      preset.activeFilters.excludedIndexes
    ).data;
  } else if (preset.activeFilters.chosen_metrics.length > 0) {
    hexagons = calculateHexagonsByMetrics(hexagons, preset.activeFilters);
  }

  const chartData = formChartFromFeature(
    calculateIndexesByMedian(
      hexagons.map((item) => item.properties),
      preset.activeFilters.excludedIndexes,
      preset.activeFilters,
      true
    ),
    language
  );

  const areasChartData = {};
  Object.keys(preset.areas).forEach((area) => {
    areasChartData[area] = formChartFromFeature(
      calculateIndexesByMedian(
        hexagons
          .filter((hex) => hex.properties.area === area)
          .map((item) => item.properties),
        preset.activeFilters.excludedIndexes,
        preset.activeFilters,
        true
      ),
      language
    );
  });

  return {
    ...preset,
    hexagons,
    chartData,
    areasChartData,
    id: preset.id,
    created_by: preset.created_by,
    created_at: preset.created_at,
    updated_at: preset.updated_at,
  };
}

export const formatPresets = (presets, startHexagons, language) => {
  return presets.map((item) => {
    return formatPreset(
      {
        ...item.preset,
        id: item.id,
        created_by: item.created_by_name,
        created_at: item.created_at,
        updated_at: item.updated_at,
      },
      startHexagons,
      language
    );
  });
};

function sortByString(a, b) {
  return a.localeCompare(b);
}

function sortByNumber(a, b) {
  return a - b;
}

function sortByDate(a, b) {
  return new Date(b).getTime() - new Date(a).getTime();
}

export const sortDataPresets = ([dataPresets, sortBy, sortOrder]) => {
  let result;
  result = dataPresets.sort((a, b) => {
    if (sortBy === 'name' || sortBy === 'author') {
      return sortByString(a[sortBy], b[sortBy]);
    }
    if (sortBy === 'created_at' || sortBy === 'updated_at') {
      return sortByDate(a[sortBy], b[sortBy]);
    }
    if (sortBy === 'index') {
      const getIndexMain = (chartData) => {
        return chartData.reduce((a1, b1) => a1 + parseInt(b1.value), 0) / 5;
      };

      return sortByNumber(getIndexMain(a.chartData), getIndexMain(b.chartData));
    }
    return sortByNumber(
      a.chartData.find((item) => item.index_name === sortBy).value,
      b.chartData.find((item) => item.index_name === sortBy).value
    );
  });

  if (sortOrder === 'desc') result = result.reverse();
  return result;
};

export const separateHexagonsToAreas = (
  hexagons,
  polygons,
  districts,
  preset_name,
  isochrone
) => {
  const temp = {};

  hexagons.forEach((hexagon) => {
    if (polygons.length > 0) {
      polygons.forEach((polygon) => {
        if (booleanIntersects(polygon, hexagon)) {
          if (!temp[polygon.id]) {
            temp[polygon.id] = [hexagon.properties.hex_id];
          } else {
            temp[polygon.id].push(hexagon.properties.hex_id);
          }
        }
      });
    }
    if (districts.length > 0) {
      if (districts.includes(hexagon.properties.city_region_id)) {
        if (
          temp[`district_${preset_name}_${hexagon.properties.city_region_id}`]
        ) {
          temp[
            `district_${preset_name}_${hexagon.properties.city_region_id}`
          ].push(hexagon.properties.hex_id);
        } else {
          temp[`district_${preset_name}_${hexagon.properties.city_region_id}`] =
            [hexagon.properties.hex_id];
        }
      }
    }
    if (isochrone.length > 0) {
      isochrone.forEach((zone) => {
        if (booleanIntersects(zone, hexagon)) {
          if (!temp[zone.properties.name]) {
            temp[zone.properties.name] = [hexagon.properties.hex_id];
          } else {
            temp[zone.properties.name].push(hexagon.properties.hex_id);
          }
        }
      });
    }
  });

  return temp;
};

export const recalculateDataPresets = (presets, activeAreas, language) => {
  return presets.map((preset) => {
    let { hexagons } = preset;

    if (activeAreas?.length > 0) {
      hexagons = hexagons.filter((hexagon) =>
        activeAreas.includes(hexagon.properties.area)
      );
    }

    if (hexagons.length === 0) hexagons = preset.hexagons;

    return {
      ...preset,
      chartData: formChartFromFeature(
        calculateIndexesByMedian(
          hexagons.map((item) => item.properties),
          preset.activeFilters.excludedIndexes,
          preset.activeFilters,
          true
        ),
        language
      ),
    };
  });
};

export const formPresetBuckets = (
  buckets,
  colors,
  areasToColor,
  defaultColor
) => {
  const result = [
    'case',
    ['any', ...areasToColor.map((area) => ['==', ['get', 'area'], area])],
    formDynamicBuckets(buckets, colors),
    defaultColor,
  ];
  return result;
};

export const validateCreatePreset = (preset, activeFilters, isochroneStore) => {
  const errors = [];
  if (!preset.name) {
    errors.push('preset_error_name');
  }
  if (
    activeFilters.draw_polygon.length === 0 &&
    activeFilters.district.length === 0 &&
    isochroneStore.length === 0
  ) {
    errors.push('preset_error_areas');
  }

  return errors;
};
