import { Button, Drawer, Popconfirm } from 'antd';
import { useUnit } from 'effector-react';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $language } from '../../models/authModel/index.js';
import {
  $presetAreaToEdit,
  $showModal,
  changeShowModalEv,
} from '../../models/dataPresetsModel/index.js';
import style from './PresetsSideModal.module.css';
import PresetsContent from './PresetsContent.jsx';

export default function PresetsSideModal() {
  const language = useUnit($language);
  const showModal = useUnit($showModal);
  const changeShowModal = useUnit(changeShowModalEv);
  const presetAreaToEdit = useUnit($presetAreaToEdit);

  const [currentPage, setCurrentPage] = useState(null);

  const onClose = () => {
    changeShowModal();
  };

  const getBack = () => {
    // changePresetAreaToEdit({ preset: presetAreaToEdit.preset, area: '' });
    setCurrentPage(0);
  };

  useEffect(() => {
    // if (presetAreaToEdit.preset) {
    //   setCurrentPage(1);
    // }
    if (!presetAreaToEdit.preset && !presetAreaToEdit.area) {
      setCurrentPage(null);
    }
  }, [presetAreaToEdit]);

  return (
    <Drawer
      title={
        <div className={style.modal_title_wrapper}>
          {currentPage ? (
            <div>
              <Button
                className={style.back_button}
                icon={<LeftOutlined style={{ fontSize: '12px' }} />}
                onClick={getBack}
              />
              {ru_en_page_dictionary[`presets_${currentPage}`][language]}
            </div>
          ) : (
            ru_en_page_dictionary.presets[language]
          )}
        </div>
      }
      placement="right"
      onClose={onClose}
      open={showModal}
      closable={false}
      width={600}
      classNames={{
        header: style.modal_header,
        body: style.modal_body,
      }}
    >
      <PresetsContent currentPage={currentPage} />
      <Button
        shape="circle"
        className={[style.close_button, style.close_button_desktop].join(' ')}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </Drawer>
  );
}
