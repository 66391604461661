import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import routes from './router/Router.jsx';
import workersHandler from './workers/workersHandler.js';

const indexWorker = new Worker(
  new URL('./workers/indexWorker.js', import.meta.url),
  {
    type: 'module',
  }
);
window.indexWorker = indexWorker;

indexWorker.addEventListener('message', (event) => {
  workersHandler(event.data);
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={routes} />
  </React.StrictMode>
);
