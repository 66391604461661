import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Image } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import casesb2b_1 from '../../../assets/images/sections/casesb2b/casesb2b_1.png';
import casesb2b_2 from '../../../assets/images/sections/casesb2b/casesb2b_2.png';
import casesb2b_3 from '../../../assets/images/sections/casesb2b/casesb2b_3.png';
import style from '../Landing.module.css';

function CasesB2B(props) {
  const language = useUnit($language);

  const casesItems = [
    {
      title: translation[language].casesb2b_1_title,
      description_1: translation[language].casesb2b_1_description_1,
      description_2: translation[language].casesb2b_1_description_2,
      description_3: translation[language].casesb2b_1_description_3,
      imgSrc: casesb2b_1,
    },
    {
      title: translation[language].casesb2b_2_title,
      description_1: translation[language].casesb2b_2_description_1,
      description_2: translation[language].casesb2b_2_description_2,
      description_3: translation[language].casesb2b_2_description_3,
      imgSrc: casesb2b_2,
    },
    {
      title: translation[language].casesb2b_3_title,
      description_1: translation[language].casesb2b_3_description_1,
      description_2: translation[language].casesb2b_3_description_2,
      description_3: translation[language].casesb2b_3_description_3,
      imgSrc: casesb2b_3,
    },
  ];
  return (
    <div className="landing_container">
      <div className={style.casesb2b_wrapper}>
        <h1 className={style.casesb2b_title}>
          {translation[language].casesb2b_title}
        </h1>
        <div className={style.casesb2b_items_wrapper}>
          {casesItems.map((item, index) => {
            return <CaseItem key={`case-item-${index}`} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
}

function CaseItem(props) {
  const { title, description_1, description_2, description_3, imgSrc } = props;
  const language = useUnit($language);
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  return (
    <div className={style.casesb2b_card}>
      <div className={style.casesb2b_card_title}>{title}</div>
      <div className={style.casesb2b_card_description}>{description_1}</div>
      <div className={style.casesb2b_card_subtitle}>
        {translation[language].casesb2b_subtitle_1}
      </div>
      <div className={style.casesb2b_card_description}>{description_2}</div>
      <div className={style.casesb2b_card_subtitle}>
        {translation[language].casesb2b_subtitle_2}
      </div>
      <div className={style.casesb2b_card_description}>{description_3}</div>

      <div className={style.casesb2b_image_wrapper}>
        <Image
          src={imgSrc}
          style={{ cursor: 'pointer' }}
          preview={{
            visible: isPreviewVisible,
            onVisibleChange: (visible, prevVisible) =>
              setPreviewVisible(visible),
            mask: null,
          }}
        />
        <Button
          icon={<ArrowsAltOutlined rotate={90} />}
          shape="circle"
          className={style.casesb2b_expand_button}
          onClick={() => setPreviewVisible(!isPreviewVisible)}
        />
      </div>
    </div>
  );
}

export default CasesB2B;
