import {
  putCalculated10HexagonsEv,
  putCalculatedExcByPresetEv,
} from '../models/zoom10Model/index.js';
import {
  updateCardsByZoom10Ev,
  updateChartByZoom10Ev,
} from '../models/indexCardsModel/index.js';
import {
  delayedHideLoaderEv,
  hideCardsLoaderEv,
  hideRadarLoaderEv,
} from '../models/webSocketModel/index.js';
import { putMutated8HexagonsEv } from '../models/zoom8Model/index.js';
import { putMutated9HexagonsEv } from '../models/zoom9Model/index.js';
import { putBlockedBucketsEv } from '../models/gradientModel/index.js';

const workersHandler = (data) => {
  const { action, payload } = data;
  console.log('WORKER DONE CALCULATION', action);
  switch (action) {
    case 'calculate10Hexagons':
      putCalculated10HexagonsEv(payload.data);
      // delayedHideLoaderEv();
      break;
    case 'calculate10HexagonsByPreset':
      putCalculatedExcByPresetEv(payload.data);
      break;
    case 'calculateTotalIndex':
      updateChartByZoom10Ev(payload);
      updateCardsByZoom10Ev(payload);
      hideRadarLoaderEv();
      hideCardsLoaderEv();
      break;
    case 'calculateByMedian': {
      const { zoom, mutatedData, gradientBlock } = payload;
      if (zoom === 'zoom_9') {
        putMutated9HexagonsEv(mutatedData);
      } else {
        putMutated8HexagonsEv(mutatedData);
      }
      break;
    }
    default:
      console.log('WORKER UNKNOWN ACTION', action);
      break;
  }
};

export default workersHandler;
