import { createStore } from 'effector';

const initialStore = {
  201: {
    chosen_business: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
    other: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
  },
  202: {
    chosen_business: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
    other: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
  },
  203: {
    chosen_business: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
    other: {
      weekend_evening: Math.floor(Math.random() * 100),
      weekend_day: Math.floor(Math.random() * 100),
      weekdays_evening: Math.floor(Math.random() * 100),
      weekdays_day: Math.floor(Math.random() * 100),
    },
  },
  204: {
    living_rent: Math.floor(Math.random() * 100),
    commerce_rent: Math.floor(Math.random() * 100),
    commerce_sell: Math.floor(Math.random() * 100),
  },
};

export const $economicsStore = createStore(initialStore);
$economicsStore.watch((state) => console.log('$economicsStore', state));
