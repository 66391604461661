import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { $indexChartData } from '../../models/indexCardsModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $isDarkTheme,
  $isTablet,
  $language,
} from '../../models/authModel/index.js';
import style from './RadarChart.module.css';
import {
  $activeAreas,
  $dataPresets,
  $showPresets,
} from '../../models/dataPresetsModel/index.js';

export default function RadarChartNew(props) {
  const { showInfo } = props;
  const showPresets = useUnit($showPresets);
  const dataPresets = useUnit($dataPresets);
  const indexChartData = useUnit($indexChartData);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const isTablet = useUnit($isTablet);
  const chartRef = useRef();
  const activeAreas = useUnit($activeAreas);

  const activeDataPresets = dataPresets.filter((preset) =>
    activeAreas.some((area) => preset.areas[area])
  );

  const options = {
    markers: {
      size: 7,
      colors: showPresets
        ? activeDataPresets.map((item) => item.color)
        : '#2D9CDB',
    },
    stroke: {
      colors: showPresets
        ? activeDataPresets.map((item) => item.color)
        : ['#2D9CDB'],
    },
    fill: {
      colors: showPresets
        ? activeDataPresets.map((item) => item.color)
        : '#2D9CDB',
      opacity: 0.5,
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.2,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        inverseColors: false,
      },
      // gradient: {
      //   type: 'vertical',
      //   shadeIntensity: 1,
      //   colorStops: [
      //     {
      //       offset: 75,
      //       color: '#5199D1BF',
      //       opacity: 0.8,
      //     },
      //     {
      //       offset: 100,
      //       color: 'black',
      //       // color: '#5199D15C',
      //       opacity: 0.1,
      //     },
      //   ],
      // },
    },
    chart: {
      offsetY: 20,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      floating: true,
      categories: indexChartData.map((item) =>
        item.index_name ? `i${item.index_name?.split('index_')[1]}` : ''
      ),
      labels: {
        offsetX: 0,
        offsetY: 8,
        show: true,
        style: {
          colors: [
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
          ],
          fontSize: '25px',
          fontFamily: 'icomoon',
        },
      },
    },
    dataLabels: {
      offsetY: 20,
      enabled: true,
      style: {
        colors: showPresets
          ? activeDataPresets.map((item) => item.color)
          : ['#2D9CDB'],
      },
      background: {
        enabled: true,
        borderRadius: 2,
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      x: {
        formatter: (value, opts) => {
          if (value === 'i0') {
            return ru_en_page_dictionary.resilience_index[language];
          }
          return indexChartData[opts.dataPointIndex].title;
        },
      },
      y: {
        title: {
          formatter: () => `${ru_en_page_dictionary.radar_tooltip[language]}:`,
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      offsetY: -20,
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
      markers: {
        fillColors: showPresets
          ? activeDataPresets.map((item) => item.color)
          : undefined,
      },
    },
  };

  const series = !showPresets
    ? [
        {
          name: ru_en_page_dictionary.radar_tooltip[language],
          data: indexChartData.map((item) => item.value),
        },
      ]
    : activeDataPresets.map((preset) => {
        return {
          name: preset.name,
          data: [...preset.chartData.map((item) => item.value)],
        };
      });

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.ctx.updateOptions(options);
    }
  }, [indexChartData, darkMode]);

  const genereateTooltipValue = () => {
    return (
      <div className={style.tooltip_wrapper}>
        {indexChartData.map((item) => {
          const index = item.index_name.split('index_')[1];
          return (
            <div
              key={`tooltip-${item.index_name}`}
              className={style.tooltip_item}
            >
              <div className={style.tooltip_icon}>{`i${index}`}</div>
              {item.title} - {item.value}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={style.radar_wrapper}>
      {showInfo !== false ? (
        <Tooltip
          title={genereateTooltipValue()}
          placement="bottomRight"
          arrow={false}
        >
          <QuestionCircleFilled className={style.question_icon} />
        </Tooltip>
      ) : (
        ''
      )}
      <Chart
        ref={chartRef}
        options={options}
        series={series}
        type="radar"
        width="100%"
        height={isTablet ? '267' : '256'}
        padding="15"
      />
    </div>
  );
}
