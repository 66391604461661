import { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import RadarChartNew from '../RadarChart/RadarChartNew.jsx';
import BarChart from '../BarChart/BarChart.jsx';
import ColumnChart from '../ColumnChart/ColumnChart.jsx';
import style from './TabletChartsManager.module.css';
import {
  BarChartIcon,
  ColumnChartIcon,
  RadarChartIcon,
} from '../LeftBar/ResRadar.jsx';
import {
  $activeAreas,
  $showPresets,
} from '../../models/dataPresetsModel/index.js';

export default function TabletChartsManager() {
  const [chartType, setChartType] = useState('radar');
  const showPresets = useUnit($showPresets);
  const activeAreas = useUnit($activeAreas);

  return (
    <div>
      <div className={style.chart_buttons_wrapper}>
        <Button
          type={chartType === 'bar' ? 'primary' : 'default'}
          className={style.chart_switch}
          onClick={() => setChartType('bar')}
          icon={<BarChartIcon />}
        />
        <Button
          type={chartType === 'radar' ? 'primary' : 'default'}
          className={style.chart_switch}
          onClick={() => setChartType('radar')}
          icon={<RadarChartIcon />}
        />
        <Button
          type={chartType === 'column' ? 'primary' : 'default'}
          className={style.chart_switch}
          onClick={() => setChartType('column')}
          icon={<ColumnChartIcon />}
        />
      </div>
      {showPresets && activeAreas.length === 0 ? (
        ''
      ) : chartType === 'radar' ? (
        <RadarChartNew showInfo={false} />
      ) : chartType === 'bar' ? (
        <BarChart showInfo={false} />
      ) : (
        <ColumnChart showInfo={false} />
      )}
    </div>
  );
}
