import { createStore } from 'effector';

const initialStore = {
  101: {
    male: {
      '1-4': Math.floor(Math.random() * 100),
      '5-9': Math.floor(Math.random() * 100),
      '10-14': Math.floor(Math.random() * 100),
      '15-19': Math.floor(Math.random() * 100),
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
    female: {
      '1-4': Math.floor(Math.random() * 100),
      '5-9': Math.floor(Math.random() * 100),
      '10-14': Math.floor(Math.random() * 100),
      '15-19': Math.floor(Math.random() * 100),
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
  },
  102: {
    male: {
      '1-4': Math.floor(Math.random() * 100),
      '5-9': Math.floor(Math.random() * 100),
      '10-14': Math.floor(Math.random() * 100),
      '15-19': Math.floor(Math.random() * 100),
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
    female: {
      '1-4': Math.floor(Math.random() * 100),
      '5-9': Math.floor(Math.random() * 100),
      '10-14': Math.floor(Math.random() * 100),
      '15-19': Math.floor(Math.random() * 100),
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
  },
  103: {
    male: {
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
    female: {
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
  },
  104: {
    male: {
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
    female: {
      '20-24': Math.floor(Math.random() * 100),
      '25-29': Math.floor(Math.random() * 100),
      '30-34': Math.floor(Math.random() * 100),
      '35-39': Math.floor(Math.random() * 100),
      '40-44': Math.floor(Math.random() * 100),
      '45-49': Math.floor(Math.random() * 100),
      '50-54': Math.floor(Math.random() * 100),
      '55-59': Math.floor(Math.random() * 100),
      '60-64': Math.floor(Math.random() * 100),
      '65+': Math.floor(Math.random() * 100),
    },
  },
};

export const $socdemStore = createStore(initialStore);
$socdemStore.watch((state) => console.log('$socdemStore', state));
