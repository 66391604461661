import { sample } from 'effector';
import { putSocdemDataEv } from './events.js';
import { $socdemStore } from './stores.js';

sample({
  clock: putSocdemDataEv,
  fn: (clock) => {
    const copy = { ...clock };
    // FIXME Deletes <18 from socdems
    Object.keys(copy).forEach((chart) => {
      Object.keys(copy[chart]).forEach((gender) => {
        Object.keys(copy[chart][gender]).forEach((age) => {
          if (age === '<18') {
            delete copy[chart][gender][age];
          }
        });
      });
    });

    return copy;
  },
  target: $socdemStore,
});
