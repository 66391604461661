import { Button, Card, Divider } from 'antd';
import { useUnit } from 'effector-react';
import style from './LeftBar.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import DatesSelector from '../DatesSelector/DatesSelector.jsx';
import DistrictsSelector from '../DistrictsSelector/DistrictsSelector.jsx';
import IndexTree from '../IndexTree/IndexTree.jsx';
import {
  $isDarkTheme,
  $isTablet,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import {
  $activeFilters,
  clearFiltersEv,
  selectAllEv,
  submitFiltersBackEv,
  submitFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import { showLoaderEv } from '../../models/webSocketModel/index.js';
import { $showPresets } from '../../models/dataPresetsModel/index.js';

export default function MetricTree(props) {
  const { expanded } = props;
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const clearFilters = useUnit(clearFiltersEv);
  const selectAll = useUnit(selectAllEv);
  const submitFilters = useUnit(submitFiltersEv);
  const submitFiltersBack = useUnit(submitFiltersBackEv);
  const showLoader = useUnit(showLoaderEv);
  const { excludedIndexes } = useUnit($activeFilters);
  const { use_backend } = useUnit($userInfo);
  const isTablet = useUnit($isTablet);
  const showPresets = useUnit($showPresets);

  const onSubmitFilters = () => {
    if (excludedIndexes.length > 0) {
      showLoader();
    }
    if (use_backend) {
      submitFiltersBack();
    } else {
      submitFilters();
    }
  };

  return (
    <Card
      className={[
        style.metrics_tree,
        showPresets ? style.hide : style.show,
      ].join(' ')}
      bodyStyle={{
        padding: isTablet ? '10px' : '20px',
        height: '100%',
      }}
      style={{
        border: isTablet ? 'unset' : '1 px solid rgb(240, 240, 240)',
      }}
    >
      {!isTablet ? (
        <div
          className={style.filters_title}
          style={{ color: darkMode ? 'white' : 'black' }}
        >
          {ru_en_page_dictionary.filters[language]}
        </div>
      ) : (
        ''
      )}
      <div className={style.selects_wrapper}>
        <DatesSelector />
        <DistrictsSelector />
      </div>
      <div
        className={style.params_wrapper}
        style={{ color: darkMode ? 'white' : '#717171' }}
      >
        {ru_en_page_dictionary.params[language]}
        <div>
          <Button className={style.params_button} onClick={clearFilters}>
            {ru_en_page_dictionary.reset_button[language]}
          </Button>
          <Button className={style.params_button} onClick={selectAll}>
            {ru_en_page_dictionary.select_all[language]}
          </Button>
        </div>
      </div>
      <div className={style.index_tree_wrapper}>
        <div className="index_tree_scroll">
          <IndexTree expanded={expanded.length > 0} />
        </div>
      </div>
      <div>
        <Divider
          style={{
            margin: '15px 0px',
            borderBlockStart: darkMode
              ? '1px solid white'
              : '1px solid rgba(5, 5, 5, 0.06)',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={onSubmitFilters}
            className={style.filter_button}
            type="primary"
          >
            {ru_en_page_dictionary.submit_button[language]}
          </Button>
        </div>
      </div>
    </Card>
  );
}
