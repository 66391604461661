import React, { useRef } from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import features_1 from '../../../assets/images/sections/features/features_1.png';
import features_2 from '../../../assets/images/sections/features/features_2.png';
import features_3 from '../../../assets/images/sections/features/features_3.png';
import features_4 from '../../../assets/images/sections/features/features_4.png';

function Features(props) {
  const language = useUnit($language);
  const slickRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    // cssEase: 'linear',
  };

  const featuresItems = [
    {
      tag: translation[language].features_1_tag,
      text: translation[language].features_1_text,
      imgSrc: features_1,
    },
    {
      tag: translation[language].features_2_tag,
      text: translation[language].features_2_text,
      imgSrc: features_2,
    },
    {
      tag: translation[language].features_3_tag,
      text: translation[language].features_3_text,
      imgSrc: features_3,
    },
    {
      tag: translation[language].features_4_tag,
      text: translation[language].features_4_text,
      imgSrc: features_4,
    },
  ];
  const prevSlide = () => {
    slickRef.current.slickPrev();
  };

  const nextSlide = () => {
    slickRef.current.slickNext();
  };

  return (
    <div className="landing_container">
      <div className={style.features_wrapper} id="features">
        <div className={style.features_top}>
          <h1 className={style.features_title}>
            {translation[language].features_title}
          </h1>
          <div className={style.features_buttons_wrapper}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              className={style.features_button}
              onClick={prevSlide}
            />
            <Button
              shape="circle"
              icon={<RightOutlined />}
              className={style.features_button}
              onClick={nextSlide}
            />
          </div>
        </div>
        <div className={`slider-container ${style.features_slider_wrapper}`}>
          <Slider {...settings} ref={slickRef}>
            {featuresItems.map((item) => {
              return <FeatureCard key={`feature-${item.tag}`} {...item} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

function FeatureCard(props) {
  const { tag, text, imgSrc } = props;
  return (
    <div className={style.features_card}>
      <div>
        <div className={style.features_tag}>{tag}</div>
        <div className={style.features_text}>{text}</div>
      </div>
      <img src={imgSrc} />
    </div>
  );
}

export default Features;
