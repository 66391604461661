import { createEffect } from 'effector';
import zoom9hexagons from '../../data/zoom9.json';

export const getZoom9HexagonsFx = createEffect().use(async () => {
  return new Promise((resolve) =>
    setTimeout(() => {
      return resolve(zoom9hexagons);
    }, 1000)
  );
});
