import { createStore } from 'effector';

const initialState = [];

export const $zoom10Hexagons = createStore(initialState);
$zoom10Hexagons.watch((state) => {
  console.log('$zoom10Hexagons', state);
});

export const $zoom10StartHexagons = createStore(initialState);

export const $zoom10MutatedHexagons = createStore([]);

export const $zoom10MutatedByMetric = createStore([]);

export const $isExtrusion = createStore(false);
$isExtrusion.watch((state) => console.log('$isExtrusion', state));

export const $activeExtrusionProp = createStore('index_main');
$activeExtrusionProp.watch((state) =>
  console.log('$activeExtrusionProp', state)
);
