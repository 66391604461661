import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import {
  $activeAreas,
  $dataPresets,
  $layerToUpdate,
  $presetsToColor,
  $showPresets,
  resetLayerToUpdateEv,
} from '../../models/dataPresetsModel/index.js';
import {
  $hexagonsHeight,
  $invertHeight,
  $layersOpacity,
  $mapLoaded,
} from '../../models/mapModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import {
  formDynamicBuckets,
  generateExtrusionHeight,
} from '../../utils/hexagon-utils.js';
import {
  $activeExtrusionProp,
  $isExtrusion,
} from '../../models/zoom10Model/index.js';
import { formPresetBuckets } from '../../utils/data-presets-utils.js';

export default function PresetLayersManager() {
  const dataPresets = useUnit($dataPresets);
  const mapLoaded = useUnit($mapLoaded);
  const showPreset = useUnit($showPresets);
  const layerToUpdate = useUnit($layerToUpdate);
  const resetLayerToUpdate = useUnit(resetLayerToUpdateEv);
  const layersOpacity = useUnit($layersOpacity);
  const isExtrusion = useUnit($isExtrusion);
  const activeExtrusionProp = useUnit($activeExtrusionProp);
  const hexagonsHeight = useUnit($hexagonsHeight);
  const activeAreas = useUnit($activeAreas);
  const presetsToColor = useUnit($presetsToColor);
  const invertHeight = useUnit($invertHeight);

  const createLayers = () => {
    let { layers, sources } = window.map.getStyle();
    layers = layers.filter((layer) => layer.id.includes('preset_layer-'));

    layers.forEach((layer) => {
      const presetId = +layer.id.split('-')[1];
      if (!dataPresets.find((preset) => preset.id === presetId)) {
        window.map.removeLayer(layer.id);
        window.map.removeLayer(`preset_outline-${presetId}`);
        window.map.removeSource(`preset_source-${presetId}`);
      }
    });

    dataPresets.forEach((preset) => {
      const presetHexagons = {
        type: 'FeatureCollection',
        features: preset.hexagons,
      };

      if (!window.map.getSource(`preset_source-${preset.id}`)) {
        window.map.addSource(`preset_source-${preset.id}`, {
          type: 'geojson',
          data: presetHexagons,
        });
      }

      if (!window.map.getLayer(`preset_layer-${preset.id}`)) {
        window.map.addLayer({
          id: `preset_layer-${preset.id}`,
          type: 'fill-extrusion',
          source: `preset_source-${preset.id}`,
          paint: {
            'fill-extrusion-color': preset.color,
            'fill-extrusion-height': generateExtrusionHeight(
              isExtrusion,
              activeExtrusionProp,
              hexagonsHeight,
              invertHeight
            ),
            'fill-extrusion-opacity': layersOpacity,
          },
        });
        window.map.addLayer({
          id: `preset_outline-${preset.id}`,
          type: 'line',
          source: `preset_source-${preset.id}`,
          paint: {
            'line-color': preset.color,
            'line-width': 1,
            'line-opacity': layersOpacity,
          },
        });

        changeLayerVisibility(`preset_outline-${preset.id}`, 'none');
      }

      changeLayerVisibility(
        `preset_layer-${preset.id}`,
        showPreset &&
          Object.keys(preset.areas).some((area) => activeAreas.includes(area))
          ? 'visible'
          : 'none'
      );
    });
  };

  const updateLayer = (layerId) => {
    const preset = dataPresets.find((preset) => preset.id === layerId);

    const sourceData = {
      type: 'FeatureCollection',
      features: preset.hexagons,
    };

    window.map.getSource(`preset_source-${layerId}`).setData(sourceData);

    resetLayerToUpdate();
  };

  const updateSource = (sourceId, hexagons) => {
    window.map.getSource(sourceId).setData({
      type: 'FeatureCollection',
      features: hexagons,
    });
  };

  useEffect(() => {
    if (mapLoaded && dataPresets.length > 0) {
      setTimeout(() => {
        createLayers();
      }, 1000);
    }
  }, [dataPresets.length, mapLoaded]);

  // useEffect(() => {
  //   if (
  //     mapLoaded &&
  //     dataPresets.length > 0 &&
  //     dataPresets.every((preset) =>
  //       window.map.getLayer(`preset_layer-${preset.id}`)
  //     )
  //   ) {
  //     if (showPreset) {
  //       dataPresets.forEach((preset) => {
  //         changeLayerVisibility(`preset_layer-${preset.id}`, 'visible');
  //         changeLayerVisibility(
  //           `preset_outline-${preset.id}`,
  //           isExtrusion ? 'none' : 'visible'
  //         );
  //       });
  //     } else {
  //       dataPresets.forEach((preset) => {
  //         changeLayerVisibility(`preset_layer-${preset.id}`, 'none');
  //         changeLayerVisibility(`preset_outline-${preset.id}`, 'none');
  //       });
  //     }
  //   }
  // }, [showPreset, mapLoaded, isExtrusion]);

  useEffect(() => {
    if (
      mapLoaded &&
      dataPresets.length > 0 &&
      dataPresets.every((preset) =>
        window.map.getLayer(`preset_layer-${preset.id}`)
      )
    ) {
      dataPresets.forEach((preset) => {
        if (!isExtrusion) {
          window.map.setPaintProperty(
            `preset_layer-${preset.id}`,
            'fill-extrusion-height',
            generateExtrusionHeight(isExtrusion, '', 0, invertHeight)
          );
        } else {
          window.map.setPaintProperty(
            `preset_layer-${preset.id}`,
            'fill-extrusion-height',
            generateExtrusionHeight(
              isExtrusion,
              'index_main',
              hexagonsHeight,
              invertHeight
            )
          );
        }
      });
    }
  }, [isExtrusion, hexagonsHeight, invertHeight]);

  useEffect(() => {
    if (layerToUpdate !== '') {
      updateLayer(layerToUpdate);
    }
  }, [layerToUpdate]);

  useEffect(() => {
    if (
      mapLoaded &&
      dataPresets.length > 0 &&
      dataPresets.every((preset) =>
        window.map.getLayer(`preset_layer-${preset.id}`)
      )
    ) {
      dataPresets.forEach((preset) => {
        if (!showPreset || activeAreas.length === 0) {
          changeLayerVisibility(`preset_layer-${preset.id}`, 'none');
          changeLayerVisibility(`preset_outline-${preset.id}`, 'none');
        } else if (activeAreas.length > 0) {
          const areas = Object.keys(preset.areas);
          if (areas.some((area) => activeAreas.includes(area))) {
            changeLayerVisibility(`preset_layer-${preset.id}`, 'visible');
            updateSource(
              `preset_source-${preset.id}`,
              preset.hexagons.filter((item) =>
                activeAreas.includes(item.properties.area)
              )
            );
            changeLayerVisibility(
              `preset_outline-${preset.id}`,
              isExtrusion ? 'none' : 'visible'
            );
          } else {
            changeLayerVisibility(`preset_layer-${preset.id}`, 'none');
            changeLayerVisibility(`preset_outline-${preset.id}`, 'none');
          }
        }
      });
    }
  }, [activeAreas, dataPresets, showPreset, isExtrusion]);

  useEffect(() => {
    if (
      mapLoaded &&
      dataPresets.length > 0 &&
      dataPresets.every((preset) =>
        window.map.getLayer(`preset_layer-${preset.id}`)
      )
    ) {
      if (presetsToColor.length > 0) {
        dataPresets.forEach((preset) => {
          const areasToColor = activeAreas.filter((item) => preset.areas[item]);
          if (presetsToColor.includes(preset.id)) {
            window.map.setPaintProperty(
              `preset_layer-${preset.id}`,
              'fill-extrusion-color',
              formPresetBuckets(
                preset.gradient.buckets,
                preset.gradient.colors,
                areasToColor,
                preset.color
              )
            );
            window.map.setPaintProperty(
              `preset_outline-${preset.id}`,
              'line-color',
              formPresetBuckets(
                preset.gradient.buckets,
                preset.gradient.colors,
                areasToColor,
                preset.color
              )
            );
          } else {
            window.map.setPaintProperty(
              `preset_layer-${preset.id}`,
              'fill-extrusion-color',
              preset.color
            );
            window.map.setPaintProperty(
              `preset_outline-${preset.id}`,
              'line-color',
              preset.color
            );
          }
        });
      } else {
        dataPresets.forEach((preset) => {
          window.map.setPaintProperty(
            `preset_layer-${preset.id}`,
            'fill-extrusion-color',
            preset.color
          );
          window.map.setPaintProperty(
            `preset_outline-${preset.id}`,
            'line-color',
            preset.color
          );
        });
      }
    }
  }, [presetsToColor]);

  useEffect(() => {
    if (
      mapLoaded &&
      dataPresets.length > 0 &&
      dataPresets.every((preset) =>
        window.map.getLayer(`preset_layer-${preset.id}`)
      )
    ) {
      dataPresets.forEach((preset) => {
        window.map.setPaintProperty(
          `preset_layer-${preset.id}`,
          'fill-extrusion-opacity',
          layersOpacity
        );
        window.map.setPaintProperty(
          `preset_outline-${preset.id}`,
          'line-opacity',
          layersOpacity
        );
      });
    }
  }, [mapLoaded, layersOpacity]);

  useEffect(() => {
    if (
      mapLoaded &&
      dataPresets.length > 0 &&
      dataPresets.every((preset) =>
        window.map.getLayer(`preset_layer-${preset.id}`)
      )
    ) {
      dataPresets.forEach((preset) => {
        window.map.setPaintProperty(
          `preset_layer-${preset.id}`,
          'fill-extrusion-opacity',
          layersOpacity
        );
        window.map.setPaintProperty(
          `preset_outline-${preset.id}`,
          'line-opacity',
          layersOpacity
        );
      });
    }
  }, [mapLoaded, layersOpacity]);

  return null;
}
