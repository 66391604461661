import { Segmented } from 'antd';
import parse from 'html-react-parser';
import { Column } from '@ant-design/charts';
import { useUnit } from 'effector-react';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import style from './SideModal.module.css';
import index_names_dymanics from '../../dictionaries/index_names_dymanics.json';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { $chosenMetric } from '../../models/indexCardsModel/index.js';

const columnData = [
  {
    date: '01.02.2023',
    index: 0.2,
  },
  {
    date: '01.03.2023',
    index: 0.5,
  },
  {
    date: '01.04.2023',
    index: 0.5,
  },
  {
    date: '01.05.2023',
    index: 0.6,
  },
  {
    date: '01.06.2023',
    index: 0.21,
  },
  {
    date: '01.07.2023',
    index: 0.8,
  },
  {
    date: '01.08.2023',
    index: 0.21,
  },
  {
    date: '01.09.2023',
    index: 0.6,
  },
];

export default function DescriptionContent(props) {
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const chosenMetric = useUnit($chosenMetric);
  const { activeTab, setActiveTab } = props;

  const changeTab = (value) => {
    setActiveTab(value);
  };

  const content = {
    0: (
      <div
        className={style.side_content}
        style={{ color: darkMode ? 'white' : 'black' }}
      >
        {parse(
          index_names_dymanics[chosenMetric][`description_${language}`] || ''
        )}
        {/* Индекс Херфиндаля–Хиршмана (Herfindahl–Hirschman Index, HHI) — */}
        {/* показатель, оценивающий уровень концентрации рынка. Индекс широко */}
        {/* применяют в антимонопольной деятельности для оценки слияния компаний и */}
        {/* их дальнейшего влияния на рынке. Размер выборки может ограничиваться */}
        {/* 50-ю крупными фирмами отрасли для получения конкретных результатов. */}
        {/* Результат, полученный путем вычисления индекса, может показывать */}
        {/* снижение конкуренции и увеличение рыночной власти, либо наоборот. */}
        {/* Основное преимущество индекса — в придании большого веса более крупным */}
        {/* фирмам на рынке, простой расчет самого индекса, а также небольшое */}
        {/* количество легко доступных данных, необходимых для расчета. */}
      </div>
    ),
    1: (
      <div className={style.side_content}>
        {'Формула индекса Херфиндаля–Хиршмана\n' +
          '      ​HHI = s12 ​+ s22 ​​+ s32 ​+ … + sn2​​\n' +
          '      Где:\n' +
          '      S = рыночная доля фирмыn = количество фирм на рынке\n' +
          '      HHI < 0,01 (или 100, если проценты используются как целые числа) указывает на высококонкурентную отрасль.\n' +
          '      HHI < 0,15 (или 1500) указывает на неконцентрированную отрасль, рынок конкурентен.\n' +
          '      HHI от 0,15 до 0,25 (или от 1 500 до 2500) указывает на умеренную концентрацию. Слияния и поглощения возможны, но могут вызвать антиконкурентные проблемы.\n' +
          '      HHI > 0,25 (или 2500) указывает на высокую концентрацию. Слияния и поглощения будут считаться антиконкурентными, усиливающие сосредоточение рыночной власти. Рынок близок к монополии.'}
      </div>
    ),
    2: (
      <div className={style.side_content}>
        <div className={style.side_chart_title}>Динамика изменения</div>
        <Column xField="date" yField="index" data={columnData} />
      </div>
    ),
  };

  const segmentedOptions = [
    {
      label: ru_en_page_dictionary.side_description[language],
      value: 0,
    },
    {
      label: ru_en_page_dictionary.side_calculation[language],
      value: 1,
    },
    {
      label: ru_en_page_dictionary.side_hist_data[language],
      value: 2,
    },
  ];
  return (
    <>
      <Segmented
        style={{ background: 'transparent' }}
        disabled
        block
        onChange={changeTab}
        value={activeTab}
        options={segmentedOptions}
      />
      {content[activeTab]}
    </>
  );
}
