import { useUnit } from 'effector-react';
import style from './MapPresetItem.module.css';
import { PresetItemOld } from '../PresetsSideModal/ModeContentManager.jsx';
import {
  $dataPresets,
  $presetAreaToEdit,
  $showPresets,
  resetPresetAreaToEditEv,
} from '../../models/dataPresetsModel/index.js';
import { $language } from '../../models/authModel/index.js';

export default function MapPresetItem() {
  const dataPresets = useUnit($dataPresets);
  const presetAreaToEdit = useUnit($presetAreaToEdit);
  const language = useUnit($language);
  const resetPresetAreaToEdit = useUnit(resetPresetAreaToEditEv);
  const showPresets = useUnit($showPresets);

  const preset = dataPresets.find(
    (item) => item.name === presetAreaToEdit.preset
  );

  if (preset && !showPresets) {
    return (
      <div className={style.wrapper}>
        <PresetItemOld
          preset={preset}
          language={language}
          presetAreaToEdit={presetAreaToEdit}
          resetPreset={resetPresetAreaToEdit}
          mapItem
        />
      </div>
    );
  }
  return null;
}
