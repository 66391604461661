export const age_dictionary = {
  // 1: '<18',
  2: '18-29',
  3: '30-44',
  4: '45-60',
  5: '61+',
};

export const gender_dictionary = {
  1: {
    ru: 'Мужчина',
    en: 'Male',
  },
  2: {
    ru: 'Женщина',
    en: 'Female',
  },
};

export const income_dictionary = {
  1: '<100',
  2: '100-250',
  3: '250-500',
  4: '>500',
};
