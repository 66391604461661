export const formatNumber = (number) => {
  {
    if (isNaN(number)) return number;

    if (number < 10) {
      return number;
    }

    if (number < 9999) {
      return number.toFixed(0);
    }

    if (number < 1000000) {
      return `${(number / 1000).toFixed(0)}к`;
    }
    if (number < 999999999) {
      return `${(number / 1000000).toFixed(0)}млн`;
    }

    if (number >= 1000000000) {
      return `${(number / 1000000000).toFixed(0)}млрд`;
    }

    return '1T+';
  }
};
