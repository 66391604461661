import { Button, Card, Collapse, Divider, Spin, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import Icon, {
  BarChartOutlined,
  DoubleRightOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { $radarLoader } from '../../models/webSocketModel/index.js';
import style from './LeftBar.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import RadarChartNew from '../RadarChart/RadarChartNew.jsx';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { $indexChartData } from '../../models/indexCardsModel/index.js';
import BarChart from '../BarChart/BarChart.jsx';
import {
  $activeAreas,
  $showPresets,
} from '../../models/dataPresetsModel/index.js';
import ColumnChart from '../ColumnChart/ColumnChart.jsx';

function ColumnChartSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25024 9.84375C2.25024 9.378 2.62824 9 3.09399 9L4.78149 9C5.24724 9 5.62524 9.378 5.62524 9.84375L5.62524 14.9062C5.62524 15.372 5.24724 15.75 4.78149 15.75L3.09399 15.75C2.87022 15.75 2.65561 15.6611 2.49737 15.5029C2.33914 15.3446 2.25024 15.13 2.25024 14.9062L2.25024 9.84375Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7496 6.46875C12.7496 6.003 13.1276 5.625 13.5934 5.625L15.2809 5.625C15.7466 5.625 16.1246 6.003 16.1246 6.46875L16.1246 14.9062C16.1246 15.372 15.7466 15.75 15.2809 15.75L13.5934 15.75C13.3696 15.75 13.155 15.6611 12.9968 15.5029C12.8385 15.3446 12.7496 15.13 12.7496 14.9062L12.7496 6.46875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49988 3.09375C7.49988 2.628 7.87788 2.25 8.34363 2.25L10.0311 2.25C10.4969 2.25 10.8749 2.628 10.8749 3.09375L10.8749 14.9062C10.8749 15.372 10.4969 15.75 10.0311 15.75L8.34363 15.75C8.11985 15.75 7.90524 15.6611 7.74701 15.5029C7.58877 15.3446 7.49988 15.13 7.49988 14.9062L7.49988 3.09375Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function RadarChartSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.80005 7.28552L9.00005 1.7998M1.80005 7.28552L4.41823 16.1998M1.80005 7.28552L9.00005 9.68552M9.00005 1.7998L16.2 7.28552M9.00005 1.7998V9.68552M16.2 7.28552L13.5819 16.1998M16.2 7.28552L9.00005 9.68552M13.5819 16.1998H4.41823M13.5819 16.1998L9.00005 9.68552M4.41823 16.1998L9.00005 9.68552"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function BarChartSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.34375 2.0625C8.8095 2.0625 9.1875 2.4405 9.1875 2.90625L9.1875 4.59375C9.1875 5.0595 8.8095 5.4375 8.34375 5.4375L3.28125 5.4375C2.8155 5.4375 2.4375 5.0595 2.4375 4.59375L2.4375 2.90625C2.4375 2.68247 2.52639 2.46786 2.68463 2.30963C2.84286 2.15139 3.05747 2.0625 3.28125 2.0625L8.34375 2.0625Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7188 12.5625C12.1845 12.5625 12.5625 12.9405 12.5625 13.4063L12.5625 15.0938C12.5625 15.5595 12.1845 15.9375 11.7187 15.9375L3.28125 15.9375C2.8155 15.9375 2.4375 15.5595 2.4375 15.0937L2.4375 13.4062C2.4375 13.1825 2.52639 12.9679 2.68463 12.8096C2.84286 12.6514 3.05747 12.5625 3.28125 12.5625L11.7188 12.5625Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0938 7.3125C15.5595 7.3125 15.9375 7.6905 15.9375 8.15625L15.9375 9.84375C15.9375 10.3095 15.5595 10.6875 15.0937 10.6875L3.28125 10.6875C2.8155 10.6875 2.4375 10.3095 2.4375 9.84375L2.4375 8.15625C2.4375 7.93247 2.52639 7.71786 2.68463 7.55963C2.84286 7.40139 3.05747 7.3125 3.28125 7.3125L15.0938 7.3125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ColumnChartIcon() {
  return <Icon component={ColumnChartSvg} />;
}
export function RadarChartIcon() {
  return <Icon component={RadarChartSvg} />;
}
export function BarChartIcon() {
  return <Icon component={BarChartSvg} />;
}

export default function ResRadar(props) {
  const { onExpand, expanded } = props;
  const radarLoader = useUnit($radarLoader);
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const showPresets = useUnit($showPresets);
  const activeAreas = useUnit($activeAreas);

  const indexChartData = useUnit($indexChartData);
  const [index, setIndex] = useState(0);
  const [chartMode, setChartMode] = useState('bar');

  useEffect(() => {
    const filteredChartData = indexChartData.filter((item) => item.value !== 0);
    setIndex(
      filteredChartData.reduce((sum, item) => sum + +item.value, 0) /
        filteredChartData.length || 0
    );
  }, [indexChartData]);

  const collapseItems = [
    {
      key: 1,
      label: !showPresets ? (
        <div
          className={style.radar_title}
          style={{ color: darkMode ? 'white' : 'black' }}
        >
          {ru_en_page_dictionary.resilience_index[language]}: {index.toFixed(2)}{' '}
          / 100
          <Divider className={style.radar_title_divider} />
        </div>
      ) : activeAreas.length === 0 ? (
        <div className={style.choose_preset}>
          {ru_en_page_dictionary.choose_preset[language]}
        </div>
      ) : (
        <div style={{ height: '33px' }} />
      ),
      // children: <RadarChart />,
      style: {
        paddingTop: '5px',
      },
      children:
        showPresets && activeAreas.length === 0 ? null : (
          <div style={{ marginTop: '6px' }}>
            {/* <div className={style.chart_buttons_wrapper}> */}
            {/*  <Tooltip placement="top" title="Switch to bar"> */}
            {/*    <Button */}
            {/*      type={chartMode === 'bar' ? 'primary' : 'default'} */}
            {/*      className={style.chart_switch} */}
            {/*      onClick={() => setChartMode('bar')} */}
            {/*      icon={<BarChartIcon />} */}
            {/*    /> */}
            {/*  </Tooltip> */}
            {/*  <Tooltip placement="top" title="Switch to radar"> */}
            {/*    <Button */}
            {/*      type={chartMode === 'radar' ? 'primary' : 'default'} */}
            {/*      className={style.chart_switch} */}
            {/*      onClick={() => setChartMode('radar')} */}
            {/*      icon={<RadarChartIcon />} */}
            {/*    /> */}
            {/*  </Tooltip> */}
            {/*  <Tooltip placement="top" title="Switch to column"> */}
            {/*    <Button */}
            {/*      type={chartMode === 'column' ? 'primary' : 'default'} */}
            {/*      className={style.chart_switch} */}
            {/*      onClick={() => setChartMode('column')} */}
            {/*      icon={<ColumnChartIcon />} */}
            {/*    /> */}
            {/*  </Tooltip> */}
            {/* </div> */}
            {chartMode === 'radar' ? (
              <RadarChartNew />
            ) : chartMode === 'bar' ? (
              <BarChart />
            ) : (
              <ColumnChart />
            )}
          </div>
        ),
    },
  ];

  const setExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    if (isActive) {
      return <DoubleRightOutlined rotate={90} style={{ fontSize: '16px' }} />;
    }
    return <DoubleRightOutlined rotate={270} style={{ fontSize: '16px' }} />;
  };

  return (
    <Spin spinning={radarLoader} size="large">
      <Card bodyStyle={{ padding: '0px 20px', overflow: 'hidden' }}>
        <Collapse
          ghost
          defaultActiveKey={1}
          items={collapseItems}
          expandIconPosition="end"
          expandIcon={setExpandIcon}
          onChange={onExpand}
          activeKey={expanded}
        />
      </Card>
    </Spin>
  );
}
