import { useUnit } from 'effector-react';
import { useState } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  $chosenMetric,
  $modalOpen,
  changeModalOpenEv,
} from '../../models/indexCardsModel/index.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import index_names_dymanics from '../../dictionaries/index_names_dymanics.json';
import style from './SideModal.module.css';
import DescriptionContent from './DescriptionContent.jsx';

export default function TabletSideModal() {
  const open = useUnit($modalOpen);
  const changeModalOpen = useUnit(changeModalOpenEv);
  const chosenMetric = useUnit($chosenMetric);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);

  const [activeTab, setActiveTab] = useState(0);

  const onClose = () => {
    changeModalOpen(false);
    setActiveTab(0);
  };

  return (
    <div
      className={[style.table_modal_wrapper, open ? style.active : ''].join(
        ' '
      )}
      style={{
        background: darkMode ? '#1E2328' : 'white',
      }}
    >
      <div
        style={{
          color: darkMode ? 'white' : 'black',
          marginBottom: '10px',
        }}
      >
        {index_names_dymanics[chosenMetric][`name_${language}`]}
      </div>
      <DescriptionContent activeTab={activeTab} setActiveTab={setActiveTab} />
      <Button
        shape="circle"
        className={[style.close_button, style.close_button_tablet].join(' ')}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </div>
  );
}
