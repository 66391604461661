import { Button, Checkbox, Drawer, Form, Input, Segmented } from 'antd';
import { useUnit } from 'effector-react';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import style from './ReportModal.module.css';
import {
  $modalOpen,
  closeModalEv,
  downloadReportFx,
  sendGenerateReportFx,
} from '../../models/reportModel/index.js';
import index_names_dymanics from '../../dictionaries/index_names_dymanics.json';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { $zoom10Hexagons } from '../../models/zoom10Model/index.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import ReportForm from './ReportForm.jsx';
import ReportArchive from './ReportArchive.jsx';

export default function ReportModal() {
  const open = useUnit($modalOpen);
  const closeModal = useUnit(closeModalEv);
  const language = useUnit($language);
  const sendGenerateReport = useUnit(sendGenerateReportFx);
  const downloadReport = useUnit(downloadReportFx);
  const activeFilters = useUnit($activeFilters);
  const zoom10Hexagons = useUnit($zoom10Hexagons);
  const userInfo = useUnit($userInfo);
  const isDarkMode = useUnit($isDarkTheme);

  const [activeTab, setActiveTab] = useState(0);

  const onClose = () => {
    closeModal();
    setActiveTab(0);
  };

  const segmentedOptions = [
    {
      label: ru_en_page_dictionary.report_new[language],
      value: 0,
    },
    {
      label: ru_en_page_dictionary.report_archive[language],
      value: 1,
    },
  ];

  return (
    <Drawer
      title={ru_en_page_dictionary.report_title[language]}
      open={open}
      onClose={onClose}
      width={600}
      closable={false}
      classNames={{
        header: style.modal_header,
        body: style.modal_body,
      }}
    >
      {/* <Segmented */}
      {/*  options={segmentedOptions} */}
      {/*  onChange={setActiveTab} */}
      {/*  value={activeTab} */}
      {/*  style={{background: 'transparent'}} */}
      {/* /> */}
      {/* {content[activeTab]} */}
      <div className={style.side_content}>
        <div style={{ color: isDarkMode ? 'white' : 'black' }}>
          {ru_en_page_dictionary.report_new[language]}
        </div>
        <ReportForm />
        <div style={{ color: isDarkMode ? 'white' : 'black' }}>
          {ru_en_page_dictionary.report_archive[language]}
        </div>
        <div className={style.archive_scroll_wrapper}>
          <ReportArchive />
        </div>
      </div>

      <Button
        shape="circle"
        className={style.close_button}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </Drawer>
  );
}
