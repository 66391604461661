import { sample } from 'effector';
import notification from 'antd/es/notification';
import {
  changeDarkThemeEv,
  changeLanguageEv,
  delayedShowTabletBarEv,
  loginEv,
  logoutEv,
  registerEv,
  sendTimersEv,
  setAuthStatusEv,
  setInitDarkStyleEv,
  setUserInfoEv,
  togglePlatformEv,
  toggleTabletBarHiddenEv,
} from './events.js';
import {
  blockUserFx,
  confirmUserFx,
  delayShowLeftBarFx,
  getUserListFx,
  loginFx,
  logoutFx,
  makeAdminFx,
  registerFx,
  sendTimersFx,
  switchUseBackendFx,
  togglePlatformFx,
  unblockUserFx,
} from './effects.js';
import {
  $authStatus,
  $isDarkTheme,
  $language,
  $tabletBarHidden,
  $userInfo,
  $userList,
} from './stores.js';
import { cookies } from '../../api/axiosinstance.js';
import { deleteReportFx, sendGenerateReportFx } from '../reportModel/index.js';
import { setMapLoadedEv } from '../mapModel/index.js';
import { changeDrawStyles } from '../../utils/draw_polygon_utils.js';
import { delayFx } from '../webSocketModel/effects.js';

$language.on(changeLanguageEv, (state, payload) => payload.key);

$tabletBarHidden.on(toggleTabletBarHiddenEv, (state, payload) => !state);

$authStatus
  .on(loginFx.doneData, (state, payload) => {
    console.log('TEST:: I SET COOKIES', payload.object.result.token);
    cookies.set('access_token', payload.object.result.token);
    return true;
  })
  .on(setAuthStatusEv, (state, payload) => payload);

$userInfo.on(setUserInfoEv, (state, payload) => {
  if (payload.rbp === true) {
    window.location.replace('/rbp/root');
  }
  return payload;
});

$isDarkTheme.on(setUserInfoEv, (state, payload) => payload.dark_theme);
$isDarkTheme.on(changeDarkThemeEv, (state, payload) => !state);

$userList.on(getUserListFx.doneData, (state, payload) =>
  payload.object.result.map((item) => ({
    ...item,
    key: `table-item-${item.id}`,
  }))
);

sample({
  clock: loginEv,
  target: loginFx,
});

sample({
  clock: registerEv,
  target: registerFx,
});

registerFx.doneData.watch((data) => {
  notification.success({
    message: 'Регистрация прошла успешно',
    description: 'Ожидайте подтверждения',
    placement: 'topRight',
    className: 'notification',
    duration: 5,
  });

  setTimeout(() => {
    window.location.replace('/login');
  }, 5000);
});

sample({
  clock: logoutEv,
  target: logoutFx,
});

logoutFx.doneData.watch(() => {
  cookies.remove('access_token');
  window.location.replace('/login');
});

sample({
  source: $userList,
  clock: [
    confirmUserFx.doneData,
    blockUserFx.doneData,
    unblockUserFx.doneData,
    makeAdminFx.doneData,
    switchUseBackendFx.doneData,
  ],
  filter: (source, clock) => clock.data.success,
  fn: (source, clock) => {
    const user = source.filter((item) => item.id === clock.id)[0];
    const userIndex = source.findIndex((item) => item.id === clock.id);

    return [
      ...source.slice(0, userIndex),
      {
        ...user,
        [clock.field]: clock.value,
      },
      ...source.slice(userIndex + 1),
    ];
  },
  target: $userList,
});

sample({
  source: $userInfo,
  clock: switchUseBackendFx.doneData,
  filter: (source, clock) => {
    return clock.data.success && source.id === clock.id;
  },
  fn: (source, clock) => {
    return {
      ...source,
      use_backend: clock.value,
    };
  },
  target: $userInfo,
});

sample({
  source: $userInfo,
  clock: sendTimersEv,
  fn: (source, clock) => {
    const payload = {
      id: source.id,
      timers: {
        download: Math.round(window.download_end - window.download_start),
        mutate: Math.round(window.mutate_end - window.mutate_start),
      },
    };

    delete window.download_start;
    delete window.download_end;
    delete window.mutate_start;
    delete window.mutate_end;

    return payload;
  },
  target: sendTimersFx,
});

sample({
  source: $userInfo,
  clock: sendGenerateReportFx.doneData,
  fn: (source, clock) => {
    return {
      ...source,
      reports: [clock[1], ...source.reports],
    };
  },
  target: $userInfo,
});

sample({
  clock: setUserInfoEv,
  filter: (clock) => clock.dark_theme,
  fn: () => false,
  target: [setMapLoadedEv, setInitDarkStyleEv],
});

sample({
  clock: setInitDarkStyleEv,
  fn: () => {
    if (window.map) {
      window.map.setStyle('mapbox://styles/mapbox/dark-v11');
    }
  },
});

sample({
  clock: $isDarkTheme.updates,
  filter: () => !!window.draw,
  fn: (clock) => {
    setTimeout(() => {
      changeDrawStyles(clock);
    }, 1000);
  },
});

sample({
  clock: delayedShowTabletBarEv,
  target: delayShowLeftBarFx,
});

sample({
  clock: delayShowLeftBarFx.doneData,
  target: $tabletBarHidden,
});

sample({
  source: $userInfo,
  clock: deleteReportFx.doneData,
  fn: (source, clock) => {
    return {
      ...source,
      reports: source.reports.filter((item) => item.id !== clock),
    };
  },
  target: $userInfo,
});

sample({
  clock: togglePlatformEv,
  target: togglePlatformFx,
});

sample({
  source: $userList,
  clock: togglePlatformFx.doneData,
  fn: (source, clock) => {
    const updatedUser = clock.object.result;
    return source.map((item) => {
      if (item.id === updatedUser.id) {
        return {
          ...updatedUser,
          key: item.key,
          timers: Array.isArray(updatedUser.timers)
            ? updatedUser.timers.length
            : updatedUser.timers,
        };
      }
      return item;
    });
  },
  target: $userList,
});
