export const all_metrics = [
  'index_101',
  'index_102',
  'index_103',
  'index_104',
  'index_201',
  'index_202',
  'index_203',
  'index_204',
  'index_205',
  'index_206',
  'index_207',
  'index_301',
  'index_302',
  'index_303',
  'index_304',
  'index_305',
  'index_306',
  'index_307',
  'index_308',
  'index_309',
  'index_310',
];
