import { createEvent } from 'effector';

export const putZoom8HexagonsEv = createEvent();

export const updateZoom8HexagonsBy10Ev = createEvent();

export const resetZoom8ByMetricsEv = createEvent();

export const putMutated8HexagonsEv = createEvent();

export const putFilteredZoom8HexagonsEv = createEvent();

export const filterZoom8GradientEv = createEvent();
