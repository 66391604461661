import { Switch, TreeSelect } from 'antd';
import { useUnit } from 'effector-react';
import style from './MapDimensionControl.module.css';
import {
  $activeExtrusionProp,
  $isExtrusion,
  changeActiveExtPropEv,
  toggleExtrusionEv,
} from '../../models/zoom10Model/index.js';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import { all_metrics } from '../../dictionaries/metrics.js';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

const makeTreeSelectOptions = (language) => {
  const temp = [
    {
      value: 'index_main',
      title: ru_en_page_dictionary.resilience_index[language],
    },
  ];
  all_metrics.forEach((metric) => {
    const sub_index = metric.split('index_')[1][0];
    const index = metric.split('index_')[1];
    if (temp.findIndex((item) => item.value === `index_${sub_index}`) === -1) {
      temp.push({
        value: `index_${sub_index}`,
        title: index_names_dynamics[sub_index][`name_${language}`],
        children: [
          {
            value: metric,
            title: index_names_dynamics[index][`name_${language}`],
          },
        ],
      });
    } else {
      temp
        .find((item) => item.value === `index_${sub_index}`)
        .children.push({
          value: metric,
          title: index_names_dynamics[index][`name_${language}`],
        });
    }
  });

  return temp;
};

export default function MapDimensionControl() {
  const isExtrusion = useUnit($isExtrusion);
  const toggleExtrusion = useUnit(toggleExtrusionEv);
  const language = useUnit($language);
  const changeActiveExtProp = useUnit(changeActiveExtPropEv);
  const activeExtrusionProp = useUnit($activeExtrusionProp);

  return (
    <div className={style.wrapper}>
      <Switch
        checkedChildren="3D"
        unCheckedChildren="2D"
        checked={isExtrusion}
        onChange={toggleExtrusion}
      />
      {isExtrusion ? (
        <TreeSelect
          className={style.tree_select}
          treeData={makeTreeSelectOptions(language)}
          onChange={changeActiveExtProp}
          value={activeExtrusionProp}
        />
      ) : (
        ''
      )}
    </div>
  );
}
