import { createStore } from 'effector';

const initialState = [];

export const $zoom9Hexagons = createStore(initialState);
$zoom9Hexagons.watch((state) => console.log('$zoom9Hexagons', state));

export const $zoom9StartHexagons = createStore(initialState);

export const $zoom9MutatedHexagons = createStore([]);

export const $zoom9MutatedByMetric = createStore([]);

export const $zoom9StartPeopleData = createStore([]);
