import { Card, Col, Row, Space } from 'antd';
import { useUnit } from 'effector-react';
import style from './MainPageRestyle.module.css';
import LeftBar from '../../components/LeftBar/LeftBar.jsx';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import TopBar from '../../components/TopBar/TopBar.jsx';
import SideModal from '../../components/SideModal/SideModal.jsx';
import {
  $isTablet,
  $leftBarSwitched,
  $tabletBarHidden,
} from '../../models/authModel/index.js';
import TabletLeftBar from '../../components/TabletLeftBar/TabletLeftBar.jsx';
import PresetsSideModal from '../../components/PresetsSideModal/PresetsSideModal.jsx';
import { $mapLoaded } from '../../models/mapModel/index.js';
import LeftBarSwitcher from '../../components/LeftBarSwitcher/LeftBarSwitcher.jsx';

export default function MainPageRestyle() {
  const isTablet = useUnit($isTablet);
  const tabletBarHidden = useUnit($tabletBarHidden);
  const leftBarSwitched = useUnit($leftBarSwitched);
  const mapLoaded = useUnit($mapLoaded);
  if (isTablet) {
    return (
      <Row gutter={[5, 0]} style={{ padding: '5px 0px', height: '100%' }}>
        <Col
          style={{
            width: tabletBarHidden ? '86px' : '385px',
            maxHeight: '100%',
            transition: '0.5s all',
          }}
        >
          <TabletLeftBar />
        </Col>
        <Col
          style={{
            width: tabletBarHidden ? 'calc(100% - 86px)' : 'calc(100% - 385px)',
            transition: '0.5s all',
          }}
        >
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row
      gutter={[20, 0]}
      style={{ padding: '20px 0px', height: '100%', position: 'relative' }}
    >
      <Col
        className={[
          style.left_col,
          !mapLoaded ? style.hide : leftBarSwitched ? style.hide : style.show,
        ].join(' ')}
      >
        <LeftBar />
      </Col>
      <Col
        style={{
          marginLeft: !mapLoaded ? '0' : leftBarSwitched ? '0' : '400px',
          width: !mapLoaded
            ? '100%'
            : leftBarSwitched
              ? '100%'
              : 'calc(100% - 400px)',
          transition: '0.5s all',
        }}
      >
        <div className={style.top_bar_wrapper}>
          <TopBar />
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </div>
        {/* <Space id="top_bar" className={style.top_bar} direction="vertical"> */}
        {/* </Space> */}
      </Col>
      <SideModal />
      <PresetsSideModal />
    </Row>
  );
}
