import React from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import screenshot from '../../../assets/images/sections/promo/screenshot.png';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';

function Promo(props) {
  const language = useUnit($language);

  return (
    <div className="landing_container">
      <div className={style.promo_wrapper}>
        <div className={style.promo_text_wrapper}>
          <h1 className={style.promo_title}>
            {translation[language].promo_title}
          </h1>
          <h3 className={style.promo_subtitle}>
            {translation[language].promo_subtitle}
          </h3>
          <Button
            type="primary"
            className={style.promo_button}
            icon={<RightOutlined />}
            iconPosition="end"
          >
            {translation[language].request_demo}
          </Button>
        </div>
        <img src={screenshot} />
      </div>
    </div>
  );
}

export default Promo;
