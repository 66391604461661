import {
  DeleteOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { Popconfirm } from 'antd';
import style from './ReportModal.module.css';
import {
  deleteReportFx,
  downloadReportFx,
} from '../../models/reportModel/index.js';
import { $language, $userInfo } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

export default function ReportArchive() {
  const downloadReport = useUnit(downloadReportFx);
  const deleteReport = useUnit(deleteReportFx);
  const language = useUnit($language);
  const userInfo = useUnit($userInfo);

  return (
    <div className={style.archive_wrapper}>
      {(userInfo.reports || []).map((item, index) => {
        return (
          <div className={style.archive_item} key={index}>
            <div>{item.filename}</div>
            <div className={style.buttons_wrapper}>
              <DownloadOutlined
                onClick={() =>
                  downloadReport({ id: item.id, name: item.filename })
                }
              />
              <Popconfirm
                title={ru_en_page_dictionary.r_u_sure[language]}
                onConfirm={() => deleteReport({ id: item.id })}
                okText={ru_en_page_dictionary.yes[language]}
                cancelText={ru_en_page_dictionary.no[language]}
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }
              >
                <DeleteOutlined />
              </Popconfirm>{' '}
            </div>
          </div>
        );
      })}
    </div>
  );
}
