import { useUnit } from 'effector-react';
import SelectPreset from '../SelectPreset/SelectPreset.jsx';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { months_dictionary } from '../../dictionaries/months_dictionary.js';

const options = [
  {
    label: '2023',
    value: 11,
  },
  // {
  //   label: 'test 2',
  //   value: 'test2',
  // },
  // {
  //   label: 'test 3',
  //   value: 'test3',
  // },
];

const availableMonths = [11];

function DatesSelector() {
  const language = useUnit($language);
  return (
    <SelectPreset
      options={availableMonths.map((item) => {
        return {
          label: `2023`,
          value: item,
        };
      })}
      field="quarter"
      placeholder={ru_en_page_dictionary.quarter_placeholder[language]}
    />
  );
}

export default DatesSelector;
