import { Button } from 'antd';
import { useUnit } from 'effector-react';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import style from './LeftBarSwitcher.module.css';
import {
  $leftBarSwitched,
  toggleLeftBarSwitchedEv,
} from '../../models/authModel/index.js';

export default function LeftBarSwitcher() {
  const leftBarSwitched = useUnit($leftBarSwitched);
  const toggleLeftBarSwitched = useUnit(toggleLeftBarSwitchedEv);

  return (
    <Button
      className={style.switcher_button}
      icon={leftBarSwitched ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
      onClick={toggleLeftBarSwitched}
    />
  );
}
