import { createEffect } from 'effector';
import axios from 'axios';
import { api, cookies } from '../../api/axiosinstance.js';

export const sendGenerateReportFx = createEffect().use(async (payload) => {
  return (await api(true).post('reports/', payload)).data;
});

export const downloadReportFx = createEffect().use(({ id, name }) => {
  let url;

  api(true, 'blob')
    .get(`reports/rcp/${id}`)
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${name}.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
});

export const deleteReportFx = createEffect().use(async ({ id }) => {
  const response = (await api(true).delete(`reports/rcp/${id}`)).data;
  if (response) return id;
});
