import { createStore } from 'effector';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import { small_icons_dict } from '../../dictionaries/icons_dict.jsx';

const initialState = [
  {
    index_name: 'index_1',
    title: 'Index 1',
    index: 0.69,
    difference: 0.5,
    bgColor: 'red',
    id: '1',
    children: [
      {
        title: 'SubIndex 1 1',
        icon: () => null,
        index_name: 'index_101',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-1',
      },
      {
        title: 'SubIndex 1 2',
        icon: () => null,
        index_name: 'index_102',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-2',
      },
      {
        title: 'SubIndex 1 3',
        icon: () => null,
        index_name: 'index_103',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-3',
      },
    ],
  },
  {
    index_name: 'index_2',
    title: 'Index 2',
    index: 0.6,
    difference: -0.5,
    bgColor: 'blue',
    id: '2',
    children: [
      {
        title: 'SubIndex 2 1',
        icon: () => null,
        index_name: 'index_201',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-1',
      },
      {
        title: 'SubIndex 2 2',
        icon: () => null,
        index_name: 'index_202',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-2',
      },
      {
        title: 'SubIndex 2 3',
        icon: () => null,
        index_name: 'index_203',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-3',
      },
    ],
  },
  {
    index_name: 'index_3',
    title: 'Index 3',
    index: 0.58,
    difference: 0.5,
    bgColor: 'green',
    id: '3',
    children: [
      {
        title: 'SubIndex 3 1',
        icon: () => null,
        index_name: 'index_301',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-1',
      },
      {
        title: 'SubIndex 3 2',
        icon: () => null,
        index_name: 'index_302',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-2',
      },
      {
        title: 'SubIndex 3 3',
        icon: () => null,
        index_name: 'index_303',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-3',
      },
    ],
  },
  {
    index_name: 'index_4',
    title: 'Index 4',
    index: 0.69,
    difference: 0.5,
    bgColor: 'yellow',
    id: '4',
    children: [
      {
        title: 'SubIndex 4 1',
        icon: () => null,
        index_name: 'index_401',
        index: 0.69,
        difference: 0.5,
        bgColor: 'yellow',
        id: '4-1',
      },
      {
        title: 'SubIndex 4 2',
        icon: () => null,
        index_name: 'index_402',
        index: 0.69,
        difference: 0.5,
        bgColor: 'yellow',
        id: '4-2',
      },
      {
        title: 'SubIndex 4 3',
        icon: () => null,
        index_name: 'index_403',
        index: 0.69,
        difference: 0.5,
        bgColor: 'yellow',
        id: '4-3',
      },
    ],
  },
  {
    index_name: 'index_5',
    title: 'Index 5',
    index: 0.6,
    difference: -0.5,
    bgColor: 'magenta',
    id: '5',
    children: [
      {
        title: 'SubIndex 5 1',
        icon: () => null,
        index_name: 'index_501',
        index: 0.6,
        difference: -0.5,
        bgColor: 'magenta',
        id: '5-1',
      },
      {
        title: 'SubIndex 5 2',
        icon: () => null,
        index_name: 'index_502',
        index: 0.6,
        difference: -0.5,
        bgColor: 'magenta',
        id: '5-2',
      },
      {
        title: 'SubIndex 5 3',
        icon: () => null,
        index_name: 'index_503',
        index: 0.6,
        difference: -0.5,
        bgColor: 'magenta',
        id: '5-3',
      },
    ],
  },
];

const inititalHexData = {
  zoom_8: {
    id: '',
    data: [],
  },
  zoom_9: {
    id: '',
    data: [],
  },
  zoom_10: {
    id: '',
    data: [],
  },
};

const initialChartData = [
  {
    title: 'index 1',
    index_name: 'index_1',
    value: 0,
  },
  {
    title: 'index 2',
    index_name: 'index_2',
    value: 0,
  },
  {
    title: 'index 3',
    index_name: 'index_3',
    value: 0,
  },
  {
    title: 'index 4',
    index_name: 'index_4',
    value: 0,
  },
  {
    title: 'index 5',
    index_name: 'index_5',
    value: 0,
  },
];

let treeData = [
  {
    title: 'index 1',
    key: 'index_1',
    icon: small_icons_dict[1],
    children: [
      {
        title: 'index 101',
        key: 'index_101',
      },
      {
        title: 'index 102',
        key: 'index_102',
      },
      {
        title: 'index 103',
        key: 'index_103',
      },
      {
        title: 'index 104',
        key: 'index_104',
      },
      {
        title: 'index 105',
        key: 'index_105',
      },
      {
        title: 'index 106',
        key: 'index_106',
      },
      {
        title: 'index 107',
        key: 'index_107',
      },
      {
        title: 'index 108',
        key: 'index_108',
      },
      {
        title: 'index 109',
        key: 'index_109',
      },
      {
        title: 'index 110',
        key: 'index_110',
      },
      {
        title: 'index 111',
        key: 'index_111',
      },
      {
        title: 'index 112',
        key: 'index_112',
      },
      {
        title: 'index 113',
        key: 'index_113',
      },
      {
        title: 'index 114',
        key: 'index_114',
      },
      {
        title: 'index 115',
        key: 'index_115',
      },
      {
        title: 'index 116',
        key: 'index_116',
      },
    ],
  },
  {
    title: 'index 2',
    key: 'index_2',
    icon: small_icons_dict[2],
    children: [
      {
        title: 'index 201',
        key: 'index_201',
      },
      {
        title: 'index 202',
        key: 'index_202',
      },
      {
        title: 'index 203',
        key: 'index_203',
      },
      {
        title: 'index 204',
        key: 'index_204',
      },
      // {
      //   title: 'index 205',
      //   key: 'index_205',
      // },
      {
        title: 'index 206',
        key: 'index_206',
      },
      {
        title: 'index 207',
        key: 'index_207',
      },
      {
        title: 'index 208',
        key: 'index_208',
      },
      {
        title: 'index 209',
        key: 'index_209',
      },
      {
        title: 'index 210',
        key: 'index_210',
      },
      {
        title: 'index 211',
        key: 'index_211',
      },
      {
        title: 'index 212',
        key: 'index_212',
      },
      {
        title: 'index 213',
        key: 'index_213',
      },
      {
        title: 'index 214',
        key: 'index_214',
      },
      {
        title: 'index 215',
        key: 'index_215',
      },
      {
        title: 'index 216',
        key: 'index_216',
      },
      {
        title: 'index 217',
        key: 'index_217',
      },
      {
        title: 'index 218',
        key: 'index_218',
      },
      {
        title: 'index 219',
        key: 'index_219',
      },
      {
        title: 'index 220',
        key: 'index_220',
      },
    ],
  },
  {
    title: 'index 3',
    key: 'index_3',
    icon: small_icons_dict[3],
    children: [
      {
        title: 'index 301',
        key: 'index_301',
      },
      // {
      //   title: 'index 302',
      //   key: 'index_302',
      // },
      // {
      //   title: 'index 303',
      //   key: 'index_303',
      // },
      {
        title: 'index 304',
        key: 'index_304',
      },
      {
        title: 'index 305',
        key: 'index_305',
      },
      {
        title: 'index 306',
        key: 'index_306',
      },
      {
        title: 'index 307',
        key: 'index_307',
      },
      {
        title: 'index 308',
        key: 'index_308',
      },
      {
        title: 'index 309',
        key: 'index_309',
      },
      {
        title: 'index 310',
        key: 'index_310',
      },
      {
        title: 'index 311',
        key: 'index_311',
      },
      {
        title: 'index 312',
        key: 'index_312',
      },
    ],
  },
  {
    title: 'index 4',
    key: 'index_4',
    icon: small_icons_dict[4],
    children: [
      {
        title: 'index 401',
        key: 'index_401',
      },
      {
        title: 'index 402',
        key: 'index_402',
      },
      {
        title: 'index 403',
        key: 'index_403',
      },
      // {
      //   title: 'index 404',
      //   key: 'index_404',
      // },
      {
        title: 'index 405',
        key: 'index_405',
      },
      {
        title: 'index 406',
        key: 'index_406',
      },
      {
        title: 'index 407',
        key: 'index_407',
      },
      {
        title: 'index 408',
        key: 'index_408',
      },
      {
        title: 'index 409',
        key: 'index_409',
      },
      // {
      //   title: 'index 410',
      //   key: 'index_410',
      // },
      {
        title: 'index 411',
        key: 'index_411',
      },
      {
        title: 'index 412',
        key: 'index_412',
      },
    ],
  },
  {
    title: 'index 5',
    key: 'index_5',
    icon: small_icons_dict[5],
    children: [
      {
        title: 'index 501',
        key: 'index_501',
      },
      {
        title: 'index 502',
        key: 'index_502',
      },
      {
        title: 'index 503',
        key: 'index_503',
      },
      {
        title: 'index 504',
        key: 'index_504',
      },
      {
        title: 'index 505',
        key: 'index_505',
      },
      {
        title: 'index 506',
        key: 'index_506',
      },
      {
        title: 'index 507',
        key: 'index_507',
      },
      {
        title: 'index 508',
        key: 'index_508',
      },
      {
        title: 'index 509',
        key: 'index_509',
      },
      {
        title: 'index 510',
        key: 'index_510',
      },
      {
        title: 'index 511',
        key: 'index_511',
      },
      {
        title: 'index 512',
        key: 'index_512',
      },
      {
        title: 'index 513',
        key: 'index_513',
      },
      {
        title: 'index 514',
        key: 'index_514',
      },
      {
        title: 'index 515',
        key: 'index_515',
      },
      {
        title: 'index 516',
        key: 'index_516',
      },
    ],
  },
];

treeData = treeData.map((item) => {
  const index = item.key.split('index_')[1];
  return {
    ...item,
    title: index_names_dynamics[index].name_ru,
    children: item.children.map((child) => {
      const child_index = child.key.split('index_')[1];
      return {
        ...child,
        title: index_names_dynamics[child_index].name_ru,
      };
    }),
  };
});

export const $indexCards = createStore(initialState);
$indexCards.watch((state) => console.log('$indexCards', state));

export const $indexCardsStart = createStore(initialState);

export const $hexagonsData = createStore(inititalHexData);

export const $indexChartData = createStore(initialChartData);
$indexChartData.watch((state) => console.log('$indexChartData', state));
export const $indexChartStartData = createStore(initialChartData);

export const $cityIndex = createStore(0);

export const $modalOpen = createStore(false);
$modalOpen.watch((state) => console.log('$modalOpen', state));

export const $chosenMetric = createStore(0);

export const $indexTree = createStore(treeData);
