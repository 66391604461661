import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';

function Request(props) {
  const language = useUnit($language);

  const collapses = [
    {
      title: translation[language].request_1_title,
      items: translation[language].request_1_items.split('|'),
    },
    {
      title: translation[language].request_2_title,
      items: translation[language].request_2_items.split('|'),
    },
    {
      title: translation[language].request_3_title,
      items: translation[language].request_3_items.split('|'),
    },
    {
      title: translation[language].request_4_title,
      items: translation[language].request_4_items.split('|'),
    },
    {
      title: translation[language].request_5_title,
      items: translation[language].request_5_items.split('|'),
    },
  ];

  return (
    <div className="landing_container">
      <div className={style.request_wrapper}>
        <div className={style.request_title}>
          {translation[language].request_title}
        </div>
        <div className={style.request_collapse_wrapper}>
          {collapses.map((item, index) => {
            return (
              <CustomCollapse
                key={`request-collapse-${index}`}
                collapseIndex={index}
                {...item}
              />
            );
          })}
        </div>
        <Button type="primary" className={style.request_button}>
          {translation[language].leave_a_request}
        </Button>
      </div>
    </div>
  );
}

function CustomCollapse(props) {
  const { title, items, collapseIndex } = props;

  const [open, setOpen] = useState(false);

  return (
    <div
      className={style.request_collapse}
      onClick={() => setOpen(!open)}
      style={{
        color: open ? '#1780BC' : '#11023B',
      }}
    >
      <div className={style.request_collapse_title_wrapper}>
        <div className={style.request_collapse_title}>{title}</div>
        <PlusOutlined
          className={style.request_collapse_icon}
          rotate={open ? 45 : 0}
        />
      </div>
      <ul
        className={[
          style.request_collapse_item_wrapper,
          open ? style.request_collapse_open : '',
        ].join(' ')}
      >
        {items.map((item, index) => {
          return (
            <li
              key={`collapse-item-${collapseIndex}-${index}`}
              className={style.request_collapse_item}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Request;
