import { sample } from 'effector';
import {
  $zoom9Hexagons,
  $zoom9MutatedByMetric,
  $zoom9MutatedHexagons,
  $zoom9StartHexagons,
  $zoom9StartPeopleData,
} from './stores.js';
import {
  $activeFilters,
  $chartFilters,
  calculateByMetricEv,
  changeActiveFilterEv,
  getPeopleFilteredEv,
  submitFiltersEv,
} from '../activeFiltersModel/index.js';
import zoom8_9_10_connections from '../../data/zoom8_9_10_connections.json';
import {
  filterZoom9GradientEv,
  mutateZoom9PeopleEv,
  putFilteredZoom9HexagonsEv,
  putMutated9HexagonsEv,
  putZoom9HexagonsEv,
  putZoom9StartHexagonsEv,
  putZoom9StartPeopleDataEv,
  resetZoom9ByMetricsEv,
  sendWsGetRBPEv,
  updateZoom9HexagonsBy10Ev,
} from './events.js';
import {
  calculateByMedian,
  calculateHexagonsByMetrics,
  filterGradient,
  mutateHexagons,
} from '../../utils/hexagon-utils.js';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import {
  formatSocdemPeopleGroups,
  hasActiveFilters,
} from '../../utils/active-filters-utils.js';
import { $isochroneStore } from '../isochroneModel/index.js';
import {
  calculateThresholdsEv,
  putBlockedBucketsEv,
} from '../gradientModel/index.js';
import { $userInfo, sendTimersEv } from '../authModel/index.js';
import {
  wsGetPeopleFiltered,
  wsGetRBPData,
  wsGetRBPFilteredData,
} from '../../utils/webSocketConfig.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import { $rbpLayer } from '../mapModel/index.js';

// sample({
//   clock: getZoom9HexagonsFx.doneData,
//   fn: (clock) =>
//     clock.features.map((item) => {
//       return {
//         ...item,
//         properties: {
//           ...item.properties,
//           hex_id: item.id,
//         },
//       };
//     }),
//   target: [$zoom9Hexagons, $zoom9StartHexagons],
// });

$zoom9MutatedByMetric.reset(resetZoom9ByMetricsEv);

sample({
  source: $activeFilters,
  clock: putZoom9HexagonsEv,
  fn: (activeFilters, clock) => {
    const data = mutateHexagons(clock, 'zoom_9', activeFilters.chosen_metrics);
    window.mutate_end = performance.now();
    return data;
  },
  target: [
    $zoom9Hexagons,
    putZoom9StartHexagonsEv,
    sendTimersEv,
    calculateThresholdsEv,
  ],
});

sample({
  source: $zoom9StartHexagons,
  clock: putZoom9StartHexagonsEv,
  filter: (source, clock) =>
    source.length === 0 || source.length === clock.length,
  fn: (source, clock) => clock,
  target: $zoom9StartHexagons,
});

sample({
  clock: putFilteredZoom9HexagonsEv,
  fn: (clock) => mutateHexagons(clock, 'zoom_9'),
  target: $zoom9Hexagons,
});

sample({
  source: [$zoom9StartHexagons, $activeFilters],
  clock: updateZoom9HexagonsBy10Ev,
  fn: ([startData, activeFilters], clock) =>
    window.indexWorker.postMessage({
      worker: 'index',
      action: 'calculateByMedian',
      payload: {
        zoom: 'zoom_9',
        startData,
        hexagons10: clock,
        excludedIndexes: activeFilters.excludedIndexes,
        activeFilters,
      },
    }),
  // target: $zoom9MutatedHexagons,
});

sample({
  clock: putMutated9HexagonsEv,
  fn: (clock) => clock,
  target: $zoom9MutatedHexagons,
});

sample({
  // clock: $activeFilters.updates,
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (clock) => clock.excludedIndexes.length === 0,
  fn: () => [],
  target: $zoom9MutatedHexagons,
});

// sample({
//   source: [
//     $zoom9StartHexagons,
//     $zoom9MutatedHexagons,
//     $activeFilters,
//     $isochroneStore,
//     $zoom9MutatedByMetric,
//   ],
//   clock: [
//     $activeFilters.updates,
//     $zoom9MutatedHexagons.updates,
//     $isochroneStore.updates,
//     $zoom9MutatedByMetric.updates,
//   ],
//   fn: (
//     [startData, mutatedData, activeFilters, isochroneStore, mutatedByMetric],
//     clock
//   ) => {
//     let data = startData;
//
//     if (activeFilters.chosen_metrics.length > 0 && mutatedByMetric.length > 0) {
//       data = mutatedByMetric;
//     } else if (
//       // activeFilters.excludedIndexes.length > 0 &&
//       mutatedData.length > 0
//     ) {
//       data = mutatedData;
//     }
//
//     if (activeFilters.zoom9_hex.id !== '') {
//       return data.filter(
//         (item) => item.properties.hex_id === activeFilters.zoom9_hex.id
//       );
//     }
//     if (activeFilters.zoom8_hex.id !== '') {
//       const zoom9_ids =
//         zoom8_9_10_connections.zoom8_id[activeFilters.zoom8_hex.id];
//       return data.filter((item) => zoom9_ids.includes(item.properties.hex_id));
//
//       // FIXME for proper dict
//       // const zoom9_ids = zoom8_9_connections[clock.zoom8_hex.id];
//       // return source.filter((item) =>
//       //   zoom9_ids.includes(item.properties.hex_id)
//       // );
//     }
//
//     if (hasActiveFilters(activeFilters) || isochroneStore.length > 0) {
//       return filterHexagons(data, activeFilters, isochroneStore);
//     }
//     return data;
//   },
//   target: $zoom9Hexagons,
// });

sample({
  source: [$activeFilters, $userInfo],
  clock: filterZoom9GradientEv,
  filter: ([activeFilters, userInfo], clock) =>
    activeFilters.gradient.length === 0 && !userInfo.useBackend,
  fn: (source, clock) => filterGradient(clock, 'zoom_9'),
  target: putBlockedBucketsEv,
});

sample({
  source: [$zoom9StartHexagons, $activeFilters],
  clock: calculateByMetricEv,
  filter: ([source, activeFilters], clock) =>
    activeFilters.chosen_metrics.length > 0,
  fn: ([startData, activeFilters], clock) =>
    calculateHexagonsByMetrics(startData, activeFilters),
  target: $zoom9MutatedByMetric,
});

sample({
  clock: $activeFilters.updates,
  filter: (clock) => clock.chosen_metrics.length === 0,
  fn: () => [],
  target: $zoom9MutatedByMetric,
});

sample({
  source: [
    $zoom9StartHexagons,
    $activeFilters,
    $isochroneStore,
    $zoom9MutatedByMetric,
    $rbpLayer,
    $zoom9StartPeopleData,
  ],
  clock: [$activeFilters.updates, $isochroneStore.updates],
  filter: ([_, activeFilters, isochroneStore], clock) =>
    hasActiveFilters(activeFilters) ||
    isochroneStore.length > 0 ||
    activeFilters.chosen_metrics.length > 0 ||
    activeFilters.groups.length > 0,
  fn: (
    [
      hexagons,
      activeFilters,
      isochroneStore,
      hexByMetric,
      rbpLayer,
      startPeopleHexagons,
    ],
    clock
  ) => {
    let data = hexagons;
    if (activeFilters.chosen_metrics.length > 0 && hexByMetric.length > 0) {
      data = hexByMetric;
    }
    if (rbpLayer === 'socdem') {
      data = startPeopleHexagons;
    }
    if (activeFilters.zoom9_hex.id !== '') {
      return data.filter(
        (item) => item.properties.hex_id === activeFilters.zoom9_hex.id
      );
    }
    return filterHexagons(data, activeFilters, isochroneStore, rbpLayer);
  },
  target: sendWsGetRBPEv,
});

sample({
  source: [$activeFilters, $isochroneStore, $chartFilters],
  clock: [$activeFilters.updates, $isochroneStore.updates],
  filter: ([activeFilters, isochroneStore]) =>
    !hasActiveFilters(activeFilters) &&
    isochroneStore.length === 0 &&
    activeFilters.excludedIndexes.length === 0 &&
    activeFilters.chosen_metrics.length === 0 &&
    activeFilters.groups.length === 0,
  fn: ([activeFilters, isochroneStore, chartFilters], clock) => {
    // FIXME Causes delete all button on map call API 3 times
    wsGetRBPData({
      business: activeFilters.business_type,
      index_ids:
        activeFilters.excludedIndexes.length > 0
          ? all_metrics
              .filter((item) => !activeFilters.excludedIndexes.includes(item))
              .map((item) => +item.split('index_')[1])
          : [],
      zoom_ids: [],
    });

    if (chartFilters.length > 0) {
      wsGetPeopleFiltered({
        working: chartFilters[0].working,
        zoom_ids: [],
        groups: formatSocdemPeopleGroups(chartFilters),
      });
    }
  },
});

sample({
  source: [$activeFilters, $zoom9StartHexagons],
  clock: sendWsGetRBPEv,
  filter: ([activeFilters], hexagons) =>
    hexagons.length > 0 && activeFilters.gradient.length === 0,
  fn: ([activeFilters, startHexagons], hexagons) => {
    const payload = {
      business: activeFilters.business_type,
      index_ids:
        activeFilters.excludedIndexes.length > 0
          ? all_metrics
              .filter((item) => !activeFilters.excludedIndexes.includes(item))
              .map((item) => +item.split('index_')[1])
          : [],
      zoom_ids:
        hexagons.length === startHexagons.length
          ? []
          : hexagons.map((item) => item.properties.hex_id),
    };
    if (activeFilters.groups.length > 0) {
      payload.groups = activeFilters.groups;
      wsGetRBPFilteredData(payload);
    } else {
      wsGetRBPData(payload);
    }
  },
  target: getPeopleFilteredEv,
});

sample({
  source: $activeFilters,
  clock: sendWsGetRBPEv,
  filter: (activeFilters, hexagons) =>
    hexagons.length > 0 && activeFilters.gradient.length > 0,
  fn: (activeFilters, hexagons) => {
    return hexagons;
  },
  target: $zoom9Hexagons,
});

sample({
  source: [$zoom9Hexagons, $activeFilters],
  clock: changeActiveFilterEv,
  filter: ([hexagons, activeFilters], clock) =>
    clock.field === 'chosen_metrics' &&
    activeFilters.chosen_metrics.includes(clock.value),
  fn: ([hexagons, activeFilters], clock) => hexagons,
  target: sendWsGetRBPEv,
});

sample({
  source: [$zoom9Hexagons, $chartFilters],
  clock: mutateZoom9PeopleEv,
  fn: ([hexagons, chartFilters], clock) => {
    const field =
      chartFilters[0].chart === '103' || chartFilters[0].chart === '104'
        ? 'people_pay'
        : 'people_count';
    return hexagons.map((hexagon) => {
      return {
        ...hexagon,
        properties: {
          ...hexagon.properties,
          people_main:
            clock.find((item) => item.zoom_id === hexagon.properties.hex_id)?.[
              field
            ] || 0,
        },
      };
    });
  },
  target: [$zoom9Hexagons, calculateThresholdsEv],
});

sample({
  source: [$zoom9StartHexagons, $chartFilters],
  clock: putZoom9StartPeopleDataEv,
  fn: ([hexagons, chartFilters], clock) => {
    const field =
      chartFilters[0].chart === '103' || chartFilters[0].chart === '104'
        ? 'people_pay'
        : 'people_count';
    return hexagons.map((hexagon) => {
      return {
        ...hexagon,
        properties: {
          ...hexagon.properties,
          people_main:
            clock.find((item) => item.zoom_id === hexagon.properties.hex_id)?.[
              field
            ] || 0,
        },
      };
    });
  },
  target: $zoom9StartPeopleData,
});
