import { sample } from 'effector';
import { $activeFilters } from './stores.js';
import {
  changeActiveFilterEv,
  clearActiveGradientEv,
  clearFiltersEv,
  dbChangeActiveFilterEv,
  debounced,
  resetActiveFiltersEv,
  selectAllEv,
  submitFiltersBackEv,
  submitFiltersEv,
  updateFiltersByPresetEv,
} from './events.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import { wsGetFilteredPolygons } from '../../utils/webSocketConfig.js';
import { $dataPresets, $presetAreaToEdit } from '../dataPresetsModel/index.js';

$activeFilters.reset(resetActiveFiltersEv);

sample({
  source: $activeFilters,
  clock: [debounced, changeActiveFilterEv],
  fn: (source, clock) => {
    if (/zoom\d+?_hex/g.test(clock.field)) {
      if (source[clock.field].id === clock.value.id) {
        return { ...source, [clock.field]: { id: '', centerCoords: [] } };
      }
      return { ...source, [clock.field]: clock.value };
    }
    if (clock.field === 'excludedIndexes') {
      if (!clock.value) {
        return {
          ...source,
          excludedIndexes: [],
        };
      }
      if (typeof clock.value === 'string') {
        if (source[clock.field].includes(clock.value)) {
          return {
            ...source,
            excludedIndexes: source.excludedIndexes.filter(
              (item) => item !== clock.value
            ),
          };
        }
      } else if (
        clock.value.every((item) => source[clock.field].includes(item))
      ) {
        return {
          ...source,
          excludedIndexes: source.excludedIndexes.filter(
            (item) => !clock.value.includes(item)
          ),
        };
      }
      let addValue = clock.value;
      if (typeof clock.value === 'string') {
        addValue = [clock.value];
      }
      return {
        ...source,
        excludedIndexes: [...source.excludedIndexes, ...addValue],
      };
    }

    if (clock.field === 'gradient') {
      if (
        source.gradient.every(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        )
      ) {
        return {
          ...source,
          gradient: [...source.gradient, clock.value],
        };
      }
      return {
        ...source,
        gradient: source.gradient.filter(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        ),
      };
    }

    if (clock.field === 'chosen_metrics') {
      if (source.chosen_metrics.includes(clock.value)) {
        return {
          ...source,
          chosen_metrics: source.chosen_metrics.filter(
            (item) => item !== clock.value
          ),
        };
      }
      return {
        ...source,
        // FIXME For multiple metrics. Gotta rewrite calculateHexagonsByMetrics in hex utils
        // chosen_metrics: [...source.chosen_metrics, clock.value],
        chosen_metrics: [clock.value],
      };
    }

    if (source[clock.field] === clock.value) {
      return { ...source, [clock.field]: '' };
    }
    return { ...source, [clock.field]: clock.value };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: clearFiltersEv,
  fn: (source, clock) => {
    return {
      ...source,
      excludedIndexes: all_metrics,
    };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: selectAllEv,
  fn: (source, clock) => {
    return {
      ...source,
      excludedIndexes: [],
    };
  },
  target: [$activeFilters, submitFiltersEv],
});

sample({
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (source) => source.chosen_metrics.length !== 0,
  fn: (source, clock) => {
    return {
      field: 'chosen_metrics',
      value: source.chosen_metrics[0],
    };
  },
  target: changeActiveFilterEv,
});

sample({
  source: $activeFilters,
  clock: submitFiltersBackEv,
  fn: (source) => {
    const payload = {
      region_ids: source.district,
      index_ids: all_metrics
        .filter((item) => !source.excludedIndexes.includes(item))
        .map((item) => Number(item.split('index_')[1])),
    };

    wsGetFilteredPolygons(payload);
  },
});

sample({
  source: $activeFilters,
  clock: clearActiveGradientEv,
  fn: (source) => {
    return {
      ...source,
      gradient: [],
    };
  },
  target: $activeFilters,
});

sample({
  source: [$activeFilters, $dataPresets, $presetAreaToEdit],
  clock: updateFiltersByPresetEv,
  filter: ([activeFilters, dataPresets, presetAreaToEdit], clock) =>
    presetAreaToEdit.preset ||
    (presetAreaToEdit.preset && presetAreaToEdit.area),
  fn: ([activeFilters, dataPresets, presetAreaToEdit], clock) => {
    const chosenPreset = dataPresets.find(
      (preset) => preset.name === presetAreaToEdit.preset
    );
    const { isochrone, ...presetActiveFilters } = chosenPreset.activeFilters;
    delete presetActiveFilters.isochrone;
    if (!presetAreaToEdit.area) {
      window.draw.set({
        type: 'FeatureCollection',
        features: [
          ...presetActiveFilters.draw_polygon,
          ...(chosenPreset.activeFilters.isochrone
            ? chosenPreset.activeFilters.isochrone.map((item) => item.point)
            : []),
        ],
      });
      return {
        ...activeFilters,
        ...presetActiveFilters,
      };
    }
    const area_polygon = presetActiveFilters.draw_polygon.find(
      (item) =>
        item.id === presetAreaToEdit.area ||
        item.properties.name === presetAreaToEdit.area
    );
    const chosenIsochrone = isochrone.find(
      (item) => item.point.properties.name === presetAreaToEdit.area
    );
    if (chosenIsochrone) {
      window.draw.set({
        type: 'FeatureCollection',
        features: [chosenIsochrone.point],
      });
    } else {
      window.draw.set({
        type: 'FeatureCollection',
        features: area_polygon ? [area_polygon] : [],
      });
    }

    let district_value = activeFilters.district;
    if (
      !area_polygon &&
      !isochrone
        .map((item) => item.area.properties.name)
        .includes(presetAreaToEdit.area)
    ) {
      district_value = [
        chosenPreset.hexagons.find(
          (item) => item.properties.area === presetAreaToEdit.area
        ).properties.city_region_id,
      ];
    } else {
      district_value = [];
    }
    return {
      ...activeFilters,
      ...presetActiveFilters,
      draw_polygon: area_polygon ? [area_polygon] : [],
      district: district_value,
    };
  },
  target: $activeFilters,
});

sample({
  clock: $presetAreaToEdit.updates,
  filter: (clock) => clock.preset === '' && clock.area === '',
  fn: () => {
    window.draw.set({
      type: 'FeatureCollection',
      features: [],
    });
  },
  target: resetActiveFiltersEv,
});
