import {
  putFilteredZoom8HexagonsEv,
  putZoom8HexagonsEv,
} from '../models/zoom8Model/index.js';
import {
  mutateZoom9PeopleEv,
  putFilteredZoom9HexagonsEv,
  putZoom9HexagonsEv,
  putZoom9StartPeopleDataEv,
} from '../models/zoom9Model/index.js';
import {
  putFilteredZoom10HexagonsEv,
  putZoom10HexagonsEv,
} from '../models/zoom10Model/index.js';
import { hideLoaderEv, showLoaderEv } from '../models/webSocketModel/index.js';
import {
  putChartDataEv,
  putCityIndexEv,
  putFilteredChartDataEv,
  putFilteredIndexCardsDataEv,
  putIndexCardsDataEv,
} from '../models/indexCardsModel/index.js';
import { setUserInfoEv, updateFavoritesEv } from '../models/authModel/index.js';
import { cookies } from '../api/axiosinstance.js';
import {
  changeRbpLayerEv,
  putSearchOptionsEv,
} from '../models/mapModel/index.js';
import { putIsochroneDataEv } from '../models/isochroneModel/index.js';
import { putBlockedBucketsEv } from '../models/gradientModel/index.js';
import { getDataPresetsFx } from '../models/dataPresetsModel/index.js';
import { putEconomicsDataEv } from '../models/economicsModel/index.js';
import { putSocdemDataEv } from '../models/socdemModel/index.js';
import {
  clearChartFiltersEv,
  clearGradientEv,
} from '../models/activeFiltersModel/index.js';

const bostandyk = [
  '8920e60805bffff',
  '8920e608283ffff',
  '8920e608287ffff',
  '8920e60828bffff',
  '8920e60828fffff',
  '8920e608297ffff',
  '8920e6082a7ffff',
  '8920e6082c3ffff',
  '8920e6082c7ffff',
  '8920e6083d7ffff',
  '8920e608273ffff',
  '8920e60800bffff',
  '8920e60804bffff',
  '8920e608053ffff',
  '8920e608003ffff',
  '8920e608007ffff',
  '8920e608057ffff',
  '8920e60800fffff',
  '8920e608013ffff',
  '8920e608017ffff',
  '8920e60801bffff',
  '8920e60803bffff',
  '8920e608043ffff',
  '8920e608047ffff',
  '8920e608063ffff',
  '8920e608067ffff',
  '8920e60806bffff',
  '8920e60806fffff',
  '8920e608073ffff',
  '8920e608077ffff',
  '8920e608083ffff',
  '8920e608087ffff',
  '8920e60808bffff',
  '8920e60808fffff',
  '8920e608093ffff',
  '8920e608097ffff',
  '8920e60809bffff',
  '8920e6080abffff',
  '8920e6080b3ffff',
  '8920e6080bbffff',
  '8920e6080c3ffff',
  '8920e6080c7ffff',
  '8920e6080cbffff',
  '8920e6080cfffff',
  '8920e6080d3ffff',
  '8920e6080d7ffff',
  '8920e6080dbffff',
  '8920e608277ffff',
  '8920e60827bffff',
  '8920e60820bffff',
  '8920e60820fffff',
  '8920e60821bffff',
  '8920e608227ffff',
  '8920e608237ffff',
  '8920e608247ffff',
  '8920e608257ffff',
  '8920e608263ffff',
  '8920e608267ffff',
  '8920e6082cfffff',
  '8920e6082d7ffff',
  '8920e608353ffff',
  '8920e60835bffff',
  '8920e60839bffff',
  '8920e6083c3ffff',
  '8920e6083cbffff',
  '8920e6083cfffff',
  '8920e6083d3ffff',
  '8920e6083dbffff',
  '8920e608403ffff',
  '8920e608407ffff',
  '8920e60840bffff',
  '8920e60840fffff',
  '8920e608413ffff',
  '8920e608417ffff',
  '8920e60841bffff',
  '8920e608423ffff',
  '8920e608427ffff',
  '8920e60842bffff',
  '8920e60842fffff',
  '8920e608433ffff',
  '8920e608437ffff',
  '8920e60843bffff',
  '8920e608443ffff',
  '8920e608447ffff',
  '8920e60844bffff',
  '8920e60844fffff',
  '8920e608453ffff',
  '8920e608457ffff',
  '8920e60845bffff',
  '8920e608463ffff',
  '8920e608467ffff',
  '8920e60846bffff',
  '8920e60846fffff',
  '8920e608473ffff',
  '8920e608477ffff',
  '8920e60847bffff',
  '8920e608483ffff',
  '8920e608487ffff',
  '8920e60848bffff',
  '8920e60848fffff',
  '8920e608493ffff',
  '8920e608497ffff',
  '8920e60849bffff',
  '8920e6084a3ffff',
  '8920e6084a7ffff',
  '8920e6084abffff',
  '8920e6084afffff',
  '8920e6084b3ffff',
  '8920e6084b7ffff',
  '8920e6084bbffff',
  '8920e6084c3ffff',
  '8920e6084c7ffff',
  '8920e6084cbffff',
  '8920e6084cfffff',
  '8920e6084d3ffff',
  '8920e6084d7ffff',
  '8920e6084dbffff',
  '8920e60854bffff',
  '8920e60855bffff',
  '8920e608603ffff',
  '8920e608607ffff',
  '8920e60860bffff',
  '8920e60860fffff',
  '8920e608613ffff',
  '8920e608617ffff',
  '8920e60861bffff',
  '8920e608623ffff',
  '8920e608627ffff',
  '8920e60862fffff',
  '8920e608633ffff',
  '8920e608637ffff',
  '8920e60863bffff',
  '8920e608643ffff',
  '8920e608647ffff',
  '8920e60864bffff',
  '8920e60864fffff',
  '8920e608653ffff',
  '8920e608657ffff',
  '8920e60865bffff',
  '8920e608673ffff',
  '8920e608677ffff',
  '8920e60867bffff',
  '8920e608683ffff',
  '8920e608687ffff',
  '8920e60868bffff',
  '8920e60868fffff',
  '8920e608693ffff',
  '8920e608697ffff',
  '8920e60869bffff',
  '8920e6086a3ffff',
  '8920e6086a7ffff',
  '8920e6086abffff',
  '8920e6086afffff',
  '8920e6086b3ffff',
  '8920e6086b7ffff',
  '8920e6086bbffff',
  '8920e6086c3ffff',
  '8920e6086c7ffff',
  '8920e6086cbffff',
  '8920e6086cfffff',
  '8920e6086d3ffff',
  '8920e6086d7ffff',
  '8920e6086dbffff',
  '8920e609003ffff',
  '8920e609007ffff',
  '8920e60900bffff',
  '8920e609013ffff',
  '8920e609017ffff',
  '8920e60901bffff',
  '8920e609023ffff',
  '8920e609027ffff',
  '8920e609033ffff',
  '8920e609037ffff',
  '8920e609047ffff',
  '8920e60904fffff',
  '8920e609063ffff',
  '8920e60906bffff',
  '8920e609073ffff',
  '8920e60907bffff',
  '8920e609087ffff',
  '8920e60908bffff',
  '8920e60908fffff',
  '8920e609097ffff',
  '8920e6090abffff',
  '8920e6090bbffff',
  '8920e6090c7ffff',
  '8920e609107ffff',
  '8920e60910bffff',
  '8920e60910fffff',
  '8920e609123ffff',
  '8920e60912bffff',
  '8920e60912fffff',
  '8920e609133ffff',
  '8920e60913bffff',
  '8920e609153ffff',
  '8920e609157ffff',
  '8920e609173ffff',
  '8920e609177ffff',
  '8920e6091cbffff',
  '8920e6091cfffff',
  '8920e609343ffff',
  '8920e609203ffff',
  '8920e609207ffff',
  '8920e60920bffff',
  '8920e60920fffff',
  '8920e609213ffff',
  '8920e609217ffff',
  '8920e609227ffff',
  '8920e609237ffff',
  '8920e609247ffff',
  '8920e60924fffff',
  '8920e609263ffff',
  '8920e60926bffff',
  '8920e609273ffff',
  '8920e60927bffff',
  '8920e609287ffff',
  '8920e60928fffff',
  '8920e6092a3ffff',
  '8920e6092a7ffff',
  '8920e6092abffff',
  '8920e6092afffff',
  '8920e6092b3ffff',
  '8920e6092b7ffff',
  '8920e6092bbffff',
  '8920e609347ffff',
  '8920e60934bffff',
  '8920e60934fffff',
  '8920e609353ffff',
  '8920e60935bffff',
  '8920e609363ffff',
  '8920e60936bffff',
  '8920e60936fffff',
  '8920e609373ffff',
  '8920e60937bffff',
  '8920e6093cbffff',
  '8920e6093dbffff',
  '8920e60942fffff',
  '8920e609503ffff',
  '8920e60950bffff',
  '8920e609513ffff',
  '8920e60951bffff',
  '8920e609543ffff',
  '8920e60954bffff',
  '8920e609553ffff',
  '8920e609557ffff',
  '8920e60955bffff',
  '8920e609583ffff',
  '8920e609587ffff',
  '8920e60958bffff',
  '8920e60958fffff',
  '8920e609593ffff',
  '8920e609597ffff',
  '8920e60959bffff',
  '8920e6095b3ffff',
  '8920e6095c3ffff',
  '8920e6095c7ffff',
  '8920e6095cbffff',
  '8920e6095cfffff',
  '8920e6095d3ffff',
  '8920e6095d7ffff',
  '8920e609e93ffff',
  '8920e60a003ffff',
  '8920e60a007ffff',
  '8920e60a00bffff',
  '8920e60a00fffff',
  '8920e60a013ffff',
  '8920e60a017ffff',
  '8920e60a01bffff',
  '8920e60a023ffff',
  '8920e60a027ffff',
  '8920e60a02bffff',
  '8920e60a02fffff',
  '8920e60a033ffff',
  '8920e60a037ffff',
  '8920e60a03bffff',
  '8920e60a043ffff',
  '8920e60a047ffff',
  '8920e60a04bffff',
  '8920e60a04fffff',
  '8920e60a053ffff',
  '8920e60a057ffff',
  '8920e60a05bffff',
  '8920e60a063ffff',
  '8920e60a067ffff',
  '8920e60a06bffff',
  '8920e60a06fffff',
  '8920e60a073ffff',
  '8920e60a077ffff',
  '8920e60a07bffff',
  '8920e60a08bffff',
  '8920e60a08fffff',
  '8920e60a0abffff',
  '8920e60a0afffff',
  '8920e60a0c3ffff',
  '8920e60a0c7ffff',
  '8920e60a0cbffff',
  '8920e60a0cfffff',
  '8920e60a0d3ffff',
  '8920e60a0d7ffff',
  '8920e60a0dbffff',
  '8920e60a10bffff',
  '8920e60a10fffff',
  '8920e60a11bffff',
  '8920e60a12bffff',
  '8920e60a12fffff',
  '8920e60a13bffff',
  '8920e60a143ffff',
  '8920e60a147ffff',
  '8920e60a14bffff',
  '8920e60a14fffff',
  '8920e60a153ffff',
  '8920e60a157ffff',
  '8920e60a353ffff',
  '8920e60a15bffff',
  '8920e60a163ffff',
  '8920e60a167ffff',
  '8920e60a16bffff',
  '8920e60a16fffff',
  '8920e60a173ffff',
  '8920e60a357ffff',
  '8920e60a177ffff',
  '8920e60a17bffff',
  '8920e60a3c3ffff',
  '8920e60a1cbffff',
  '8920e60a1cfffff',
  '8920e60a203ffff',
  '8920e60a207ffff',
  '8920e60a20bffff',
  '8920e60a20fffff',
  '8920e60a213ffff',
  '8920e60a217ffff',
  '8920e60a21bffff',
  '8920e60a223ffff',
  '8920e60a227ffff',
  '8920e60a22bffff',
  '8920e60a22fffff',
  '8920e60a233ffff',
  '8920e60a237ffff',
  '8920e60a23bffff',
  '8920e60a243ffff',
  '8920e60a247ffff',
  '8920e60a24bffff',
  '8920e60a24fffff',
  '8920e60a253ffff',
  '8920e60a257ffff',
  '8920e60a25bffff',
  '8920e60a263ffff',
  '8920e60a267ffff',
  '8920e60a26bffff',
  '8920e60a3c7ffff',
  '8920e60a26fffff',
  '8920e60a273ffff',
  '8920e60a277ffff',
  '8920e60a27bffff',
  '8920e60a283ffff',
  '8920e60a287ffff',
  '8920e60a28bffff',
  '8920e60a28fffff',
  '8920e60a293ffff',
  '8920e60a297ffff',
  '8920e60a2a3ffff',
  '8920e60a2a7ffff',
  '8920e60a2abffff',
  '8920e60a2afffff',
  '8920e60a2b3ffff',
  '8920e60a2b7ffff',
  '8920e60a2bbffff',
  '8920e60a2c7ffff',
  '8920e60a2cbffff',
  '8920e60a2cfffff',
  '8920e60a303ffff',
  '8920e60a307ffff',
  '8920e60a30bffff',
  '8920e60a313ffff',
  '8920e60a317ffff',
  '8920e60a31bffff',
  '8920e60a343ffff',
  '8920e60a347ffff',
  '8920e60a34bffff',
  '8920e60a34fffff',
  '8920e60a35bffff',
  '8920e60a363ffff',
  '8920e60a36bffff',
  '8920e60a36fffff',
  '8920e60a373ffff',
  '8920e60a37bffff',
  '8920e60a383ffff',
  '8920e60a387ffff',
  '8920e60a38bffff',
  '8920e60a38fffff',
  '8920e60a393ffff',
  '8920e60a397ffff',
  '8920e60a39bffff',
  '8920e60a3a3ffff',
  '8920e60a3a7ffff',
  '8920e60a3abffff',
  '8920e60a3afffff',
  '8920e60a3b3ffff',
  '8920e60a3b7ffff',
  '8920e60a3bbffff',
  '8920e60a3cbffff',
  '8920e60a3cfffff',
  '8920e60a3d3ffff',
  '8920e60a3d7ffff',
  '8920e60a3dbffff',
  '8920e60a46bffff',
  '8920e60a46fffff',
  '8920e60a623ffff',
  '8920e60a627ffff',
  '8920e60a62fffff',
  '8920e60a637ffff',
  '8920e60a6a3ffff',
  '8920e60a6a7ffff',
  '8920e60a6afffff',
  '8920e60a6b7ffff',
  '8920e60ae83ffff',
  '8920e60ae8bffff',
  '8920e60ae93ffff',
  '8920e60ae9bffff',
  '8920e60aed3ffff',
  '8920e60aed7ffff',
  '8920e60aedbffff',
  '8920e60b023ffff',
  '8920e60b027ffff',
  '8920e60b02bffff',
  '8920e60b02fffff',
  '8920e60b037ffff',
  '8920e60b03bffff',
  '8920e60b0a7ffff',
  '8920e60b0afffff',
  '8920e60b0b7ffff',
  '8920e60b103ffff',
  '8920e60b107ffff',
  '8920e60b10bffff',
  '8920e60b183ffff',
  '8920e60b10fffff',
  '8920e60b113ffff',
  '8920e60b117ffff',
  '8920e60b11bffff',
  '8920e60b123ffff',
  '8920e60b127ffff',
  '8920e60b12bffff',
  '8920e60b12fffff',
  '8920e60b1c7ffff',
  '8920e60b133ffff',
  '8920e60b137ffff',
  '8920e60b13bffff',
  '8920e60b143ffff',
  '8920e60b147ffff',
  '8920e60b14bffff',
  '8920e60b14fffff',
  '8920e60b153ffff',
  '8920e60b157ffff',
  '8920e60b15bffff',
  '8920e60b163ffff',
  '8920e60b167ffff',
  '8920e60b16bffff',
  '8920e60b16fffff',
  '8920e60b173ffff',
  '8920e60b177ffff',
  '8920e60b17bffff',
  '8920e60b187ffff',
  '8920e60b18bffff',
  '8920e60b18fffff',
  '8920e60b193ffff',
  '8920e60b197ffff',
  '8920e60b19bffff',
  '8920e60b1a3ffff',
  '8920e60b1a7ffff',
  '8920e60b1abffff',
  '8920e60b1afffff',
  '8920e60b1b3ffff',
  '8920e60b1b7ffff',
  '8920e60b1bbffff',
  '8920e60b1c3ffff',
  '8920e60b1cbffff',
  '8920e60b1cfffff',
  '8920e60b1d3ffff',
  '8920e60b1d7ffff',
  '8920e60b1dbffff',
  '8920e60b303ffff',
  '8920e60b307ffff',
  '8920e60b30bffff',
  '8920e60b30fffff',
  '8920e60b313ffff',
  '8920e60b317ffff',
  '8920e60b31bffff',
  '8920e60b323ffff',
  '8920e60b327ffff',
  '8920e60b32bffff',
  '8920e60b32fffff',
  '8920e60b333ffff',
  '8920e60b337ffff',
  '8920e60b33bffff',
  '8920e60b363ffff',
  '8920e60b367ffff',
  '8920e60b36bffff',
  '8920e60b36fffff',
  '8920e60b373ffff',
  '8920e60b377ffff',
  '8920e60b37bffff',
  '8920e60b383ffff',
  '8920e60b387ffff',
  '8920e60b38bffff',
  '8920e60b38fffff',
  '8920e60b393ffff',
  '8920e60b397ffff',
  '8920e60b3a3ffff',
  '8920e60b3a7ffff',
  '8920e60b3abffff',
  '8920e60b3afffff',
  '8920e60b3b3ffff',
  '8920e60b3b7ffff',
  '8920e60b3bbffff',
  '8920e60b507ffff',
  '8920e60b523ffff',
  '8920e60b527ffff',
  '8920e60b52bffff',
  '8920e60b52fffff',
  '8920e60b533ffff',
  '8920e60b537ffff',
  '8920e60b53bffff',
  '8920e60b563ffff',
  '8920e60b567ffff',
  '8920e60b56bffff',
  '8920e60b56fffff',
  '8920e60b573ffff',
  '8920e60b577ffff',
  '8920e60b57bffff',
  '8920e60b5abffff',
  '8920e60b5afffff',
  '8920e646a3bffff',
  '8920e646a53ffff',
  '8920e646a57ffff',
  '8920e646a37ffff',
  '8920e644527ffff',
  '8920e64452fffff',
  '8920e6461a3ffff',
  '8920e6461a7ffff',
  '8920e6461b3ffff',
  '8920e6461b7ffff',
  '8920e64686bffff',
  '8920e64686fffff',
  '8920e646943ffff',
  '8920e646947ffff',
  '8920e64694fffff',
  '8920e646953ffff',
  '8920e646957ffff',
  '8920e6469c3ffff',
  '8920e6469c7ffff',
  '8920e6469cfffff',
  '8920e6469d3ffff',
  '8920e6469d7ffff',
  '8920e646a03ffff',
  '8920e646a07ffff',
  '8920e646a0fffff',
  '8920e646a13ffff',
  '8920e646a17ffff',
  '8920e646a1bffff',
  '8920e646a33ffff',
  '8920e646a5bffff',
  '8920e646aa3ffff',
  '8920e646aa7ffff',
  '8920e646aabffff',
  '8920e646aafffff',
  '8920e646ab7ffff',
  '8920e646ac3ffff',
  '8920e646ac7ffff',
  '8920e646acbffff',
  '8920e646acfffff',
  '8920e646ad7ffff',
  '8920e646adbffff',
  '8920e646b03ffff',
  '8920e646b07ffff',
  '8920e646b0bffff',
  '8920e646b0fffff',
  '8920e646b13ffff',
  '8920e646b17ffff',
  '8920e646b1bffff',
  '8920e646b63ffff',
  '8920e646b6bffff',
  '8920e646b73ffff',
  '8920e646b77ffff',
  '8920e646b7bffff',
  '8920e646b8bffff',
  '8920e646b8fffff',
  '8920e646bb3ffff',
  '8920e646bb7ffff',
  '8920e646bbbffff',
  '8920e646bc3ffff',
  '8920e646bc7ffff',
  '8920e646bcfffff',
  '8920e646bd3ffff',
  '8920e646bd7ffff',
  '8920e646bdbffff',
  '8920e646d1bffff',
  '8920e646d47ffff',
  '8920e646d57ffff',
  '8920e646d63ffff',
  '8920e646d6bffff',
  '8920e646d6fffff',
  '8920e646d7bffff',
  '8920e646dc3ffff',
  '8920e646dc7ffff',
  '8920e646dcfffff',
  '8920e646dd3ffff',
  '8920e646dd7ffff',
];

const favFiledDict = {
  rcp: 'favorites',
  rbp: 'rbp_favorites',
  'rbp/chart': 'rbp_chart_favorites',
};

export function wsOnMessageHandler(message) {
  message = JSON.parse(message.data);
  console.log('WEBSOCKET:: wsOnMessageHandler: message', message);

  switch (message.action) {
    case 'users/login': {
      if (message.response.includes('expired token')) {
        cookies.remove('access_token', { path: '/' });
        window.location.replace('/login');
      }
      break;
    }
    case 'rcp/get_polygons':
      switch (message.part) {
        case 'zoom_8':
          // putZoom8HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_8',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_9':
          // putZoom9HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_9',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_10':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          getDataPresetsFx();
          // hideLoaderEv();
          break;
        case 'city_total':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rbp/get_polygons':
      switch (message.part) {
        case 'city_data':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        case 'zoom_data':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          // if (message.response.polygons.length === 4492) {
          //   putZoom9HexagonsEv(
          //     message.response.polygons.splice(
          //       0,
          //       Math.ceil(message.response.polygons.length / 2)
          //     )
          //   );
          // } else {
          // putZoom9HexagonsEv(
          //   message.response.polygons.filter((item) =>
          //     bostandyk.includes(item.id)
          //   )
          // );
          putZoom9HexagonsEv(message.response.polygons);
          // }
          break;
        case 'diagram_data':
          putSocdemDataEv(message.response[1]);
          putEconomicsDataEv(message.response[2]);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rcp/get_polygons_filtered':
      switch (message.part) {
        case 'zoom_8':
          putFilteredZoom8HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_8',
            value: message.response.gradient,
          });
          break;
        case 'zoom_9':
          putFilteredZoom9HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_9',
            value: message.response.gradient,
          });
          break;
        case 'zoom_10':
          putFilteredZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          hideLoaderEv();
          break;
        case 'city_total':
          putFilteredIndexCardsDataEv(message.response);
          putFilteredChartDataEv(message.response);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rbp/get_polygons_filtered':
      clearGradientEv();
      clearChartFiltersEv();
      changeRbpLayerEv('index');
      switch (message.part) {
        case 'city_data':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        case 'zoom_data':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom9HexagonsEv(message.response.polygons);
          break;
        case 'diagram_data':
          putSocdemDataEv(message.response[1]);
          putEconomicsDataEv(message.response[2]);
          break;
      }
      break;
    case 'users/info':
      setUserInfoEv(message.response);
      break;
    case 'map/get_point':
      putSearchOptionsEv(message.response);
      break;
    case 'map/get_isochrone':
      const id = `isochrone_lat_${message.payload.lat}_lon_${message.payload.lon}`;
      putIsochroneDataEv({ response: message.response, id });
      break;
    case 'user/favorite/add':
    case 'user/favorite/remove':
      {
        const field = favFiledDict[message.payload.api];
        updateFavoritesEv({
          field,
          value: message.response,
        });
      }
      break;
    case 'rbp/get_people_filtered':
      {
        mutateZoom9PeopleEv(message.response);
        changeRbpLayerEv('socdem');
        if (message.payload.zoom_ids.length === 0) {
          putZoom9StartPeopleDataEv(message.response);
        }
      }
      break;
    default:
      break;
  }
}

export function wsGetAlmatyPolygons() {
  showLoaderEv();
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'rcp/get_polygons',
      partial: true,
    })
  );
}

export function wsSendAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}
export function wsSendRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendDarkTheme(dark_theme) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/set_dark_theme',
      payload: {
        enable: dark_theme,
      },
    })
  );
}

export function wsGetSearchOptions(place) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'map/get_point',
      payload: {
        address: place,
      },
      partial: false,
    })
  );
}

export function wsGetIsochrone(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'map/get_isochrone',
      payload: {
        lat: payload.coordinates ? payload.coordinates[1] : null,
        lon: payload.coordinates ? payload.coordinates[0] : null,
        time: payload.time ? payload.time : 5,
        traffic: payload.traffic
          ? payload.type === 'walk'
            ? false
            : payload.traffic
          : false,
        type: payload.type ? payload.type : 'walk',
      },
    })
  );
}

export function wsGetFilteredPolygons(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rcp/get_polygons_filtered',
      payload: {
        region_ids: payload.region_ids ? payload.region_ids : [],
        index_ids: payload.index_ids ? payload.index_ids : [],
      },
      partial: true,
    })
  );
}

export function wsGetRBPData(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_polygons',
      payload: {
        business: payload?.business ? payload.business : '401',
        index_ids: payload?.index_ids ? payload.index_ids : [],
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
      },
      partial: true,
    })
  );
}

export function wsSendRBPAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPAddFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}

export function wsGetRBPFilteredData(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_polygons_filtered',
      payload: {
        business: payload?.business ? payload.business : '401',
        index_ids: payload?.index_ids ? payload.index_ids : [],
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
        groups: payload?.groups ? payload.groups : [],
      },
      partial: true,
    })
  );
}

// FIXME RBP Socdem click on charts
export function wsGetPeopleFiltered(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_people_filtered',
      payload: {
        working: payload?.working ? payload.working : false,
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
        groups: payload?.groups ? payload.groups : [],
      },
    })
  );
}
