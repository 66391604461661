import { Button, Card, Form, Input, InputNumber } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { $authStatus, registerEv } from '../../models/authModel/index.js';

export default function Register() {
  const sendRegister = useUnit(registerEv);

  const onRegister = (values) => {
    const payload = {
      ...values,
      is_confirmed: false,
      is_blocked: false,
      group_ids: [],
      rcp: true,
      rbp: false,
    };

    console.log('Register payload', payload);

    sendRegister(payload);
  };

  return (
    <div className="content" style={{ height: '100vh' }}>
      <Card className="register-form" id="register-form">
        <Form
          name="registerForm"
          id="registerForm"
          onFinish={onRegister}
          layout="vertical"
        >
          <Form.Item
            rules={[{ required: true, message: 'Пожалуйста, введите имя' }]}
            name="name"
            label="Имя"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Номер телефона"
            className="register_form_item"
          >
            <InputNumber
              type="number"
              controls={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Описание"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="organization"
            label="Организация"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="department"
            label="Отделение"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="supervisor"
            label="Руководитель"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="position"
            label="Должность"
            className="register_form_item"
          >
            <Input />
          </Form.Item>
          <Form.Item name="purpose" label="Цель" className="register_form_item">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите e-mail',
                pattern: /.+@.+\..+/,
              },
            ]}
            name="email"
            label="E-mail"
            className="register_form_item"
            validateTrigger="onBlur"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
            name="password"
            label="Пароль"
            className="register_form_item"
          >
            <Input.Password allowClear />
          </Form.Item>
          <div className="register-login">
            <Button
              htmlType="submit"
              form="registerForm"
              type="primary"
              className="register-btn"
            >
              Зарегистрироваться
            </Button>
            <Link to="/login">Войти</Link>
          </div>
        </Form>
      </Card>
    </div>
  );
}
