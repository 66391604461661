import Chart from 'react-apexcharts';
import { useRef } from 'react';
import { useUnit } from 'effector-react';
import {
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import style from '../InfographicsTabs.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../../models/authModel/index.js';
import index_names_dynamics from '../../../dictionaries/index_names_dymanics.json';
import {
  wsSendRBPAddFavoritesChart,
  wsSendRBPRemoveFavoritesChart,
} from '../../../utils/webSocketConfig.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { changeModalOpenEv } from '../../../models/indexCardsModel/index.js';
import { rbp_chart_units } from '../../../dictionaries/rbp_dicts.js';
import { formatNumber } from '../../../utils/format-utils.js';

export default function BarChart(props) {
  const { expanded, data, title } = props;
  const chartRef = useRef();
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const userFavs = useUnit($userInfo).rbp_chart_favorites;
  const changeModalOpen = useUnit(changeModalOpenEv);

  const addFavorite = () => {
    wsSendRBPAddFavoritesChart([...userFavs, +title]);
  };

  const removeFavorite = () => {
    wsSendRBPRemoveFavoritesChart([+title]);
  };

  const onQuestionClick = () => {
    console.log('card_index: -', title);
    changeModalOpen(`d${title}`);
  };

  const options = {
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        borderRadiusApplication: 'end',
      },
    },
    chart: {
      stacked: true,
      // offsetY: -20,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    xaxis: {
      categories: Object.keys(data[Object.keys(data)[0]]).map(
        (item) => ru_en_page_dictionary[item][language]
      ),
      labels: {
        formatter: (value) =>
          `${formatNumber(value)} ${rbp_chart_units[title].x[language]}`,
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: (value) => `${ru_en_page_dictionary[value][language]}`,
      // onItemClick: {
      //   toggleDataSeries: false,
      // },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      y: {
        title: {
          formatter: (value) => `${ru_en_page_dictionary[value][language]}:`,
        },
      },
    },
  };
  const series = Object.keys(data).map((item) => {
    return {
      name: item,
      data: Object.values(data[item]),
    };
  });

  return (
    <div
      className={[
        style.chart_wrapper,
        expanded ? style.chart_expanded : '',
      ].join(' ')}
      style={{
        background: darkMode ? '#181A1F' : '#F8F8F8',
      }}
    >
      <div className={style.chart_header_wrapper}>
        <div className={style.chart_title}>
          {index_names_dynamics[`d${title}`][`name_${language}`]}
        </div>
        {userFavs.includes(+title) ? (
          <StarFilled onClick={removeFavorite} />
        ) : (
          <StarOutlined onClick={addFavorite} />
        )}
        <div
          className={style.modal_button}
          onClick={onQuestionClick}
          style={{
            color: darkMode ? 'white' : '#717171',
          }}
        >
          <InfoCircleOutlined />
        </div>
      </div>
      <Chart
        ref={chartRef}
        type="bar"
        options={options}
        series={series}
        width={500}
        height={190}
        padding="15"
      />
    </div>
  );
}
