import { createEffect } from 'effector';
import { getGeoBuckets } from '../../utils/gradient-utils.js';
import { api } from '../../api/axiosinstance.js';

export const bucketsByAlgorithmFx = createEffect().use(
  async ({ hexagons, algorithm, rbpLayer }) => {
    if (algorithm === 'JNK' && hexagons.length > 11000) {
      const payload = {
        array: hexagons.map((item) => item.properties.index_main),
        buckets: 11,
      };
      const result = (await api(true).post('utils/jenks', payload)).data.map(
        (item) => Math.floor(item)
      );
      return result;
    }
    return await getGeoBuckets(hexagons, algorithm, rbpLayer);
  }
);
