import { createEffect } from 'effector';
// import zoom10hexagons from '../../data/Zoom_10.json';
import zoom10hexagons from '../../data/zoom10.json';

export const getZoom10HexagonsFx = createEffect().use(async () => {
  return new Promise((resolve) =>
    setTimeout(() => {
      return resolve(zoom10hexagons);
    }, 1000)
  );
});
