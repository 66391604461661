import { createStore } from 'effector';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import { small_icons_dict } from '../../dictionaries/icons_dict.jsx';

const initialState = [
  {
    index_name: 'index_1',
    title: 'Index 1',
    index: 0.69,
    difference: 0.5,
    bgColor: 'red',
    id: '1',
    children: [
      {
        title: 'SubIndex 1 1',
        icon: () => null,
        index_name: 'index_101',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-1',
      },
      {
        title: 'SubIndex 1 2',
        icon: () => null,
        index_name: 'index_102',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-2',
      },
      {
        title: 'SubIndex 1 3',
        icon: () => null,
        index_name: 'index_103',
        index: 0.69,
        difference: 0.5,
        bgColor: 'red',
        id: '1-3',
      },
    ],
  },
  {
    index_name: 'index_2',
    title: 'Index 2',
    index: 0.6,
    difference: -0.5,
    bgColor: 'blue',
    id: '2',
    children: [
      {
        title: 'SubIndex 2 1',
        icon: () => null,
        index_name: 'index_201',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-1',
      },
      {
        title: 'SubIndex 2 2',
        icon: () => null,
        index_name: 'index_202',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-2',
      },
      {
        title: 'SubIndex 2 3',
        icon: () => null,
        index_name: 'index_203',
        index: 0.6,
        difference: -0.5,
        bgColor: 'blue',
        id: '2-3',
      },
    ],
  },
  {
    index_name: 'index_3',
    title: 'Index 3',
    index: 0.58,
    difference: 0.5,
    bgColor: 'green',
    id: '3',
    children: [
      {
        title: 'SubIndex 3 1',
        icon: () => null,
        index_name: 'index_301',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-1',
      },
      {
        title: 'SubIndex 3 2',
        icon: () => null,
        index_name: 'index_302',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-2',
      },
      {
        title: 'SubIndex 3 3',
        icon: () => null,
        index_name: 'index_303',
        index: 0.58,
        difference: 0.5,
        bgColor: 'green',
        id: '3-3',
      },
    ],
  },
];

const inititalHexData = {
  zoom_8: {
    id: '',
    data: [],
  },
  zoom_9: {
    id: '',
    data: [],
  },
  zoom_10: {
    id: '',
    data: [],
  },
};

const initialChartData = [
  {
    title: 'index 1',
    index_name: 'index_1',
    value: 0,
  },
  {
    title: 'index 2',
    index_name: 'index_2',
    value: 0,
  },
  {
    title: 'index 3',
    index_name: 'index_3',
    value: 0,
  },
];

let treeData = [
  {
    title: 'index 1',
    key: 'index_1',
    icon: small_icons_dict[1],
    children: [
      {
        title: 'index 101',
        key: 'index_101',
      },
      {
        title: 'index 102',
        key: 'index_102',
      },
      {
        title: 'index 103',
        key: 'index_103',
      },
      {
        title: 'index 104',
        key: 'index_104',
      },
    ],
  },
  {
    title: 'index 2',
    key: 'index_2',
    icon: small_icons_dict[2],
    children: [
      {
        title: 'index 201',
        key: 'index_201',
      },
      {
        title: 'index 202',
        key: 'index_202',
      },
      {
        title: 'index 203',
        key: 'index_203',
      },
      {
        title: 'index 204',
        key: 'index_204',
      },
      {
        title: 'index 205',
        key: 'index_205',
      },
      {
        title: 'index 206',
        key: 'index_206',
      },
      {
        title: 'index 207',
        key: 'index_207',
      },
    ],
  },
  {
    title: 'index 3',
    key: 'index_3',
    icon: small_icons_dict[3],
    children: [
      {
        title: 'index 301',
        key: 'index_301',
      },
      {
        title: 'index 302',
        key: 'index_302',
      },
      {
        title: 'index 303',
        key: 'index_303',
      },
      {
        title: 'index 304',
        key: 'index_304',
      },
      {
        title: 'index 305',
        key: 'index_305',
      },
      {
        title: 'index 306',
        key: 'index_306',
      },
      {
        title: 'index 307',
        key: 'index_307',
      },
      {
        title: 'index 308',
        key: 'index_308',
      },
      {
        title: 'index 309',
        key: 'index_309',
      },
      {
        title: 'index 310',
        key: 'index_310',
      },
    ],
  },
];

treeData = treeData.map((item) => {
  const index = item.key.split('index_')[1];
  return {
    ...item,
    title: index_names_dynamics[index].name_ru,
    children: item.children.map((child) => {
      const child_index = child.key.split('index_')[1];
      return {
        ...child,
        title: index_names_dynamics[child_index].name_ru,
      };
    }),
  };
});

export const $indexCards = createStore(initialState);
$indexCards.watch((state) => console.log('$indexCards', state));

export const $indexCardsStart = createStore(initialState);

export const $hexagonsData = createStore(inititalHexData);

export const $indexChartData = createStore(initialChartData);
$indexChartData.watch((state) => console.log('$indexChartData', state));
export const $indexChartStartData = createStore(initialChartData);

export const $cityIndex = createStore(0);

export const $modalOpen = createStore(false);
$modalOpen.watch((state) => console.log('$modalOpen', state));

export const $chosenMetric = createStore(0);

export const $indexTree = createStore(treeData);
