import { createEvent } from 'effector';

export const putIndexCardsDataEv = createEvent();

export const saveHexDataEv = createEvent();

export const putChartDataEv = createEvent();

export const putCityIndexEv = createEvent();

export const changeModalOpenEv = createEvent();

export const updateChartByZoom10Ev = createEvent();
export const updateCardsByZoom10Ev = createEvent();

export const putFilteredIndexCardsDataEv = createEvent();
export const putFilteredChartDataEv = createEvent();
