import React from 'react';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import footer_logo from '../../../assets/images/sections/footer/footer_logo.svg';
import footer_instagram from '../../../assets/images/sections/footer/footer_instagram.svg';
import footer_facebook from '../../../assets/images/sections/footer/footer_facebook.svg';
import footer_telegram from '../../../assets/images/sections/footer/footer_telegram.svg';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';

function Footer(props) {
  const language = useUnit($language);

  return (
    <div className={style.footer_wrapper}>
      <div className="landing_container">
        <div className={style.footer_content}>
          <img src={footer_logo} style={{ height: 49 }} />
          <div className={style.footer_info_block}>
            <div className={style.footer_info_title}>
              {translation[language].footer_title_1}
            </div>
            <div className={style.footer_info_description}>
              {translation[language].footer_description_1}
            </div>
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: translation[language].footer_description_2,
              }}
            />
          </div>
          <div className={style.footer_info_block}>
            <div className={style.footer_info_title}>
              {translation[language].footer_title_2}
            </div>
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: translation[language].footer_description_3,
              }}
            />
            <div className={style.footer_info_description}>
              {translation[language].footer_description_4}
            </div>
          </div>
          <div className={style.footer_info_block}>
            <div className={style.footer_info_title}>
              {translation[language].footer_title_3}
            </div>
            <div className={style.footer_social_wrapper}>
              <a href="">
                <img src={footer_instagram} />
              </a>
              <a href="">
                <img src={footer_facebook} />
              </a>
              <a href="">
                <img src={footer_telegram} />
              </a>
            </div>
          </div>
        </div>
        <div className={style.footer_subtitle}>
          {translation[language].footer_subtitle}
        </div>
      </div>
    </div>
  );
}

export default Footer;
