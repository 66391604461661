import React from 'react';
import style from '../Landing.module.css';

function BgLines(props) {
  return (
    <div className={style.bg_lines_wrapper}>
      <div className={style.bg_line} />
      <div className={style.bg_line} />
      <div className={style.bg_line} />
      <div className={style.bg_line} />
      <div className={style.bg_line} />
    </div>
  );
}

export default BgLines;
