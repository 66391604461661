import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import Chart from 'react-apexcharts';
import { useEffect, useRef } from 'react';
import style from './ColumnChart.module.css';
import { $indexChartData } from '../../models/indexCardsModel/index.js';
import {
  $isDarkTheme,
  $isTablet,
  $language,
} from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $activeAreas,
  $dataPresets,
  $showPresets,
} from '../../models/dataPresetsModel/index.js';

export default function ColumnChart(props) {
  const { showInfo } = props;
  const showPresets = useUnit($showPresets);
  const dataPresets = useUnit($dataPresets);
  const indexChartData = useUnit($indexChartData);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const isTablet = useUnit($isTablet);
  const chartRef = useRef();
  const activeAreas = useUnit($activeAreas);

  const activeDataPresets = dataPresets.filter((preset) =>
    activeAreas.some((area) => preset.areas[area])
  );

  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
      },
    },
    fill: {
      colors: showPresets
        ? activeDataPresets.map((item) => item.color)
        : ['#2D9CDB'],
    },
    chart: {
      // stacked: true,
      // offsetY: -20,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    xaxis: {
      categories: indexChartData.map((item) =>
        item.index_name ? `i${item.index_name?.split('index_')[1]}` : ''
      ),
      labels: {
        show: true,
        style: {
          colors: [
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
            '#2D9CDB',
          ],
          fontSize: '14px',
          fontFamily: 'icomoon',
        },
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      x: {
        formatter: (value, opts) => {
          if (value === 'i0') {
            return ru_en_page_dictionary.resilience_index[language];
          }
          return indexChartData[opts.dataPointIndex].title;
        },
      },
      y: {
        title: {
          formatter: () => `${ru_en_page_dictionary.radar_tooltip[language]}:`,
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
      markers: {
        fillColors: showPresets
          ? activeDataPresets.map((item) => item.color)
          : undefined,
      },
    },
    dataLabels: {
      enabled: false,
      // offsetY: -20,
    },
  };

  const series = !showPresets
    ? [
        {
          name: ru_en_page_dictionary.radar_tooltip[language],
          data: indexChartData.map((item) => item.value),
        },
      ]
    : activeDataPresets.map((preset) => {
        return {
          name: preset.name,
          data: [...preset.chartData.map((item) => item.value)],
        };
      });

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.ctx.updateOptions(options);
    }
  }, [indexChartData, darkMode]);

  const genereateTooltipValue = () => {
    return (
      <div className={style.tooltip_wrapper}>
        {indexChartData.map((item) => {
          const index = item.index_name.split('index_')[1];
          return (
            <div
              key={`tooltip-${item.index_name}`}
              className={style.tooltip_item}
            >
              <div className={style.tooltip_icon}>{`i${index}`}</div>
              {item.title} - {item.value}
            </div>
          );
        })}
      </div>
    );
  };

  console.log(series);

  return (
    <div className={style.column_wrapper}>
      {showInfo !== false ? (
        <Tooltip
          title={genereateTooltipValue()}
          placement="bottomRight"
          arrow={false}
        >
          <QuestionCircleFilled className={style.question_icon} />
        </Tooltip>
      ) : (
        ''
      )}
      <Chart
        ref={chartRef}
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={isTablet ? '267' : '256'}
        padding="15"
      />
    </div>
  );
}
