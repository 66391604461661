import { Button, Popover, Radio, Select, Space } from 'antd';
import Icon from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import style from './DataPresets.module.css';
import {
  $isDarkTheme,
  $isTablet,
  $language,
} from '../../models/authModel/index.js';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';

function SortSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M.75 4.875 4.875.75m0 0L9 4.875M4.875.75v12.375m12.375 0-4.125 4.125m0 0L9 13.125m4.125 4.125V4.875"
      />
    </svg>
  );
}

function SortIcon() {
  return <Icon component={SortSvg} />;
}

export default function PresetsSorter(props) {
  const { sortOrder, setSortOrder, sortBy, setSortBy, index = true } = props;

  const language = useUnit($language);
  const isTablet = useUnit($isTablet);
  const sortOptions = [
    {
      label: ru_en_page_dictionary.sort_by_name[language],
      value: 'name',
    },
    {
      label: ru_en_page_dictionary.sort_by_index[language],
      value: 'index',
    },
    {
      label: index_names_dynamics[1][`name_${language}`],
      value: 'index_1',
    },
    {
      label: index_names_dynamics[2][`name_${language}`],
      value: 'index_2',
    },
    {
      label: index_names_dynamics[3][`name_${language}`],
      value: 'index_3',
    },
  ];
  const sortOptionsNoIndex = [
    {
      label: ru_en_page_dictionary.sort_by_name[language],
      value: 'name',
    },
    {
      label: ru_en_page_dictionary.sort_by_create[language],
      value: 'created_at',
    },
    {
      label: ru_en_page_dictionary.sort_by_update[language],
      value: 'updated_at',
    },
    {
      label: ru_en_page_dictionary.sort_by_author[language],
      value: 'author',
    },
  ];

  const sortContent = () => {
    return (
      <div className={style.sort_wrapper}>
        <div className={style.sort_select_title}>
          {ru_en_page_dictionary.sort_by[language]}:
        </div>
        <Select
          className={style.sort_select}
          options={index ? sortOptions : sortOptionsNoIndex}
          value={sortBy}
          onSelect={setSortBy}
        />
        <div className={style.order_title}>
          {ru_en_page_dictionary.order[language]}
        </div>
        <Radio.Group
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <Space direction="vertical">
            <Radio value="asc">
              {ru_en_page_dictionary.sort_ascend[language]}
            </Radio>
            <Radio value="desc">
              {ru_en_page_dictionary.sort_descend[language]}
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    );
  };

  return (
    <Popover
      content={sortContent}
      placement="left"
      trigger="click"
      arrow={false}
    >
      <Button
        icon={<SortIcon />}
        className="no_bg"
        style={{ border: 'unset', color: isTablet ? '#717171' : '#a5a5a5' }}
      />
    </Popover>
  );
}
