import { useUnit } from 'effector-react';
import TopBar from '../TopBar/TopBar.jsx';
import MetricTree from '../LeftBar/MetricTree.jsx';
import TabletRadar from '../LeftBar/TabletRadar.jsx';
import TabletReport from '../ReportModal/TabletReport.jsx';
import TabletSideModal from '../SideModal/TabletSideModal.jsx';
import TabletDataPresets from '../DataPresets/TabletDataPresets.jsx';
import TabletPresetsModal from '../PresetsSideModal/TabletPresetsModal.jsx';
import style from './TabletLeftBar.module.css';
import { $tabletBarHidden } from '../../models/authModel/index.js';

export default function TabletContentManager(props) {
  const { activeTab } = props;
  const tabletBarHidden = useUnit($tabletBarHidden);

  switch (activeTab) {
    case 0:
      return (
        <div className={style.appear_animation}>
          <TabletRadar />
        </div>
      );
    case 1:
      return (
        <div className={style.appear_animation}>
          <MetricTree expanded />
        </div>
      );
    case 2:
      return (
        <div
          className={style.appear_animation}
          style={{ position: 'relative', maxWidth: '302px' }}
        >
          <TopBar />
          <TabletSideModal />
        </div>
      );
    case 3:
      return (
        <div className={style.appear_animation}>
          <TabletReport />
        </div>
      );
    case 4:
      return (
        <div className={style.appear_animation} style={{ padding: '10px' }}>
          <TabletPresetsModal />
        </div>
      );
    case 5:
      return (
        <div className={style.appear_animation} style={{ padding: '10px' }}>
          <TabletDataPresets />
        </div>
      );
    default:
      return <div> Nothing to display </div>;
  }
}
