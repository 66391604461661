export const hasActiveFilters = (activeFilters) => {
  if (
    activeFilters?.zoom10_hex?.id !== '' ||
    activeFilters?.zoom9_hex?.id !== '' ||
    activeFilters?.zoom8_hex?.id !== '' ||
    activeFilters.district.length > 0 ||
    Object.keys(activeFilters.draw_polygon).length > 0 ||
    activeFilters?.gradient?.length > 0
  ) {
    return true;
  }
  return false;
};

export const formatSocdemPeopleGroups = (groups) => {
  const field = Object.hasOwn(groups[0], 'age') ? 'age' : 'income';
  const formattedGroups = [];

  groups.forEach((group) => {
    const existingIndex = formattedGroups.findIndex((item) =>
      item[field].includes(group[field])
    );
    if (existingIndex !== -1) {
      formattedGroups[existingIndex].gender = [
        ...formattedGroups[existingIndex].gender,
        group.gender,
      ];
    } else {
      formattedGroups.push({
        [field]: [group[field]],
        gender: [group.gender],
      });
    }
  });

  return formattedGroups;
};
