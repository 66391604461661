import { median } from 'mathjs';
import zoom8_9_10_connections from '../data/zoom8_9_10_connections.json';
import zoom8 from '../data/zoom8.json';
import zoom9 from '../data/zoom9.json';
import zoom10 from '../data/zoom10.json';
import { hasActiveFilters } from './active-filters-utils.js';

const index_1_subindexes = ['index_101', 'index_102', 'index_103', 'index_104'];
const index_2_subindexes = [
  'index_201',
  'index_202',
  'index_203',
  'index_204',
  'index_205',
  'index_206',
  'index_207',
];
const index_3_subindexes = [
  'index_301',
  'index_302',
  'index_303',
  'index_304',
  'index_305',
  'index_306',
  'index_307',
  'index_308',
  'index_309',
  'index_310',
];

const non_urban_metrics = ['index_105'];

const sub_index_metrics_tree = {
  1: index_1_subindexes,
  2: index_2_subindexes,
  3: index_3_subindexes,
};

export const mutateHexagons = (hexagons, mode, chosen_metrics = []) => {
  let source;
  switch (mode) {
    case 'zoom_8':
      source = zoom8.features;
      break;
    case 'zoom_9':
      source = zoom9.features;
      break;
    case 'zoom_10':
      source = zoom10.features;
      break;
    default:
      break;
  }

  return hexagons
    .map((item) => {
      let index_main = typeof item.idx[0] === 'number' ? item.idx[0] : null;

      if (chosen_metrics.length > 0) {
        const chosen_index = chosen_metrics[0].split('index_')[1];
        index_main =
          typeof item.idx[chosen_index] === 'number'
            ? item.idx[chosen_index]
            : null;
      }

      const properties = {
        hex_id: item.id,
        city_region_id: item.ctreg,
        index_main,
        index_1: typeof item.idx[1] === 'number' ? item.idx[1] : null,
        index_2: typeof item.idx[2] === 'number' ? item.idx[2] : null,
        index_3: typeof item.idx[3] === 'number' ? item.idx[3] : null,
        index_101: typeof item.idx[101] === 'number' ? item.idx[101] : null,
        index_102: typeof item.idx[102] === 'number' ? item.idx[102] : null,
        index_103: typeof item.idx[103] === 'number' ? item.idx[103] : null,
        index_104: typeof item.idx[104] === 'number' ? item.idx[104] : null,
        index_201: typeof item.idx[201] === 'number' ? item.idx[201] : null,
        index_202: typeof item.idx[202] === 'number' ? item.idx[202] : null,
        index_203: typeof item.idx[203] === 'number' ? item.idx[203] : null,
        index_204: typeof item.idx[204] === 'number' ? item.idx[204] : null,
        index_205: typeof item.idx[205] === 'number' ? item.idx[205] : null,
        index_206: typeof item.idx[206] === 'number' ? item.idx[206] : null,
        index_207: typeof item.idx[207] === 'number' ? item.idx[207] : null,
        index_301: typeof item.idx[301] === 'number' ? item.idx[301] : null,
        index_302: typeof item.idx[302] === 'number' ? item.idx[302] : null,
        index_303: typeof item.idx[303] === 'number' ? item.idx[303] : null,
        index_304: typeof item.idx[304] === 'number' ? item.idx[304] : null,
        index_305: typeof item.idx[305] === 'number' ? item.idx[305] : null,
        index_306: typeof item.idx[306] === 'number' ? item.idx[306] : null,
        index_307: typeof item.idx[307] === 'number' ? item.idx[307] : null,
        index_308: typeof item.idx[308] === 'number' ? item.idx[308] : null,
        index_309: typeof item.idx[309] === 'number' ? item.idx[309] : null,
        index_310: typeof item.idx[310] === 'number' ? item.idx[310] : null,
      };
      const geometry = source.find(
        (feature) => feature.id === item.id
      )?.geometry;
      return {
        type: 'Feature',
        properties,
        id: item.zoom_id,
        geometry,
      };
    })
    .filter((item) => item.geometry);
};

const calculateIndex = (hexagon, filtered_indexes) => {
  if (filtered_indexes.length === 0) return null;
  let result = 0;
  let length = 0;
  filtered_indexes.forEach((index) => {
    if (hexagon.properties[index] !== null) {
      result += hexagon.properties[index];
      length += 1;
    }
  });
  return Number.isNaN(result / length) ? null : result / length;
};

const filterSubindexes = (subindexes, excludedIndexes) => {
  return subindexes.filter((subindex) => !excludedIndexes.includes(subindex));
};

export const calculateIndexesByMedian = (
  hexagons,
  excludedIndexes,
  activeFilters,
  total = false
) => {
  const result = {};

  const filtered_hexes = hexagons.filter((item) => item);

  if (total) {
    console.time('TIME:: zoom 10 hexes');
    const subindexes_to_change = [];
    if (excludedIndexes.length === 0 || hasActiveFilters(activeFilters)) {
      subindexes_to_change.push('1', '2', '3', '4', '5');
    } else {
      excludedIndexes.forEach((index) => {
        const subindex = index.split('_')[1][0];
        if (!subindexes_to_change.includes(subindex)) {
          subindexes_to_change.push(subindex);
        }
      });
    }

    filtered_hexes.forEach((hex) => {
      if (subindexes_to_change.includes('1')) {
        hex.index_101 !== null
          ? Array.isArray(result.index_101)
            ? result.index_101.push(hex.index_101)
            : (result.index_101 = [hex.index_101])
          : '';
        hex.index_102 !== null
          ? Array.isArray(result.index_102)
            ? result.index_102.push(hex.index_102)
            : (result.index_102 = [hex.index_102])
          : '';
        hex.index_103 !== null
          ? Array.isArray(result.index_103)
            ? result.index_103.push(hex.index_103)
            : (result.index_103 = [hex.index_103])
          : '';
        hex.index_104 !== null
          ? Array.isArray(result.index_104)
            ? result.index_104.push(hex.index_104)
            : (result.index_104 = [hex.index_104])
          : '';
      }
      if (subindexes_to_change.includes('2')) {
        hex.index_201 !== null
          ? Array.isArray(result.index_201)
            ? result.index_201.push(hex.index_201)
            : (result.index_201 = [hex.index_201])
          : '';
        hex.index_202 !== null
          ? Array.isArray(result.index_202)
            ? result.index_202.push(hex.index_202)
            : (result.index_202 = [hex.index_202])
          : '';
        hex.index_203 !== null
          ? Array.isArray(result.index_203)
            ? result.index_203.push(hex.index_203)
            : (result.index_203 = [hex.index_203])
          : '';
        hex.index_204 !== null
          ? Array.isArray(result.index_204)
            ? result.index_204.push(hex.index_204)
            : (result.index_204 = [hex.index_204])
          : '';
        hex.index_205 !== null
          ? Array.isArray(result.index_205)
            ? result.index_205.push(hex.index_205)
            : (result.index_205 = [hex.index_205])
          : '';
        hex.index_206 !== null
          ? Array.isArray(result.index_206)
            ? result.index_206.push(hex.index_206)
            : (result.index_206 = [hex.index_206])
          : '';
        hex.index_207 !== null
          ? Array.isArray(result.index_207)
            ? result.index_207.push(hex.index_207)
            : (result.index_207 = [hex.index_207])
          : '';
      }
      if (subindexes_to_change.includes('3')) {
        hex.index_301 !== null
          ? Array.isArray(result.index_301)
            ? result.index_301.push(hex.index_301)
            : (result.index_301 = [hex.index_301])
          : '';
        hex.index_302 !== null
          ? Array.isArray(result.index_302)
            ? result.index_302.push(hex.index_302)
            : (result.index_302 = [hex.index_302])
          : '';
        hex.index_303 !== null
          ? Array.isArray(result.index_303)
            ? result.index_303.push(hex.index_303)
            : (result.index_303 = [hex.index_303])
          : '';
        hex.index_304 !== null
          ? Array.isArray(result.index_304)
            ? result.index_304.push(hex.index_304)
            : (result.index_304 = [hex.index_304])
          : '';
        hex.index_305 !== null
          ? Array.isArray(result.index_305)
            ? result.index_305.push(hex.index_305)
            : (result.index_305 = [hex.index_305])
          : '';
        hex.index_306 !== null
          ? Array.isArray(result.index_306)
            ? result.index_306.push(hex.index_306)
            : (result.index_306 = [hex.index_306])
          : '';
        hex.index_307 !== null
          ? Array.isArray(result.index_307)
            ? result.index_307.push(hex.index_307)
            : (result.index_307 = [hex.index_307])
          : '';
        hex.index_308 !== null
          ? Array.isArray(result.index_308)
            ? result.index_308.push(hex.index_308)
            : (result.index_308 = [hex.index_308])
          : '';
        hex.index_309 !== null
          ? Array.isArray(result.index_309)
            ? result.index_309.push(hex.index_309)
            : (result.index_309 = [hex.index_309])
          : '';
        hex.index_310 !== null
          ? Array.isArray(result.index_310)
            ? result.index_310.push(hex.index_310)
            : (result.index_310 = [hex.index_310])
          : '';
      }
    });
    Object.keys(result).forEach((key) => {
      if (result[key].length === 0) {
        result[key] = null;
      } else {
        // result[key] = median(result[key]);
        result[key] =
          result[key].reduce((a, b) => a + b, 0) / result[key].length;
      }
    });

    let index_main_length = 0;
    let index_main = 0;
    subindexes_to_change.forEach((item) => {
      let length = 0;
      const sum = sub_index_metrics_tree[item].reduce((a, b) => {
        if (b !== 'index_215' && b !== 'index_216') {
          if (result[b] !== null && result[b] !== undefined) {
            length += 1;
            return a + result[b];
          }
        }
        return a;
      }, 0);
      const avg = length > 0 ? sum / length : null;

      result[`index_${item}`] = avg;
      index_main += avg !== null ? avg : 0;
      if (avg !== null) index_main_length += 1;
    });
    console.timeEnd('TIME:: zoom 10 hexes');
  } else {
    filtered_hexes.forEach((hex) => {
      hex.index_101 !== null
        ? Array.isArray(result.index_101)
          ? result.index_101.push(hex.index_101)
          : (result.index_101 = [hex.index_101])
        : '';
      hex.index_102 !== null
        ? Array.isArray(result.index_102)
          ? result.index_102.push(hex.index_102)
          : (result.index_102 = [hex.index_102])
        : '';
      hex.index_103 !== null
        ? Array.isArray(result.index_103)
          ? result.index_103.push(hex.index_103)
          : (result.index_103 = [hex.index_103])
        : '';
      hex.index_104 !== null
        ? Array.isArray(result.index_104)
          ? result.index_104.push(hex.index_104)
          : (result.index_104 = [hex.index_104])
        : '';
      hex.index_201 !== null
        ? Array.isArray(result.index_201)
          ? result.index_201.push(hex.index_201)
          : (result.index_201 = [hex.index_201])
        : '';
      hex.index_202 !== null
        ? Array.isArray(result.index_202)
          ? result.index_202.push(hex.index_202)
          : (result.index_202 = [hex.index_202])
        : '';
      hex.index_203 !== null
        ? Array.isArray(result.index_203)
          ? result.index_203.push(hex.index_203)
          : (result.index_203 = [hex.index_203])
        : '';
      hex.index_204 !== null
        ? Array.isArray(result.index_204)
          ? result.index_204.push(hex.index_204)
          : (result.index_204 = [hex.index_204])
        : '';
      hex.index_205 !== null
        ? Array.isArray(result.index_205)
          ? result.index_205.push(hex.index_205)
          : (result.index_205 = [hex.index_205])
        : '';
      hex.index_206 !== null
        ? Array.isArray(result.index_206)
          ? result.index_206.push(hex.index_206)
          : (result.index_206 = [hex.index_206])
        : '';
      hex.index_207 !== null
        ? Array.isArray(result.index_207)
          ? result.index_207.push(hex.index_207)
          : (result.index_207 = [hex.index_207])
        : '';
      hex.index_301 !== null
        ? Array.isArray(result.index_301)
          ? result.index_301.push(hex.index_301)
          : (result.index_301 = [hex.index_301])
        : '';
      hex.index_302 !== null
        ? Array.isArray(result.index_302)
          ? result.index_302.push(hex.index_302)
          : (result.index_302 = [hex.index_302])
        : '';
      hex.index_303 !== null
        ? Array.isArray(result.index_303)
          ? result.index_303.push(hex.index_303)
          : (result.index_303 = [hex.index_303])
        : '';
      hex.index_304 !== null
        ? Array.isArray(result.index_304)
          ? result.index_304.push(hex.index_304)
          : (result.index_304 = [hex.index_304])
        : '';
      hex.index_305 !== null
        ? Array.isArray(result.index_305)
          ? result.index_305.push(hex.index_305)
          : (result.index_305 = [hex.index_305])
        : '';
      hex.index_306 !== null
        ? Array.isArray(result.index_306)
          ? result.index_306.push(hex.index_306)
          : (result.index_306 = [hex.index_306])
        : '';
      hex.index_307 !== null
        ? Array.isArray(result.index_307)
          ? result.index_307.push(hex.index_307)
          : (result.index_307 = [hex.index_307])
        : '';
      hex.index_308 !== null
        ? Array.isArray(result.index_308)
          ? result.index_308.push(hex.index_308)
          : (result.index_308 = [hex.index_308])
        : '';
      hex.index_309 !== null
        ? Array.isArray(result.index_309)
          ? result.index_309.push(hex.index_309)
          : (result.index_309 = [hex.index_309])
        : '';
      hex.index_310 !== null
        ? Array.isArray(result.index_310)
          ? result.index_310.push(hex.index_310)
          : (result.index_310 = [hex.index_310])
        : '';
    });
    Object.keys(result).forEach((key) => {
      if (result[key].length === 0) {
        result[key] = null;
      } else if (
        activeFilters.district.length > 0 ||
        Object.keys(activeFilters.draw_polygon).length > 0
      ) {
        result[key] =
          result[key].reduce((a, b) => a + b, 0) / result[key].length;
      } else {
        result[key] = median(result[key]);
      }
    });

    // index_1_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_2_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_3_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_4_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_5_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });

    let index_main_length = 0;
    let index_main = 0;
    [1, 2, 3].forEach((item) => {
      let length = 0;
      const sum = sub_index_metrics_tree[item].reduce((a, b) => {
        if (b !== 'index_215' && b !== 'index_216') {
          if (result[b] !== null && result[b] !== undefined) {
            length += 1;
            return a + result[b];
          }
        }
        return a;
      }, 0);

      const avg = length > 0 ? sum / length : null;

      result[`index_${item}`] = avg;
      index_main += avg !== null ? avg : 0;
      if (avg !== null) index_main_length += 1;
    });

    result.index_main = index_main / index_main_length;
  }

  return result;
};

export const calculate10Hexagons = (zoom10Hexagons, excludedIndexes) => {
  // FIXME Remove timeStamps
  // console.time('TIME:: 10 Hexagons mutation');
  const filtered_index_1_subindexes = filterSubindexes(
    index_1_subindexes,
    excludedIndexes
  );
  const filtered_index_2_subindexes = filterSubindexes(
    index_2_subindexes,
    excludedIndexes
  ).filter((item) => item !== 'index_215' && item !== 'index_216');
  const filtered_index_3_subindexes = filterSubindexes(
    index_3_subindexes,
    excludedIndexes
  );

  const result = zoom10Hexagons.map((hexagon) => {
    const index_1 = calculateIndex(hexagon, filtered_index_1_subindexes);
    const index_2 = calculateIndex(hexagon, filtered_index_2_subindexes);
    const index_3 = calculateIndex(hexagon, filtered_index_3_subindexes);

    const indexes_length = [index_1, index_2, index_3].filter(
      (item) => item !== null
    ).length;

    const index_main =
      indexes_length !== 0
        ? (index_1 + index_2 + index_3) / indexes_length
        : null;

    const res = {
      ...hexagon,
      properties: {
        ...hexagon.properties,
        index_1,
        index_2,
        index_3,
        index_main,
      },
    };
    excludedIndexes.forEach((index) => {
      res.properties[index] = null;
    });
    return res;
  });
  // console.timeEnd('TIME:: 10 Hexagons mutation');

  // const testObj = zoom10Hexagons.map((item) => item.properties);
  // const test = calculateIndexesByMedian(testObj);
  //
  // debugger;

  return {
    data: result,
  };
};

export const calculateByMedian = (
  mode,
  hexagons,
  hexagons_10,
  excludedIndexes,
  activeFilters
) => {
  // FIXME Remove timeStamps
  if (mode === 'zoom_9') {
    console.time('TIME:: 9 Hexagon mutation');

    const result = hexagons
      .map((hexagon) => {
        const zoom10_ids =
          zoom8_9_10_connections.zoom9_id[hexagon.properties.hex_id];
        // console.log('hexagon', hexagon);
        // console.log('zoom10_ids', zoom10_ids);
        const zoom10_hexagons = zoom10_ids.map((id) => hexagons_10[id]);

        if (zoom10_hexagons.every((item) => !item)) {
          return undefined;
        }

        const indexes_by_median = calculateIndexesByMedian(
          zoom10_hexagons,
          excludedIndexes,
          activeFilters
        );

        const { index_main } = indexes_by_median;

        return {
          ...hexagon,
          properties: {
            ...hexagon.properties,
            ...indexes_by_median,
          },
        };
      })
      .filter((item) => item !== undefined);

    console.timeEnd('TIME:: 9 Hexagon mutation');

    return {
      data: result,
    };
  }
  if (mode === 'zoom_8') {
    console.time('TIME:: 8 Hexagons mutation');

    const result = hexagons
      .map((hexagon) => {
        const zoom9_ids =
          zoom8_9_10_connections.zoom8_id[hexagon.properties.hex_id];
        const zoom10_ids = [];
        zoom9_ids.forEach((id) => {
          zoom10_ids.push(...zoom8_9_10_connections.zoom9_id[id]);
        });
        const zoom10_hexagons = zoom10_ids.map((id) => hexagons_10[id]);

        if (zoom10_hexagons.every((item) => !item)) {
          return undefined;
        }

        const indexes_by_median = calculateIndexesByMedian(
          zoom10_hexagons,
          excludedIndexes,
          activeFilters
        );

        const { index_main } = indexes_by_median;

        return {
          ...hexagon,
          properties: {
            ...hexagon.properties,
            ...indexes_by_median,
          },
        };
      })
      .filter((item) => item !== undefined);

    console.timeEnd('TIME:: 8 Hexagons mutation');

    return {
      data: result,
    };
  }
};

export const formMutatedDict10 = (hexagons) => {
  const dict = {};

  hexagons.forEach((item) => {
    dict[item.properties.hex_id] = item.properties;
  });

  return dict;
};

export const generateExtrusionHeight = (
  isExtrusion,
  property = '',
  hexagonsHeight,
  invertHeight
) => {
  if (isExtrusion) {
    if (invertHeight) {
      return [
        '-',
        [
          '-',
          [
            '*',
            ['+', ['-', ['get', property], ['*', ['get', property], 2]], 100],
            500,
          ],
          [
            '*',
            ['+', ['-', ['get', property], ['*', ['get', property], 2]], 100],
            350,
          ],
        ],
        10000 - hexagonsHeight * 10,
      ];
    }
    return [
      '-',
      ['-', ['*', ['get', property], 500], ['*', ['get', property], 350]],
      10000 - hexagonsHeight * 10,
    ];
  }
  return 0;
};

export const formDynamicBuckets = (buckets, colors, rbpLayer = 'index') => {
  const property = rbpLayer === 'index' ? 'index_main' : 'people_main';
  const formatedBuckets = [];
  buckets.forEach((item, index) => {
    if (index === 0) return;
    if (index === 1) {
      formatedBuckets.push(['<', ['get', property], item]);
    } else {
      let value = item;
      if (index === buckets.length - 1) {
        value = item + 1;
      }
      formatedBuckets.push([
        'all',
        ['>=', ['get', property], buckets[index - 1]],
        ['<', ['get', property], value],
      ]);
    }
  });

  const result = [
    'case',
    ['all', ['==', ['get', property], null]],
    'rgba(213,213,213,0.7)',
  ];

  formatedBuckets.forEach((item, index) => {
    result.push(item, colors[index]);
  });
  result.push('rgba(0,0,0,0.7)');
  return result;
};

export const calculateHexagonsByMetrics = (startData, activeFilters) => {
  const { chosen_metrics } = activeFilters;

  // FIXME Part to rewrite
  const sub_index = chosen_metrics[0].slice(0, 7);

  return startData.map((item) => {
    const calculated_index =
      chosen_metrics.reduce((a, b) => a + item.properties[b], 0) /
      chosen_metrics.length;

    const index_main = calculated_index === 0 ? null : calculated_index;
    // if (
    //   !item.properties.is_urban &&
    //   non_urban_metrics.includes(chosen_metrics[0]) &&
    //   !item.properties[chosen_metrics[0]]
    // ) {
    //   index_main = null;
    // }

    return {
      ...item,
      properties: {
        ...item.properties,
        index_main,
        // FIXME Part to rewrite
        // [sub_index]: calculated_index,
      },
    };
  });
};

export const filterGradient = (hexagons, level) => {
  const gradientBlock = {
    '0-10': false,
    '10-20': false,
    '20-30': false,
    '30-40': false,
    '40-50': false,
    '50-60': false,
    '60-70': false,
    '70-80': false,
    '80-90': false,
    '90-100': false,
  };

  hexagons.forEach((hexagon) => {
    Object.keys(gradientBlock).forEach((bucket) => {
      const [min, max] = bucket.split('-').map((item) => Number(item));
      const { index_main } = hexagon.properties;
      if (index_main !== null) {
        if (gradientBlock[bucket]) return;
        if (index_main >= min && index_main < max) gradientBlock[bucket] = true;
      }
    });
  });

  return {
    zoom: level,
    value: gradientBlock,
  };
};
