import { Select } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import mapboxgl from 'mapbox-gl';
import style from './MapGeoCoder.module.css';
import { wsGetSearchOptions } from '../../utils/webSocketConfig.js';
import { $searchOptions } from '../../models/mapModel/index.js';
import { flyTo } from '../../utils/mapbox-utils.js';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

export default function MapGeoCoder() {
  const language = useUnit($language);
  const searchOptions = useUnit($searchOptions);

  const [options, setOptions] = useState([]);
  const onSearch = (value) => {
    wsGetSearchOptions(value);
  };

  const onSelect = (value, option) => {
    if (window.marker) {
      window.marker.remove();
    }
    window.marker = new mapboxgl.Marker()
      .setLngLat(option.point.geometry.coordinates)
      .addTo(window.map);
    flyTo(option.point.geometry.coordinates, 10);
  };

  const onClear = () => {
    window?.marker?.remove();
    flyTo([76.92861, 43.10667], 9);
    setOptions([]);
  };

  useEffect(() => {
    if (Object.keys(searchOptions).length > 0) {
      setOptions(
        Object.keys(searchOptions).map((key, index) => ({
          label: searchOptions[key].long_name,
          point: searchOptions[key].geo_json,
          value: index,
        }))
      );
    } else {
      setOptions([]);
    }
  }, [searchOptions]);

  return (
    <Select
      options={options}
      className={style.geo_coder}
      showSearch
      notFoundContent={null}
      placeholder={ru_en_page_dictionary.geocoder_placeholder[language]}
      onSearch={debounce(onSearch, 500)}
      onSelect={onSelect}
      onClear={onClear}
      allowClear
      suffixIcon={<SearchOutlined />}
      filterOption={false}
    />
  );
}
