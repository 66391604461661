import { createEvent } from 'effector';

export const putIsochroneDataEv = createEvent();

export const clearIsochroneEv = createEvent();

export const getIsochroneByPointEv = createEvent();

export const getIsochroneByTimeEv = createEvent();

export const changeIsochroneTimeEv = createEvent();

export const changeTrafficEv = createEvent();

export const changeIsochroneTypeEv = createEvent();

export const changeIsochroneCustomTimeEv = createEvent();

export const changeShowModalEv = createEvent();
