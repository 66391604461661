import React from 'react';
import { useUnit } from 'effector-react';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import style from '../Landing.module.css';
import header_logo from '../../../assets/images/sections/header/header_logo.svg';
import {
  $language,
  changeLanguageEv,
} from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';

function Header(props) {
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);

  const languageItems = [
    {
      key: 'en',
      label: 'Eng',
    },
    {
      key: 'ru',
      label: 'Рус',
    },
  ];

  return (
    <div className="landing_container">
      <div className={style.header_wrapper}>
        <img src={header_logo} />
        <ul className={style.menu_wrapper}>
          <li>
            <a className={style.menu_item} href="#products">
              {translation[language].menu_item_1}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#features">
              {translation[language].menu_item_2}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#cases">
              {translation[language].menu_item_3}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="">
              {translation[language].menu_item_4}
            </a>
          </li>
        </ul>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Dropdown
            menu={{
              items: languageItems,
              onClick: ({ key }) => {
                changeLanguage(key);
              },
            }}
          >
            <div className={style.header_language}>
              {language === 'en' ? 'Eng' : 'Рус'} <DownOutlined />
            </div>
          </Dropdown>
          <Button
            type="primary"
            className={style.header_request_button}
            icon={<RightOutlined />}
            iconPosition="end"
          >
            {translation[language].request_demo}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
