import { createEvent } from 'effector';

export const loginEv = createEvent();
export const logoutEv = createEvent();

export const registerEv = createEvent();

export const setAuthStatusEv = createEvent();

export const setUserInfoEv = createEvent();

export const changeDarkThemeEv = createEvent();

export const changeLanguageEv = createEvent();

export const sendTimersEv = createEvent();

export const setInitDarkStyleEv = createEvent();

export const toggleTabletBarHiddenEv = createEvent();

export const delayedShowTabletBarEv = createEvent();

export const toggleLeftBarSwitchedEv = createEvent();

export const updateFavoritesEv = createEvent();

export const togglePlatformEv = createEvent();

export const getDataPresetsListEv = createEvent();
