import { createStore } from 'effector';

export const $webSocketConnected = createStore(false);
$webSocketConnected.watch((state) => console.log('$webSocketConnected', state));

export const $loader = createStore(false);
$loader.watch((state) => console.log('$loader', state));

export const $radarLoader = createStore(false);
export const $cardsLoader = createStore(false);
