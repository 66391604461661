import React from 'react';
import { Select } from 'antd';
import { useUnit } from 'effector-react';
import style from './LayersController.module.css';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $rbpLayer, changeRbpLayerEv } from '../../models/mapModel/index.js';
import { $chartFilters } from '../../models/activeFiltersModel/index.js';

function LayersController(props) {
  const language = useUnit($language);
  const rbpLayer = useUnit($rbpLayer);
  const changeRbpLayer = useUnit(changeRbpLayerEv);
  const chartFilters = useUnit($chartFilters);

  const options = [
    {
      value: 'index',
      label: ru_en_page_dictionary.rbp_layer_control_1[language],
    },
    {
      value: 'socdem',
      label: ru_en_page_dictionary.rbp_layer_control_2[language],
      disabled: chartFilters.length === 0,
    },
  ];
  return (
    <Select
      className={style.layers_controller}
      options={options}
      value={rbpLayer}
      onChange={changeRbpLayer}
      placement="topLeft"
    />
  );
}

export default LayersController;
